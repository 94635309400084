var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{attrs:{"id":"category-filter"}},[_c('h6',[_vm._v("Categories")]),_c('div',{staticClass:"accordion"},[_c('div',{attrs:{"id":"categories_level_one"}},_vm._l((_vm.categoriesForFilters),function(catFilter){return _c('div',{key:catFilter.category_id,staticClass:"categoryFilter"},[_c('button',{staticClass:"btn btn-link",attrs:{"id":`heading_${catFilter.slug}`,"type":"button","data-bs-toggle":"collapse","data-bs-target":`#collapse_${catFilter.slug}`,"aria-expanded":"true","aria-controls":`collapse_${catFilter.slug}`}},[_vm._v(" "+_vm._s(catFilter.name)+" ")]),_c('div',{staticClass:"collapse",attrs:{"id":`collapse_${catFilter.slug}`,"aria-labelledby":`heading_${catFilter.slug}`,"data-parent":"#categories_level_one"}},[_c('div',{staticClass:"categories_level_two",attrs:{"id":`categories_level_two_${catFilter.slug}`}},_vm._l((catFilter.sub_categories),function(subCat,index){return _c('div',{key:subCat.category_id,staticClass:"categoryFilter"},[(index === 0)?_c('span',[_c('button',{staticClass:"btn btn-link show-all-category-button",class:{
                    'active-filter': _vm.categoryFilter === catFilter.slug,
                  },attrs:{"type":"button"},on:{"click":function($event){return _vm.handleCategoryFilter(catFilter.slug)}}},[_vm._v(" All "+_vm._s(catFilter.name)+" ")]),_c('br')]):_vm._e(),_c('button',{staticClass:"btn btn-link",attrs:{"id":`heading_${subCat.slug}`,"type":"button","data-bs-toggle":"collapse","data-bs-target":`#collapse_${subCat.slug}`,"aria-expanded":"true","aria-controls":`collapse_${subCat.slug}`}},[_vm._v(" "+_vm._s(subCat.name)+" ")]),_c('div',{staticClass:"collapse",attrs:{"id":`collapse_${subCat.slug}`,"aria-labelledby":`heading_${subCat.slug}`,"data-parent":`#categories_level_two_${catFilter.slug}`}},[_c('div',{staticClass:"categories_level_three"},_vm._l((subCat.sub_categories),function(subSubCat,index){return _c('div',{key:subSubCat.category_id,staticClass:"categoryFilter"},[(index === 0)?_c('span',[_c('button',{staticClass:"btn btn-link show-all-category-button",class:{
                          'active-filter': _vm.categoryFilter === subCat.slug,
                        },attrs:{"type":"button"},on:{"click":function($event){return _vm.handleCategoryFilter(subCat.slug)}}},[_vm._v(" All "+_vm._s(subCat.name)+" ")]),_c('br')]):_vm._e(),_c('button',{staticClass:"btn btn-link",class:{
                        'active-filter': _vm.categoryFilter === subSubCat.slug,
                      },attrs:{"id":`heading_${subSubCat.slug}`,"type":"button"},on:{"click":function($event){return _vm.handleCategoryFilter(subSubCat.slug)}}},[_vm._v(" "+_vm._s(subSubCat.name)+" ")])])}),0)])])}),0)])])}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }