import Multiselect from 'vue-multiselect'

export default {
  name: 'PostCategoriesForm',
  components: {
    Multiselect,
  },
  data() {
    return {
      postCategory: {
        post_categories_id: null,
        name: null,
      },
      postcategory_keywords: [],
      keywords: [],
      isNew: true,
      btnDisabled: false,
    }
  },

  created: function () {
    this.$bus.$on('OPEN-UPDATE-FORM', (newPostCategory) => {
      this.isNew = false
      this.postCategory.post_categories_id = newPostCategory.post_categories_id
      this.postCategory.name = newPostCategory.name
      this.getCategoryKeywords(newPostCategory.post_categories_id)
    })
    this.getKeywords()
  },

  methods: {
    getKeywords: function () {
      this.$axios
        .get(this.$API_URL + 'keywords', {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          },
        })
        .then((response) => {
          this.keywords = response.data
        })
        .catch((error) => {
          this.$toastr.error(error, 'Error!')
        })
    },
    getCategoryKeywords(postcategoryid) {
      this.$axios
        .get(this.$API_URL + 'postcategory-keywords/' + postcategoryid, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          },
        })
        .then((response) => {
          this.postcategory_keywords = response.data
        })
        .catch((error) => {
          this.$toastr.error(error, 'Error!')
        })
    },
    checkForm: function (option) {
      this.btnDisabled = true

      if (this.postCategory.name) {
        if (this.postcategory_keywords.length > 0) {
          if (option == 1) {
            this.createPostCategory()
          } else {
            this.updatePostCategory()
          }
        } else {
          this.$toastr.error(
            'You must assign at least one keyword to this post category.',
            'Error'
          )
          this.btnDisabled = false
        }
      } else {
        this.$toastr.error('All fields are required.', 'Error')
        this.btnDisabled = false
      }
    },

    createPostCategory: function () {
      this.$axios
        .post(
          this.$API_URL + 'postcategories',
          {
            name: this.postCategory.name,
          },
          {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
          }
        )
        .then((response) => {
          response
          var keywords_success = false
          /*
                Once the user is created, we get the new user_id to use it for roles assignment
                */
          this.postCategory.post_categories_id =
            response.data.post_categories_id

          /*
                Now that we already have the user id, we assign the keywords to the post category
                */
          for (let i = 0; i < this.postcategory_keywords.length; i++) {
            if (i == this.postcategory_keywords.length - 1)
              keywords_success = true
            this.$axios
              .post(
                this.$API_URL +
                  'postcategory-keywords/' +
                  this.postCategory.post_categories_id +
                  '/' +
                  this.postcategory_keywords[i].key_words_id,
                null,
                {
                  headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token'),
                  },
                }
              )
              .then((response) => {
                response
              })
              .catch((error) => {
                error
                keywords_success = false
                this.$toastr.error(error, 'Keyword assigment Error!')
                this.btnDisabled = false
              })
          }

          if (keywords_success) {
            this.$bus.$emit('REFRESH-POST-CATEGORIES-LIST')
            this.clearForm()
            this.$emit('cancelForm')

            this.$toastr.success(
              'The Post Category has been Created successfully!',
              'Success!'
            )

            this.btnDisabled = false
          }
        })
        .catch((error) => {
          this.$toastr.error(error, 'Error!')
          this.btnDisabled = false
        })
    },

    updatePostCategory: function () {
      this.$axios
        .put(
          this.$API_URL +
            'postcategories/' +
            this.postCategory.post_categories_id,
          {
            name: this.postCategory.name,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
          }
        )
        .then(() => {
          this.$bus.$emit('REFRESH-POST-CATEGORIES-LIST')

          this.$toastr.info(
            'The Post Category has been Updated successfully!',
            'Success!'
          )

          this.btnDisabled = false
        })
        .catch((error) => {
          this.$toastr.error(error, 'Error!')
          this.btnDisabled = false
        })
      //Updating post categories keywords
      /*
            Pasos
            1. Obtener, de nuevo, en una variable temporal, el arreglo de keywords para esta post category
            2. Comparar los dos arreglos para ver cuales de las keywords se quedan
            3. Borrar las keywords que ya no se usan
            4. Insertar las keywords de nuevos en caso existan
            */
      var old_postcategory_keywords = []
      this.$axios
        .get(
          this.$API_URL +
            'postcategory-keywords/' +
            this.postCategory.post_categories_id,
          {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
          }
        )
        .then((response) => {
          old_postcategory_keywords = response.data

          for (let i = 0; i < old_postcategory_keywords.length; i++) {
            if (
              !this.postcategory_keywords.some(
                (data) => data.name == old_postcategory_keywords[i].name
              )
            ) {
              /* Keywords que se deben eliminar */
              this.$axios
                .delete(
                  this.$API_URL +
                    'postcategory-keyword/' +
                    this.postCategory.post_categories_id +
                    '/' +
                    old_postcategory_keywords[i].key_words_id,
                  {
                    headers: {
                      Authorization: 'Bearer ' + localStorage.getItem('token'),
                    },
                  }
                )
                .then((response) => {
                  response
                })
                .catch((error) => {
                  error
                  this.$toastr.error(
                    'We are having issues. Try again later.',
                    'Error!'
                  )
                })
            }
          }
          for (let j = 0; j < this.postcategory_keywords.length; j++) {
            if (
              !old_postcategory_keywords.some(
                (data) => data.name == this.postcategory_keywords[j].name
              )
            ) {
              /* Keywords que se deben agregar */
              this.$axios
                .post(
                  this.$API_URL +
                    'postcategory-keywords/' +
                    this.postCategory.post_categories_id +
                    '/' +
                    this.postcategory_keywords[j].key_words_id,
                  null,
                  {
                    headers: {
                      Authorization: 'Bearer ' + localStorage.getItem('token'),
                    },
                  }
                )
                .then((response) => {
                  response
                })
                .catch((error) => {
                  error
                  this.$toastr.error(
                    'We are having issues. Try again later.',
                    'Error!'
                  )
                })
            }
          }
          this.cancelForm()
        })
        .catch((error) => {
          this.$toastr.error(error, 'Error!')
        })
    },

    cancelForm: function () {
      this.isNew = true
      this.clearForm()
      this.$emit('cancelForm')
    },

    clearForm: function () {
      this.postCategory.post_categories_id = null
      this.postCategory.name = ''
      this.postcategory_keywords = []
    },
  },
}
