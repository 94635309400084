import BreadcrumbComponent from '@/components/general/BreadcrumbComponent/Breadcrumb.vue';
import StatesForm from '@/components/States/StatesForm/StatesForm.vue';
import StatesList from '@/components/States/StatesList/StatesList.vue';

export default {
    name: 'States',
    components: {
        BreadcrumbComponent,
        StatesForm,
        StatesList
    },

    data () {
        return {
            isForm: false
        }
    },

    methods: {
        showForm: function () {
            this.isForm = true;
        },

        hideForm: function () {
            this.isForm = false;
        }
    }
}