import Modal from '@/components/general/ModalComponent/Modal.vue';
import Loader from '@/components/general/LoaderComponent/Loader.vue';
import { Dropzone } from '@/mixins/Dropzone.js';

import { TipsCrud } from '@/components/Tips/mixins/TipsCrud.js';

export default {
    name: 'TipsList',
    props: ['tips'],
    mixins: [TipsCrud, Dropzone],

    components: {
        Modal,
        Loader
    },

    data () {
        return {
            tip_temporal: null,
            tip_id: null,
            isLoading: true,
            keyWord: '',
            sort: 'asc'
        }
    },

    created: function() {
        this.getTips();

        this.$bus.$on('SHOW-LOADING', () => {
            this.isLoading = true;
        });

        this.$bus.$on('HIDDEN-LOADING', () => {
            this.isLoading = false;
        })
    },

    methods: {
        
        editTip: function (tip) {
            this.$bus.$emit('REFRESH-TIP-OBJECT', tip);

            this.$emit('showForm');
            this.scrollToTop();
        },

        deleteTipsImages: function () {
            return new Promise( (resolve, reject) => {
                var imgsToDeletePromises = this.tip_temporal.images.map( image => {
                    //To Dropzone Mixin
                    return this.deleteImage(image.image_id);
                })

                Promise
                    .all(imgsToDeletePromises)
                    .then( () => {
                    resolve()
                })
                    .catch( error => {
                    reject(error)
                })
            })
        },

        assignTip: function(tip) {
            this.tip_temporal = tip;
        },

        modalResponse: async function() {
            try {
                await this.deleteTipsImages()
                await this.deleteTip(this.tip_temporal.tip_id)
                this.$toastr.warning('The Tip has been deleted successfully!', "Deleted!");
            } catch (error) {
                this.$toastr.error(error , "Error!");
            }
        },

        dateFormated: function(d) {
            return this.$moment(d).format('MMMM Do YYYY');
        },

        sortList: function(option) {
            if ( option == 'name' ) {
                if ( this.sort == 'asc' ) {
                    this.searchKeyWord.sort(function (a, b) {
                        return a.name.localeCompare(b.name);
                    });

                    this.sort = 'desc';
                }
                else {
                    this.searchKeyWord.sort( function (a, b) {
                        return b.name.localeCompare(a.name);
                    });
                    
                    this.sort = 'asc';
                }
            }
            
            else if ( option == 'created_at' ) {
                if ( this.sort == 'asc' ) {
                    this.searchKeyWord.sort(function (a, b) {
                        if (a.created_at < b.created_at) {
                            return -1;
                        }
                        if (a.created_at > b.created_at) {
                            return 1;
                        }

                        return 0;
                    });

                    this.sort = 'desc';
                }
                else {
                    this.searchKeyWord.sort( function (a, b) {
                        if (a.created_at > b.created_at) {
                            return -1;
                        }
                        if (a.created_at < b.created_at) {
                            return 1;
                        }

                        return 0;
                    });
                    
                    this.sort = 'asc';
                }
            }
            
            else if ( option == 'updated_at' ) {
                if ( this.sort == 'asc' ) {
                    this.searchKeyWord.sort(function (a, b) {
                        if (a.updated_at < b.updated_at) {
                            return -1;
                        }
                        if (a.updated_at > b.updated_at) {
                            return 1;
                        }

                        return 0;
                    });

                    this.sort = 'desc';
                }
                else {
                    this.searchKeyWord.sort( function (a, b) {
                        if (a.updated_at > b.updated_at) {
                            return -1;
                        }
                        if (a.updated_at < b.updated_at) {
                            return 1;
                        }

                        return 0;
                    });
                    
                    this.sort = 'asc';
                }
            }
        },

        scrollToTop: function () {
            window.scrollTo(0,0);
        }
    },

    computed: {
        searchKeyWord: function() {
            return this.keyWord ? this.tips.filter( (item) => `${item.name} ${item.created_at} ${item.updated_at}`
                .toLowerCase()
                .includes(
                    this.keyWord.toLowerCase()
                ) ) : this.tips
        }
    }
}