const ProductCondition = {
  data() {
    return {
      conditions: [],
    }
  },
  methods: {
    getConditions: function () {
      return new Promise((resolve, reject) => {
        this.$axios
          .get(`${this.$API_URL}conditions`, {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
          })
          .then((response) => {
            this.conditions = response.data
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
  },
}

export default ProductCondition
