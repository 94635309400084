import Modal from '@/components/general/ModalComponent/Modal.vue'
import Loader from '@/components/general/LoaderComponent/Loader.vue'

export default {
  name: 'PeriodsList',

  components: {
    Modal,
    Loader,
  },

  data() {
    return {
      periods: null,
      period_id: null,
      isLoading: true,
      keyWord: '',
      sort: 'asc',
    }
  },

  created: function () {
    this.getPeriods()

    this.$bus.$on('REFRESH-PERIODS-LIST', () => {
      this.getPeriods()
    })
  },

  methods: {
    getPeriods: function () {
      this.isLoading = true

      this.$axios
        .get(this.$API_URL + 'periods', {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          },
        })
        .then((response) => {
          this.periods = response.data

          this.isLoading = false
        })
        .catch((error) => {
          this.$toastr.error(error, 'Error!')
        })
    },

    deletePeriod: function () {
      this.$axios
        .delete(this.$API_URL + 'periods/' + this.period_id, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          },
        })
        .then((response) => {
          response
          this.getPeriods()

          this.$toastr.warning(
            'The Period has been deleted successfully!',
            'Deleted!'
          )
        })
        .catch((error) => {
          this.$toastr.error(
            error +
              ' - Probably the period you want to eliminate is being used by some products!',
            'Error!'
          )
        })
    },

    editPeriod: function (period) {
      this.$emit('showForm')
      this.$bus.$emit('OPEN-UPDATE-FORM', period)

      this.scrollToTop()
    },

    assignPeriodId: function (id) {
      this.period_id = id
    },

    dateFormated: function (d) {
      return this.$moment(d).format('MMMM Do YYYY')
    },

    sortList: function (option) {
      if (option == 'period') {
        if (this.sort == 'asc') {
          this.searchKeyWord.sort(function (a, b) {
            return a.period.localeCompare(b.period)
          })

          this.sort = 'desc'
        } else {
          this.searchKeyWord.sort(function (a, b) {
            return b.period.localeCompare(a.period)
          })

          this.sort = 'asc'
        }
      } else if (option == 'created_at') {
        if (this.sort == 'asc') {
          this.searchKeyWord.sort(function (a, b) {
            if (a.created_at < b.created_at) {
              return -1
            }
            if (a.created_at > b.created_at) {
              return 1
            }

            return 0
          })

          this.sort = 'desc'
        } else {
          this.searchKeyWord.sort(function (a, b) {
            if (a.created_at > b.created_at) {
              return -1
            }
            if (a.created_at < b.created_at) {
              return 1
            }

            return 0
          })

          this.sort = 'asc'
        }
      } else if (option == 'updated_at') {
        if (this.sort == 'asc') {
          this.searchKeyWord.sort(function (a, b) {
            if (a.updated_at < b.updated_at) {
              return -1
            }
            if (a.updated_at > b.updated_at) {
              return 1
            }

            return 0
          })

          this.sort = 'desc'
        } else {
          this.searchKeyWord.sort(function (a, b) {
            if (a.updated_at > b.updated_at) {
              return -1
            }
            if (a.updated_at < b.updated_at) {
              return 1
            }

            return 0
          })

          this.sort = 'asc'
        }
      }
    },

    scrollToTop: function () {
      window.scrollTo(0, 0)
    },
  },

  computed: {
    searchKeyWord: function () {
      return this.keyWord
        ? this.periods.filter((item) =>
            `${item.period}`.toLowerCase().includes(this.keyWord.toLowerCase())
          )
        : this.periods
    },
  },
}
