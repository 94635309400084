import CKEditor from '@ckeditor/ckeditor5-vue'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import draggable from 'vuedraggable'
import Multiselect from 'vue-multiselect'

import { Dropzone } from '@/mixins/Dropzone'
import ProductsCRUD from '@/components/Products/mixins/ProductsCRUD'
import ProductState from '@/components/Products/mixins/ProductState'
import ProductManufacturer from '@/components/Products/mixins/ProductManufacturer'
import ProductCountry from '@/components/Products/mixins/ProductCountry'
import ProductPeriod from '@/components/Products/mixins/ProductPeriod'
import ProductCondition from '@/components/Products/mixins/ProductCondition'
import ProductDesigner from '@/components/Products/mixins/ProductDesigner'
import ProductStyle from '@/components/Products/mixins/ProductStyle'
import ProductTip from '@/components/Products/mixins/ProductTip'
import ProductsImages from '@/components/Products/mixins/ProductsImages'
import ProductCategory from '@/components/Products/mixins/ProductCategory'

export default {
  name: 'ProductsForm',

  props: ['product'],

  mixins: [
    ProductsCRUD,
    ProductState,
    ProductManufacturer,
    ProductCountry,
    ProductPeriod,
    ProductCondition,
    ProductDesigner,
    ProductStyle,
    Dropzone,
    ProductsImages,
    ProductCategory,
    ProductTip,
  ],

  components: {
    ckeditor: CKEditor.component,
    draggable,
    Multiselect,
  },

  data() {
    return {
      temporal_product: {
        product_id: null,
        price: null,
        discount_price: null,
        shipping: null,
        state: {
          state_id: null,
          name: null,
        },
        TRANSACTION_transaction_id: null,
        product_physical: {
          product_physical_id: null,
          name: null,
          slug: null,
          id_number: null,
          description: null,
          dimensions: null,
          quantity: null,
          individual: null,
          customizable: null,
          materials: null,
          extra_condition: null,
          PRODUCTS_product_id: null,
          manufacturer: {
            manufacturer_id: null,
            name: null,
          },
          country: {
            country_id: null,
            name: null,
          },
          period: {
            period_id: null,
            period: null,
          },
          condition: {
            condition_id: null,
            name: null,
          },
        },

        images: null,
      },

      nameProductPhysicalValidator: {
        validated: false,
        validating: false,
        validateButtonPressed: false,
      },

      objectId: 0,

      isNew: true,

      editor: ClassicEditor,
      editorConfig: {
        toolbar: {
          items: [
            'heading',
            '|',
            'bold',
            'italic',
            'link',
            'bulletedList',
            'numberedList',
            '|',
            'undo',
            'redo',
          ],
        },
      },
      editorConfigDimensions: {
        toolbar: {
          items: ['undo', 'redo'],
        },
      },
    }
  },

  created: function () {
    //To Products.js
    this.$bus.$emit('SHOW-LOADING')

    this.isNew = true

    this.copyTemporal()

    //To ProductsImages
    this.copyImages()

    this.getRelatedInformation()
  },

  methods: {
    /**
     * Evaluate the kind of process to do and validates the input rules. If any rule dont match, a error toast is shown
     * @param {Number} option - contains the kind of process to do
     *  The following values may be:
     *    1 -> if is a Create Process
     *    2 -> if is a Update Process
     * @returns {Nothing} Only if the rule of the product name is null
     */
    checkForm: function (option) {
      // Verify if the name contains something
      if (!this.temporal_product.product_physical.name) {
        this.$toastr.error('All fields are required', 'Error!')
        return
      }

      // If option is 1, then is a create process. If option is 2, then is an update process
      if (option === 1) {
        // Before create a new Product, Validate the name
        if (this.nameProductPhysicalValidator.validated) {
          //To Products.js
          this.$bus.$emit('SHOW-LOADING')
          this.createProcess()
        } else {
          this.$toastr.error('The Product Name Must Be Validated', 'Error!')
        }
      } else if (option === 2) {
        //To Products.js
        this.$bus.$emit('SHOW-LOADING')
        this.updateProcess()
      }
    },

    createProcess: async function () {
      try {
        //To ProductsCRUD
        this.objectId = await this.createProduct(this.temporal_product)
        this.temporal_product.product_physical.PRODUCTS_product_id = this.objectId
        this.temporal_product.product_id = this.objectId

        //To ProductsCRUD
        this.temporal_product.product_physical.product_physical_id = await this.createProductPhysical(
          this.temporal_product.product_physical
        )

        //To all mixins in form
        await this.saveProductDesigners()
        await this.saveProductStyles()
        await this.saveProductTips()

        //To ProductCategory
        await this.saveCategories(
          this.temporal_product.product_physical.product_physical_id
        )

        //To ProductsImages
        await this.saveImages()

        //To Products.js
        this.$bus.$emit('CLEAR-PRODUCT')

        this.$toastr.success(
          'The product has been created successfully!',
          'Success!'
        )
        this.$router.push({ name: 'ProductCRUD', params: { view: 'list' } })
      } catch (error) {
        this.$toastr.error(error, 'Error!')
      }
    },

    updateProcess: async function () {
      try {
        this.objectId = this.temporal_product.product_id

        //To ProductsCRUD
        await this.updateProduct(this.temporal_product)
        await this.updateProductPhysical(this.temporal_product.product_physical)

        //To all mixins in form
        //--- Designer ---
        await this.deleteProductDesignersRelations()
        await this.saveProductDesigners()

        //--- Style ---
        await this.deleteProductStylesRelations()
        await this.saveProductStyles()

        //--- Tip ---
        await this.deleteProducTipsRelations()
        await this.saveProductTips()

        //To ProductCategory
        await this.updateCategories()

        //To ProductsImages
        await this.updateImages()

        //To Products.js
        this.$bus.$emit('CLEAR-PRODUCT')

        this.$toastr.info(
          'The product has been updated successfully!',
          'Updated!'
        )
        this.$router.push({ name: 'ProductCRUD', params: { view: 'list' } })
      } catch (error) {
        this.$toastr.error(error, 'Error!')
      }
    },

    cancelForm: function () {
      //To Products.js
      this.$bus.$emit('CLEAR-PRODUCT')
      this.$router.push({ name: 'ProductCRUD', params: { view: 'list' } })
    },

    copyTemporal: function () {
      //Se verifica si se ha pasado algo
      if (this.product.product_physical.name) {
        this.isNew = false
      }

      this.temporal_product.product_id = this.product.product_id
      this.temporal_product.price = this.product.price
      this.temporal_product.discount_price = this.product.discount_price
      this.temporal_product.shipping = this.product.shipping
      this.temporal_product.TRANSACTION_transaction_id = this.product.TRANSACTION_transaction_id

      this.temporal_product.state.state_id = this.product.state.state_id
      this.temporal_product.state.name = this.product.state.name

      this.temporal_product.images = this.product.images

      this.temporal_product.product_physical.product_physical_id = this.product.product_physical.product_physical_id
      this.temporal_product.product_physical.name = this.product.product_physical.name
      this.temporal_product.product_physical.slug = this.product.product_physical.slug
      this.temporal_product.product_physical.id_number = this.product.product_physical.id_number
      this.temporal_product.product_physical.description = this.product.product_physical.description
      this.temporal_product.product_physical.dimensions = this.product.product_physical.dimensions
      this.temporal_product.product_physical.quantity = this.product.product_physical.quantity
      this.temporal_product.product_physical.individual = this.product.product_physical.individual
      this.temporal_product.product_physical.customizable = this.product.product_physical.customizable
      this.temporal_product.product_physical.materials = this.product.product_physical.materials
      this.temporal_product.product_physical.extra_condition = this.product.product_physical.extra_condition
      this.temporal_product.product_physical.PRODUCTS_product_id = this.product.product_physical.PRODUCTS_product_id

      if (this.product.product_physical.manufacturer) {
        this.temporal_product.product_physical.manufacturer.manufacturer_id = this.product.product_physical.manufacturer.manufacturer_id
        this.temporal_product.product_physical.manufacturer.name = this.product.product_physical.manufacturer.name
      }

      if (this.product.product_physical.country) {
        this.temporal_product.product_physical.country.country_id = this.product.product_physical.country.country_id
        this.temporal_product.product_physical.country.name = this.product.product_physical.country.name
      }

      if (this.product.product_physical.period) {
        this.temporal_product.product_physical.period.period_id = this.product.product_physical.period.period_id
        this.temporal_product.product_physical.period.period = this.product.product_physical.period.period
      }

      if (this.product.product_physical.condition) {
        this.temporal_product.product_physical.condition.condition_id = this.product.product_physical.condition.condition_id
        this.temporal_product.product_physical.condition.name = this.product.product_physical.condition.name
      }
    },

    getRelatedInformation: async function () {
      try {
        //Carga de datos generales
        await this.getStates()
        await this.getManufacturers()
        await this.getCountries()
        await this.getPeriods()
        await this.getConditions()
        await this.getDesigners()
        await this.getStyles()
        await this.getTips()
        await this.getCategories()

        if (this.product.product_id) {
          //Carga de datos especificos
          await this.getProductDesigners()
          await this.getProductStyles()
          await this.getProductTips()
          //To ProductCategory
          await this.getProductCategories()
          await this.assignProductCategories()
        }

        // Set Product State Default
        this.assignStateDefault()

        //To Products.js
        this.$bus.$emit('HIDDEN-LOADING')
      } catch (error) {
        this.$toastr.error(error, 'Error!')

        //To Products.js
        this.$bus.$emit('HIDDEN-LOADING')
      }
    },

    // Set the default value of this.temporal_product.product_physical.quantity => 1
    setIndividualPrice: function () {
      this.temporal_product.product_physical.quantity = 1
    },

    /**
     * Validate the Product Name for a valid slug before to save the Product.
     */
    validateName: async function () {
      // Verify if the name is null
      if (!this.temporal_product.product_physical.name) return

      // Change the values of nameProductPhysicalValidator fields for show the differents indicators
      this.nameProductPhysicalValidator = {
        ...this.nameProductPhysicalValidator,
        validateButtonPressed: true,
        validating: true,
      }

      const result = await this.validateProductName(
        this.temporal_product.product_physical.name
      )

      if (result.isValid) {
        this.nameProductPhysicalValidator = {
          ...this.nameProductPhysicalValidator,
          validating: false,
          validated: true,
        }
      } else {
        this.nameProductPhysicalValidator = {
          ...this.nameProductPhysicalValidator,
          validating: false,
          validated: false,
        }
      }
    },

    /**
     * If the input textbox change, so undo every validation done.
     */
    handleChangesOnProductName: function () {
      this.nameProductPhysicalValidator = {
        validated: false,
        validating: false,
        validateButtonPressed: false,
      }
    },
 
    assignStateDefault: function () {
      const activeState = this.states.filter((item) => item.name === 'active')

      if (!this.temporal_product.state.state_id) {
        this.temporal_product.state.name = activeState[0].name
        this.temporal_product.state.state_id = activeState[0].state_id
      }
    },
  },
}
