export default {
  name: 'ConditionsForm',

  data() {
    return {
      condition: {
        condition_id: null,
        name: null,
      },

      isNew: true,
      btnDisabled: false,
    }
  },

  created: function () {
    this.$bus.$on('OPEN-UPDATE-FORM', (newCondition) => {
      this.isNew = false

      this.condition.condition_id = newCondition.condition_id
      this.condition.name = newCondition.name
    })

    this.$bus.$on('CLEAR-CONDITION-FORM', () => {
      this.cancelForm()
    })
  },

  methods: {
    checkForm: function (option) {
      this.btnDisabled = true

      if (option === 1) {
        if (!this.condition.name) {
          this.$toastr.error('All fields are required', 'Error!')

          this.btnDisabled = false
        }

        if (this.condition.name) {
          this.createCondition()
        }
      }

      if (option === 2) {
        if (!this.condition.name) {
          this.$toastr.error('All fields are required', 'Error!')

          this.btnDisabled = false
        }

        if (this.condition.name) {
          this.updateCondition()
        }
      }
    },

    createCondition: function () {
      this.$axios
        .post(
          this.$API_URL + 'conditions',
          {
            name: this.condition.name,
          },
          {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
          }
        )
        .then(() => {
          this.$bus.$emit('REFRESH-CONDITIONS-LIST')
          this.cancelForm()

          this.$toastr.success(
            'The Condition has been Created successfully!',
            'Success!'
          )

          this.btnDisabled = false
        })
        .catch((error) => {
          this.$toastr.error(error, 'Error!')
          this.btnDisabled = false
        })
    },

    updateCondition: function () {
      this.$axios
        .put(
          this.$API_URL + 'conditions/' + this.condition.condition_id,
          {
            name: this.condition.name,
          },
          {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
          }
        )
        .then(() => {
          this.$bus.$emit('REFRESH-CONDITIONS-LIST')
          this.cancelForm()

          this.$toastr.info(
            'The Condition has been Updated successfully!',
            'Success!'
          )

          this.btnDisabled = false
        })
        .catch((error) => {
          this.$toastr.error(error, 'Error!')
          this.btnDisabled = false
        })
    },

    cancelForm: function () {
      this.isNew = true
      this.clearForm()
      this.$emit('cancelForm')
    },

    clearForm: function () {
      this.condition.condition_id = null
      this.condition.name = ''
    },
  },
}
