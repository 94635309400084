import BreadcrumbComponent from '@/components/general/BreadcrumbComponent/Breadcrumb.vue';
import PostCategoriesForm from '@/components/PostCategories/PostCategoriesForm/PostCategoriesForm.vue';
import PostCategoriesList from '@/components/PostCategories/PostCategoriesList/PostCategoriesList.vue';

export default {
    name: 'Roles',
    components: {
        BreadcrumbComponent,
        PostCategoriesForm,
        PostCategoriesList
    },

    data () {
        return {
            isForm: false     //bandera para mostrar o no el formulario
        }
    },

    methods: {
        showForm: function () {
            this.isForm = true;
        },

        hideForm: function () {
            this.isForm = false;
        }
    }
}
