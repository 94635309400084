import axios from 'axios';

export default {
  name: 'BannerCreate',
  data() {
    return {
      banner: {
        name: '',
        link: '',
        active: false,
        banner_image: null,
      },
      previewUrl: null,
      errors: [],
      error: '',
    };
  },
  methods: {
    previewImage(event) {
      const file = event.target.files[0];
      if (file) {
        this.banner.banner_image = file;
        this.previewUrl = URL.createObjectURL(file);
      }
    },
    removeImage() {
      this.banner.banner_image = null;
      this.previewUrl = null;
      // Clear the file input
      this.$refs.bannerImageInput.value = '';
    },
    async createBanner() {
      this.errors = [];
      this.error = '';
      
      // Show loading spinner
      this.$bus.$emit('SHOW-LOADING');
      
      // Convert the active field to 1 or 0 based on the checkbox state
      const activeValue = this.banner.active ? 1 : 0;

      const formData = new FormData();
      formData.append('name', this.banner.name);
      formData.append('link', this.banner.link);
      formData.append('active', activeValue); // Send 1 for checked, 0 for unchecked
      if (this.banner.banner_image) {
        formData.append('banner_image', this.banner.banner_image);
      }

      try {
        await axios.post(`${process.env.VUE_APP_API_URL}banner/`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${localStorage.getItem('token')}`, // Adding the token to the request headers
          },
        });

        // Hide loading spinner
        this.$bus.$emit('HIDDEN-LOADING');

        // Handle success
        this.$toastr.success('Banner created successfully!', 'Success!');
        this.$router.push('/banners');
      } catch (error) {
        // Hide loading spinner in case of error
        this.$bus.$emit('HIDDEN-LOADING');
        
        if (error.response && error.response.status === 400) {
          // Validation errors
          this.errors = Object.values(error.response.data.message).flat();
          this.$toastr.error(this.errors.join(', '), 'Validation Error');
        } else {
          // General error
          this.error = error.response.data || 'An error occurred while creating the banner.';
          this.$toastr.error(this.error, 'Error');
        }
      }
    },
  },
};
