import Modal from '@/components/general/ModalComponent/Modal.vue'
import Loader from '@/components/general/LoaderComponent/Loader.vue'

export default {
  name: 'DiscountTypesList',

  components: {
    Modal,
    Loader,
  },

  data() {
    return {
      discountTypes: null,
      discount_type_id: null,
      isLoading: true,
      keyWord: '',
      sort: 'asc',
    }
  },

  created: function () {
    this.getDiscountTypes()

    this.$bus.$on('REFRESH-DISCOUNT-TYPES-LIST', () => {
      this.getDiscountTypes()
    })
  },

  methods: {
    getDiscountTypes: function () {
      this.isLoading = true

      this.$axios
        .get(this.$API_URL + 'discounttype', {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          },
        })
        .then((response) => {
          this.discountTypes = response.data

          this.isLoading = false
        })
        .catch((error) => {
          this.$toastr.error(error, 'Error!')
        })
    },

    deleteDiscountType: function () {
      this.$axios
        .delete(this.$API_URL + 'discounttype/' + this.discount_type_id, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          },
        })
        .then((response) => {
          response
          this.getDiscountTypes()

          this.$toastr.warning(
            'The Discount Type has been deleted successfully!',
            'Deleted!'
          )
        })
        .catch((error) => {
          this.$toastr.error(
            error +
              ' - Probably the discount type you want to eliminate is being used by some discounts!',
            'Error!'
          )
        })
    },

    editDiscountType: function (discountType) {
      this.$emit('showForm')
      this.$bus.$emit('OPEN-UPDATE-FORM', discountType)

      this.scrollToTop()
    },

    assignDiscountTypeId: function (id) {
      this.discount_type_id = id
    },

    dateFormated: function (d) {
      return this.$moment(d).format('MMMM Do YYYY')
    },

    sortList: function (option) {
      if (option == 'type') {
        if (this.sort == 'asc') {
          this.searchKeyWord.sort(function (a, b) {
            return a.type.localeCompare(b.type)
          })

          this.sort = 'desc'
        } else {
          this.searchKeyWord.sort(function (a, b) {
            return b.type.localeCompare(a.type)
          })

          this.sort = 'asc'
        }
      } else if (option == 'created_at') {
        if (this.sort == 'asc') {
          this.searchKeyWord.sort(function (a, b) {
            if (a.created_at < b.created_at) {
              return -1
            }
            if (a.created_at > b.created_at) {
              return 1
            }

            return 0
          })

          this.sort = 'desc'
        } else {
          this.searchKeyWord.sort(function (a, b) {
            if (a.created_at > b.created_at) {
              return -1
            }
            if (a.created_at < b.created_at) {
              return 1
            }

            return 0
          })

          this.sort = 'asc'
        }
      } else if (option == 'updated_at') {
        if (this.sort == 'asc') {
          this.searchKeyWord.sort(function (a, b) {
            if (a.updated_at < b.updated_at) {
              return -1
            }
            if (a.updated_at > b.updated_at) {
              return 1
            }

            return 0
          })

          this.sort = 'desc'
        } else {
          this.searchKeyWord.sort(function (a, b) {
            if (a.updated_at > b.updated_at) {
              return -1
            }
            if (a.updated_at < b.updated_at) {
              return 1
            }

            return 0
          })

          this.sort = 'asc'
        }
      }
    },

    scrollToTop: function () {
      window.scrollTo(0, 0)
    },
  },

  computed: {
    searchKeyWord: function () {
      return this.keyWord
        ? this.discountTypes.filter((item) =>
            `${item.type} ${item.created_at} ${item.updated_at}`
              .toLowerCase()
              .includes(this.keyWord.toLowerCase())
          )
        : this.discountTypes
    },
  },
}
