import EmailsCRUD from "@/components/Emails/mixins/EmailsCRUD";

export default {
    name: 'EmailsNavs',

    mixins: [
        EmailsCRUD
    ],

    data() {
        return {
            inbox: null,
            sent: null,
            trash: null,

            emailRead: null,
            emailProduct: null,
            emailProductPhysical: null,
            emailProductImagePath: null,

            isActive: [true, false, false, false, false],
            itemsHover: [],
            isDataLoading: true
        }
    },

    created: function() {
        this.loadInbox();
    },

    methods: {
        loadInbox: async function() {
            try {
                //To EmailsCRUD.js
                this.inbox = await this.getInboxEmails();
                
                this.createHoverItems(this.inbox);

                //To Emails.js
                this.$bus.$emit('HIDDEN-LOADING');

                this.isDataLoading = false;
            }
            catch(error) {
                this.$toastr.error(error, 'Error!');
            }
        },

        loadSent: async function () {
            try {
                //To EmailsCRUD.js
                this.sent = await this.getSentEmails();
                this.createHoverItems(this.sent);
                this.isDataLoading = false;
            }
            catch(error) {
                this.$toastr.error(error, 'Error!');
            }
        },

        loadTrash: async function () {
            try {
                //To EmailsCRUD.js
                this.trash = await this.getTrashEmails();
                this.createHoverItems(this.trash);
                this.isDataLoading = false;
            }
            catch(error) {
                this.$toastr.error(error, 'Error!');
            }
        },

        readEmail: async function(emailId, typeRead) {
            try {
                //To EmailsCRUD.js
                await this.markReadFile(emailId, typeRead);
            }
            catch(error) {
                this.$toastr.error(error, 'Error!');
            }
        },
        
        moveToTrash: async function (emailId) {
            try {
                //To EmailsCRUD.js
                await this.updateEmailType(emailId, 'trash');
            }
            catch(error) {
                this.$toastr.error(error, 'Error!');
            }
        },

        deleteTrashEmail: async function (emailId) {
            try {
                //To EmailsCRUD.js
                await this.deleteEmail(emailId);
            }
            catch(error) {
                this.$toastr.error(error, 'Error!');
            }
        },

        getEmailProduct: async function(emailId) {
            var emailIdProduct = null;
            try {
                emailIdProduct = await this.getIdProduct(emailId);

                this.emailProduct = await this.getProduct(emailIdProduct);

                this.emailProductPhysical = await this.getProductPhysical(emailIdProduct);

                this.getURLImage(this.emailProduct.images);
            }
            catch(error) {
                //Solo se obtiene el error pero no se hace nada
            }
        },
        
        createHoverItems: function (items) {
            this.itemsHover = [];

            for( let x=0; x < items.length; x++ ) {
                this.itemsHover.push(false);
            }
        },

        selectInbox: function() {
            this.$set(this.isActive, 0, true);
            this.$set(this.isActive, 1, false);
            this.$set(this.isActive, 2, false);
            this.$set(this.isActive, 3, false);
            this.$set(this.isActive, 4, false);

            this.isDataLoading = true;
            this.sent = null;
            this.trash = null;
            
            this.clearEmailSelected();
            this.loadInbox();
        },
        
        selectSent: function() {
            this.$set(this.isActive, 0, false);
            this.$set(this.isActive, 1, true);
            this.$set(this.isActive, 2, false);
            this.$set(this.isActive, 3, false);
            this.$set(this.isActive, 4, false);

            this.isDataLoading = true;
            this.inbox = null;
            this.trash = null;

            this.clearEmailSelected();
            this.loadSent();
        },

        selectForm: function() {
            this.$set(this.isActive, 0, false);
            this.$set(this.isActive, 1, false);
            this.$set(this.isActive, 2, true);
            this.$set(this.isActive, 3, false);
            this.$set(this.isActive, 4, false);
        },

        selectRead: function(index, email) {
            this.$set(this.isActive, 0, false);
            this.$set(this.isActive, 1, false);
            this.$set(this.isActive, 2, false);
            this.$set(this.isActive, 3, true);
            this.$set(this.isActive, 4, false);

            if( email.read === 0 ) {
                this.readEmail(email.email_id, 1);
                this.inbox[index].read = 1;
            }

            this.clearHover();

            this.emailRead = email;

            this.getEmailProduct(email.email_id);
        },

        selectTrash: function() {
            this.$set(this.isActive, 0, false);
            this.$set(this.isActive, 1, false);
            this.$set(this.isActive, 2, false);
            this.$set(this.isActive, 3, false);
            this.$set(this.isActive, 4, true);

            this.isDataLoading = true;
            this.inbox = null;
            this.sent = null;

            this.clearEmailSelected();
            this.loadTrash();
        },

        markAsRead: function(index) {
            if( this.inbox[index].read === 0 ){
                this.$set(this.inbox[index], 'read', 1);
                this.readEmail(this.inbox[index].email_id, 1);
            }
            else if( this.inbox[index].read === 1 ) {
                this.$set(this.inbox[index], 'read', 0);
                this.readEmail(this.inbox[index].email_id, 0);
            }
        },

        removeInboxEmail: function(index) {
            this.moveToTrash(this.inbox[index].email_id);
            this.inbox.splice(index, 1);
        },

        removeSentEmail: function(index) {
            this.moveToTrash(this.sent[index].email_id);
            this.sent.splice(index, 1);
        },

        removeTrashEmail: function (index) {
            this.deleteTrashEmail(this.trash[index].email_id);
            this.trash.splice(index, 1);
        },

        hoveEmail: function(index) {
            this.clearHover();
            this.$set(this.itemsHover, index, true);
        },

        leaveEmail: function () {
            this.clearHover();
        },

        clearHover: function () {
            for(let x=0; x < this.itemsHover.length; x++) {
                this.$set(this.itemsHover, x, false);
            }
        },

        clearEmailSelected: function () {
            this.emailProduct = null;
            this.emailProductPhysical = null;
            this.emailProductImagePath = null;
        },

        getURLImage: function (imgs) {
            let img = null;

            for( var x = 0; x < imgs.length; x++ ) {
                if( imgs[x].isMobile === 0 ) {
                    img = imgs[x];
                    break;
                }
            }

            this.emailProductImagePath = `${this.$API_URL}${img.path}`;
        }
    }
}