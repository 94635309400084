import BreadcrumbComponent from '@/components/general/BreadcrumbComponent/Breadcrumb.vue';
import DiscountForm from '@/components/Discount/DiscountForm/DiscountForm.vue';
import DiscountList from '@/components/Discount/DiscountList/DiscountList.vue';

export default {
    name: 'Discount',
    components: {
        BreadcrumbComponent,
        DiscountForm,
        DiscountList
    },

    data () {
        return {
            isForm: false
        }
    },

    methods: {
        showForm: function () {
            this.isForm = true;
        },

        hideForm: function () {
            this.isForm = false;
        }
    }
}