import BreadcrumbComponent from '@/components/general/BreadcrumbComponent/Breadcrumb.vue';
import RolesForm from '@/components/Roles/RolesForm/RolesForm.vue';
import RolesList from '@/components/Roles/RolesList/RolesList.vue';

export default {
    name: 'Roles',
    components: {
        BreadcrumbComponent,
        RolesForm,
        RolesList
    },

    data () {
        return {
            isForm: false     //bandera para mostrar o no el formulario
        }
    },

    methods: {
        showForm: function () {
            this.isForm = true;
        },

        hideForm: function () {
            this.isForm = false;
        }
    }
}
