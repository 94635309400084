export default {
  name: 'RolesProfileList',
  data() {
    return {
      roles: [],
    }
  },
  created: function () {
    this.getRoles()
  },
  methods: {
    getRoles: function () {
      this.$axios
        .get(this.$API_URL + 'user-rol/1', {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          },
        })
        .then((response) => {
          this.roles = response.data
        })
        .catch((error) => {
          this.$toastr.error(error, 'Error!')
        })
    },
  },
}
