import axios from "axios";

export default {
  name: "BannerUpdate",
  props: ["id"],
  data() {
    return {
      banner: {
        name: "",
        link: "",
        active: false,
        banner_image: "",
      },
      originalBanner: {
        name: "",
        link: "",
        active: false,
        banner_image: "",
      },
      previewUrl: null,
      errors: [],
      error: "",
      removeImageFlag: false,
    };
  },
  methods: {
    async fetchBanner() {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}banner/${this.id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        this.banner = response.data.data;
        this.originalBanner = { ...this.banner };
      } catch (error) {
        this.error = error.response.data.message || "Failed to fetch banner details.";
        this.$toastr.error(this.error, "Error");
      }
    },
    previewImage(event) {
      const file = event.target.files[0];
      if (file) {
        this.banner.banner_image = file;
        this.previewUrl = URL.createObjectURL(file);
        this.removeImageFlag = false;
      }
    },
    removeImage() {
      // Clear the uploaded image and trigger reactivity
      this.banner.banner_image = null;
      this.previewUrl = null;
      this.removeImageFlag = true;
      this.$refs.bannerImageInput.value = "";
    },
    removeExistingImage() {
      // Handle removing the existing image
      this.banner.banner_image = null;
      this.removeImageFlag = true;
    },
    async updateBanner() {
      this.errors = [];
      this.error = "";

      // Validation: Check if image is required
      if (!this.banner.banner_image && this.removeImageFlag) {
        this.errors.push("image_required");
        this.$toastr.error("Image is required.", "Validation Error");
        return;
      }

      this.$bus.$emit("SHOW-LOADING");

      const formData = new FormData();
      formData.append("name", this.banner.name);
      formData.append("link", this.banner.link);
      formData.append("active", this.banner.active ? 1 : 0);

      if (this.banner.banner_image && typeof this.banner.banner_image !== "string") {
        formData.append("banner_image", this.banner.banner_image);
      } else if (this.removeImageFlag) {
        formData.append("banner_image", "");
      }

      try {
        await axios.post(`${process.env.VUE_APP_API_URL}banner/update/${this.id}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });

        this.$bus.$emit("HIDDEN-LOADING");
        this.$toastr.success("Banner updated successfully!", "Success");

        // Force reload the banner listing page
        setTimeout(() => {
          this.$router.push({ name: "BannerList", query: { reload: true } });
        }, 1000);
      } catch (error) {
        this.$bus.$emit("HIDDEN-LOADING");
        if (error.response && error.response.status === 400) {
          this.errors = Object.values(error.response.data.message).flat();
          this.$toastr.error(this.errors.join(", "), "Validation Error");
        } else {
          this.error = error.response.data.message || "An error occurred while updating the banner.";
          this.$toastr.error(this.error, "Error");
        }
      }
    },
  },
  created() {
    this.fetchBanner();
  },
};