export default {
  name: 'DiscountTypesForm',

  data() {
    return {
      discountType: {
        discount_type_id: null,
        type: null,
      },

      isNew: true,
      btnDisabled: false,
    }
  },

  created: function () {
    this.$bus.$on('OPEN-UPDATE-FORM', (newDiscountType) => {
      this.isNew = false

      this.discountType.discount_type_id = newDiscountType.discount_type_id
      this.discountType.type = newDiscountType.type
    })
  },

  methods: {
    checkForm: function (option) {
      this.btnDisabled = true

      if (option === 1) {
        if (!this.discountType.type) {
          this.$toastr.error('All fields are required', 'Error!')

          this.btnDisabled = false
        }

        if (this.discountType.type) {
          this.createDiscountType()
        }
      }

      if (option === 2) {
        if (!this.discountType.type) {
          this.$toastr.error('All fields are required', 'Error!')

          this.btnDisabled = false
        }

        if (this.discountType.type) {
          this.updateDiscountType()
        }
      }
    },

    createDiscountType: function () {
      this.$axios
        .post(
          this.$API_URL + 'discounttype',
          {
            type: this.discountType.type,
          },
          {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
          }
        )
        .then(() => {
          this.$bus.$emit('REFRESH-DISCOUNT-TYPES-LIST')
          this.clearForm()
          this.$emit('cancelForm')

          this.$toastr.success(
            'The Discount Type has been created successfully!',
            'Success!'
          )

          this.btnDisabled = false
        })
        .catch((error) => {
          this.$toastr.error(error, 'Error!')
          this.btnDisabled = false
        })
    },

    updateDiscountType: function () {
      this.$axios
        .put(
          this.$API_URL + 'discounttype/' + this.discountType.discount_type_id,
          {
            type: this.discountType.type,
          },
          {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
          }
        )
        .then(() => {
          this.$bus.$emit('REFRESH-DISCOUNT-TYPES-LIST')
          this.cancelForm()

          this.$toastr.info(
            'The Discount Type has been updated successfully!',
            'Success!'
          )

          this.btnDisabled = false
        })
        .catch((error) => {
          this.$toastr.error(error, 'Error!')
          this.btnDisabled = false
        })
    },

    cancelForm: function () {
      this.isNew = true
      this.clearForm()
      this.$emit('cancelForm')
    },

    clearForm: function () {
      this.discountType.discount_type_id = null
      this.discountType.type = ''
    },
  },
}
