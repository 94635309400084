import { render, staticRenderFns } from "./Loader.vue?vue&type=template&id=1e4b7d60&scoped=true"
var script = {}


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1e4b7d60",
  null
  
)

export default component.exports