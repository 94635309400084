export const Dropzone = {
  data() {
    return {
      imgToUpload: [],

      //Arreglo de imagenes que contiene las imagenes que vienen desde la BD
      imgsSaved: [],
    }
  },

  methods: {
    //Accion para guardar una imagen en el servidor
    uploadImage: function (img, idImgType, isMobile) {
      return new Promise((resolve, reject) => {
        const fd = new FormData()

        fd.append('image', img, img.name)
        fd.append('isMobile', isMobile)
        fd.append('image_type_id', isMobile === 1 ? 2 : idImgType)
        fd.append('image_type_path', this.objectUris.imgTypePath)

        this.$axios
          .post(this.$API_URL + 'images', fd, {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
          })
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    //Accion para borrar una imagen en el servidor
    deleteImage: function (idImage) {
      return new Promise((resolve, reject) => {
        this.$axios
          .delete(this.$API_URL + 'images/' + idImage, {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
          })
          .then(() => {
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    //Obtener imagen desktop desde el input
    getImage: function (e) {
      const input = this.$refs.file

      this.imgToUpload = []

      for (var i = 0; i < this.$refs.file.files.length; i++) {
        this.imgToUpload.push(e.target.files[i])
      }

      input.type = 'text'
      input.type = 'file'

      this.setImages(this.imgToUpload)
    },

    //Obtener imagen movil desde el input
    getImageM: function (e) {
      const inputM = this.$refs.fileM

      this.imgToUpload = []

      for (var i = 0; i < this.$refs.fileM.files.length; i++) {
        this.imgToUpload.push(e.target.files[i])
      }

      inputM.type = 'text'
      inputM.type = 'file'

      this.setImagesM(this.imgToUpload)
    },

    setImages: function (imgs) {
      for (var i = 0; i < imgs.length; i++) {
        this.images.push(imgs[i])
      }

      if (this.images.length > this.imgsLimit) {
        //Se elimina las imagenes que estan de mas
        this.images.splice(this.imgsLimit)
      }
    },

    setImagesM: function (imgs) {
      for (var i = 0; i < imgs.length; i++) {
        this.imagesM.push(imgs[i])
      }

      if (this.imagesM.length > this.imgsLimit) {
        //Se elimina las imagenes que estan de mas
        this.imagesM.splice(this.imgsLimit)
      }
    },

    saveObjectImages: function (isMobile = 0) {
      return new Promise((resolve, reject) => {
        var imgPromises = this.images.map((image) => {
          if (this.images.indexOf(image) === 0) {
            return this.uploadImage(image, 1, isMobile)
          } else {
            return this.uploadImage(image, 2, isMobile)
          }
        })

        try {
          Promise.all(imgPromises)
            .then((imgs) => {
              this.imgsSaved = imgs

              this.saveImagesRelations().then(() => {
                this.clearComponent()
                resolve()
              })
            })
            .catch((error) => {
              reject(error)
            })
        } catch (error) {
          reject(error)
        }
      })
    },

    //Actualizacion de imagenes utilizando la eliminacion de relaciones
    updateObjectImages: function (isMobile = 0) {
      return new Promise((resolve, reject) => {
        this.deleteAllObjectImages(this.objectId, this.objectUris)
          .then(() => {
            this.deleteImages(this.objectImagesArray, this.images)
              .then(() => {
                this.saveOrAssignImages(isMobile)
                  .then(() => {
                    this.saveImagesRelations().then(() => {
                      this.clearComponent()

                      resolve()
                    })
                  })
                  .catch((error) => {
                    reject(error)
                  })
              })
              .catch((error) => {
                reject(error)
              })
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    //Actualizacion de imagenes sin utilizar la eliminacion de relaciones
    updateObjectImagesWithoutDeleteRelations: function (isMobile) {
      return new Promise((resolve, reject) => {
        this.deleteImages(this.objectImagesArray, this.images)
          .then(() => {
            this.saveOrAssignImages(isMobile).then(() => {
              this.saveImagesRelations().then(() => {
                this.clearComponent()

                resolve()
              })
            })
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    deleteAllObjectImages: function (objectId, objectImgUris) {
      return new Promise((resolve, reject) => {
        this.$axios
          .delete(this.$API_URL + objectImgUris.removeAllImages + objectId, {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
          })
          .then(() => {
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    deleteImages: function (objectImages, currentImages) {
      return new Promise((resolve, reject) => {
        var imgsToDeletePromises = objectImages.map((image) => {
          if (!currentImages.includes(image)) {
            return this.deleteImage(image.image_id)
          }
        })

        try {
          Promise.all(imgsToDeletePromises)
            .then(() => {
              resolve()
            })
            .catch((error) => {
              reject(error)
            })
        } catch (error) {
          reject(error)
        }
      })
    },

    //Verifica y guarda en BD imagenes nuevas o viejas
    saveOrAssignImages: function (isMobile) {
      return new Promise((resolve, reject) => {
        var imgsPromises = this.images.map((img) => {
          //Verifica si es un archivo
          if (img.type) {
            if (this.images.indexOf(img) === 0) {
              this.imgsSaved.push('FILE')

              return this.uploadImage(img, isMobile === 1 ? 2 : 1, isMobile)
            } else {
              this.imgsSaved.push('FILE')

              return this.uploadImage(img, 2, isMobile)
            }
          }
          //Si no es un archivo entonces es un array obtenido desde BD
          else {
            this.imgsSaved.push(img)
          }
        })

        try {
          Promise.all(imgsPromises).then((imgs) => {
            imgs.forEach((i) => {
              if (i !== undefined) {
                //Se arma el arreglo imgsSaved para guardar las relaciones
                for (var x = 0; x < this.imgsSaved.length; x++) {
                  if (this.imgsSaved[x] === 'FILE') {
                    this.imgsSaved[x] = i

                    break
                  }
                }
              }
            })

            resolve()
          })
        } catch (error) {
          reject(error)
        }
      })
    },

    saveImagesRelations: function () {
      return new Promise((resolve, reject) => {
        var imgRelationsPromises = this.imgsSaved.map((imgRelation) => {
          var imgId = imgRelation.image_id
          var order = this.imgsSaved.indexOf(imgRelation) + 1

          return this.saveImageRelation(imgId, order)
        })

        try {
          Promise.all(imgRelationsPromises).then(() => {
            resolve()
          })
        } catch (error) {
          reject(error)
        }
      })
    },

    saveImageRelation: function (imgId, imgOrder) {
      return new Promise((resolve, reject) => {
        this.$axios
          .post(
            this.$API_URL +
              this.objectUris.addImage +
              this.objectId +
              '/' +
              imgId,
            {
              order: imgOrder,
            },
            {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token'),
              },
            }
          )
          .then(() => {
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    clearComponent: function () {
      this.images = []
      this.imgsSaved = []
      this.imgToUpload = []
    },
  },
}
