import BreadcrumbComponent from '@/components/general/BreadcrumbComponent/Breadcrumb.vue';
import ImageTypesForm from '@/components/ImageTypes/ImageTypesForm/ImageTypesForm.vue';
import ImageTypesList from '@/components/ImageTypes/ImageTypesList/ImageTypesList.vue';

export default {
    name: 'Conditions',
    
    components: {
        BreadcrumbComponent,
        ImageTypesForm,
        ImageTypesList
    },

    data () {
        return {
            isForm: false
        }
    },

    methods: {
        showForm: function () {
            this.isForm = true;
        },

        hideForm: function () {
            this.isForm = false;
        }
    }
}
