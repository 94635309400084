import BreadcrumbComponent from '@/components/general/BreadcrumbComponent/Breadcrumb.vue';
import DiscountTypesForm from '@/components/DiscountTypes/DiscountTypesForm/DiscountTypesForm.vue';
import DiscountTypesList from '@/components/DiscountTypes/DiscountTypesList/DiscountTypesList.vue';

export default {
    name: 'DiscountTypes',
    components: {
        BreadcrumbComponent,
        DiscountTypesForm,
        DiscountTypesList
    },

    data () {
        return {
            isForm: false
        }
    },

    methods: {
        showForm: function () {
            this.isForm = true;
        },

        hideForm: function () {
            this.isForm = false;
        }
    }
}
