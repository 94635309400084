const ProductCountry = {
  data() {
    return {
      countries: [],
    }
  },
  methods: {
    getCountries: function () {
      return new Promise((resolve, reject) => {
        this.$axios
          .get(`${this.$API_URL}countries`, {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
          })
          .then((response) => {
            this.countries = response.data
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
  },
}

export default ProductCountry
