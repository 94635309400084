import CKEditor  from '@ckeditor/ckeditor5-vue';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import draggable from 'vuedraggable';

import { Dropzone } from '@/mixins/Dropzone';
import CategoriesCRUD from '@/components/Categories/mixins/CategoriesCRUD';

export default {
    name: 'CategoriesForm',

    props: [
        'category',
        'objectUris',
        'isNew'
    ],
    
    mixins: [
        CategoriesCRUD,
        Dropzone
    ],

    components: {
        ckeditor: CKEditor.component,
        draggable,
        Dropzone
    },

    data () {
        return {
            category_temporal: {
                category_id: null,
                name: null,
                description: null,
                slug: null,
                CATEGORY_LEVELS_category_level_id: 0,
                created_at: null,
                updated_at: null,
                category_level: {},
                category: [],
                sub_categories: [],
                images: []
            },

            objectId: 0,
            objectImagesArray: [],
            
            catLevelSelected: {
                category_level_id: 0,
                level: 0
            },
            upCategoryIdSelected: 0,
            upCategories: [],
            categoryLevels: [],

            images: [],

            isHover: [],

            imgsLimit: 2,

            btnDisabled: false,

            editor: ClassicEditor,
            editorConfig: {
                toolbar: {
                    items: [
                        'heading', 
                        '|', 
                        'bold', 
                        'italic', 
                        'link', 
                        'bulletedList', 
                        'numberedList', 
                        '|', 
                        'undo', 
                        'redo'
                    ]
                }
            }
        }
    },

    created: function() {
        this.$bus.$on('OPEN-UPDATE-FORM', (newCategory) => {
            //Se copian los valores del arreglo de imagenes
            this.images = newCategory.images.slice();

            //Otras asignaciones
            this.catLevelSelected.category_level_id = newCategory.category_level.category_level_id;
            this.catLevelSelected.level = newCategory.category_level.level;

            //To CategoriesCRUD
            this.getUpCategories(newCategory.category_level.category_level_id);

            this.upCategoryIdSelected = newCategory.category[0] ? newCategory.category[0].category_id : 0;
        });

        this.$bus.$on('CLEAR-CATEGORY-FORM', () => {
            this.refreshList();
        });

        this.getCategoryLevels();
        this.copyTemporal();
    },

    methods: {
        checkForm: function (option) {
            this.btnDisabled = true;
            
            if( option === 1 ) {
                if( !this.category_temporal.name || 
                    !this.category_temporal.description || 
                    this.catLevelSelected.category_level_id === 0 ) {

                    this.$toastr.error('All fields are required', 'Error!');
                    this.btnDisabled = false;
                }
                else if( this.category_temporal.name && 
                        this.category_temporal.description && 
                        this.catLevelSelected.category_level_id !== 0 ) {
                            
                    if( this.catLevelSelected.level === 1 ) {
                        this.$emit('cancelForm');
                        this.$bus.$emit('SHOW-LOADING');

                        this.createProcess();
                    }
                    else if( this.catLevelSelected.level > 1 ) {
                        if( this.upCategoryIdSelected !== 0 ) {
                            this.$emit('cancelForm');
                            this.$bus.$emit('SHOW-LOADING');

                            this.createProcess();
                        }
                        else {
                            this.$toastr.error('All fields are required', 'Error!');
                            this.btnDisabled = false;
                        }
                    }
                }
            }
            else if( option === 2 ) {
                if( !this.category_temporal.name || 
                    !this.category_temporal.description || 
                    this.catLevelSelected.category_level_id === 0 ) {

                    this.$toastr.error('All fields are required', 'Error!');
                    this.btnDisabled = false;
                }
                else if( this.category_temporal.name && 
                        this.category_temporal.description && 
                        this.catLevelSelected.category_level_id !== 0 ) {
                            
                    if( this.catLevelSelected.level === 1 ) {
                        this.$emit('cancelForm');
                        this.$bus.$emit('SHOW-LOADING');

                        this.updateProcess();
                    }
                    else if( this.catLevelSelected.level > 1 ) {
                        if( this.upCategoryIdSelected !== 0 ) {
                            this.$emit('cancelForm');
                            this.$bus.$emit('SHOW-LOADING');

                            this.updateProcess();
                        }
                        else {
                            this.$toastr.error('All fields are required', 'Error!');
                            this.btnDisabled = false;
                        }
                    }
                }
            }
        },

        createProcess: async function () {
            try {
                //To CategoriesCRUD
                this.objectId = await this.createCategory( this.category_temporal );
    
                if( this.images.length > 0 ) {
                    //To Dropzone Mixin
                    await this.saveObjectImages();
                } 
                
                if(this.upCategoryIdSelected > 0) {
                    //To CategoriesCRUD
                    await this.addSubcategory();
                }
    
                this.refreshList();
                this.$toastr.success('The category has been created successfully!', "Success!");
            }
            catch(error) {
                this.$toastr.error(error, "Error!");
                this.btnDisabled = false;
            }
        },

        updateProcess: async function () {
            try {
                
                await this.verifyRelationsChanges();
                
                await this.verifyImagesChanges();
                
                //To CategoriesCRUD Mixin
                 await this.saveCategoryChanges(this.category_temporal);
    
                this.refreshList();
                this.$toastr.info('The Category has been updated successfully!', "Updated!");
            }
            catch(error) {
                this.$toastr.error(error, "Error!");
                this.btnDisabled = false;
            }
        },

        verifyRelationsChanges: function () {
            return new Promise( (resolve, reject) => {
                var isChange = false;

                //Si se cambia el nivel: existe cambio
                if( this.catLevelSelected.level !== this.category.category_level.level ) {
                    isChange = true;
                }
                else if( this.category.category.length !== 0 ) {
                    //Si se cambia la categoria padre: existe cambio
                    if( this.upCategoryIdSelected !== this.category.category[0].category_id ) {
                        isChange = true;
                    }
                }
                //Si era categoria nivel 1 y luego se cambia a un nivel mayor
                else if( this.category.category.length === 0 ) {
                    if( this.upCategoryIdSelected > 0 ) {
                        //To CategoriesCRUD
                        this.addSubcategory()
                            .then( () => {
                                resolve();
                            } )
                            .catch(error => {
                                reject(error);
                            });
                    }
                }

                if(isChange) {
                    if( this.category.category_level.level !== 1 ) {
                        var idCatUp = this.category.category[0].category_id;
                        var idCat = this.category.category_id;

                        //To CategoriesCRUD
                        this.deleteSubcategory(idCatUp, idCat)
                            .then( () => {
                                if(this.catLevelSelected.level !== 1 ) {
                                    //To CategoriesCRUD
                                    this.addSubcategory()
                                        .then( () => {
                                            resolve();
                                        } )
                                        .catch(error => {
                                            reject(error);
                                        });
                                }
                                else if( this.catLevelSelected.level === 1 ) {
                                    resolve();
                                }
                            } )
                            .catch(error => {
                                reject(error);
                            })
                    }
                    else if ( this.category.category_level.level === 1 ) {
                        this.addSubcategory()
                            .then( () => {
                                resolve();
                            } )
                            .catch(error => {
                                reject(error);
                            });
                    }
                }
                else {
                    resolve();
                }
            } );
        },

        verifyImagesChanges: async function () {
            var isChange = false;
                
            //Verificando cambios en el arreglo de this.images
            for(var i = 0; i <= this.images.length; i++) {
                if( (this.images[i] !== this.category.images[i]) || 
                    (this.images.length !== this.category.images.length) ) {
                    isChange = true;
                    break;
                }
            }

            if( isChange ) {
                //To Dropzone Mixin
                await this.updateObjectImages();
            }
        },
        
        setCatLevelSelected: function (e) {
            var value = e.target.value;

            if( value != 0 ) {
                var catLevel = this.categoryLevels.find(cl => cl.level == value);
    
                this.upCategoryIdSelected = 0;
    
                this.catLevelSelected.category_level_id = catLevel.category_level_id;
                this.catLevelSelected.level = catLevel.level;
    
                //To CategoriesCRUD
                this.getUpCategories(catLevel.level);
            }
            else {
                this.catLevelSelected.category_level_id = 0;
                this.catLevelSelected.level = 0;
            }
        },

        setUpCategorySelected: function (e) {
            var value = e.target.value;

            if( value != 0 ) {
                var upCat = this.upCategories.find( uc => uc.category_id == value );
                
                this.upCategoryIdSelected = upCat.category_id;
            }
            else {
                this.upCategoryIdSelected = 0;
            }
        },

        setImages: function(imgs) {
            for( var i = 0; i < imgs.length; i++ ) {
                this.images.push(imgs[i]);
            }

            if( this.images.length > this.imgsLimit ){
                //Se elimina las imagenes que estan de mas
                this.images.splice(this.imgsLimit);
            }
        },
        
        getURLImage: function (img) {
            var URLImage = null;

            //Verifica si es un archivo
            if( img.type ) {
                URLImage = URL.createObjectURL(img);
            } else {
                URLImage = this.$MEDIA_URL + img.path;
            }

            return URLImage;
        },

        hoveImg: function(index) {
            this.$set(this.isHover, index, true);
        },

        leaveImg: function(index) {
            this.$set(this.isHover, index, false);
        },

        removeImg: function(index) {
            this.images.splice(index, 1);

            //Evento dirigido a componente Dropzone.js
            this.$bus.$emit('REMOVE-IMAGE', index)
        },

        refreshList: function () {
            this.$bus.$emit('REFRESH-CATEGORY-LIST');
            this.$bus.$emit('HIDDEN-LOADING');
            this.cancelForm();
            this.btnDisabled = false;
        },

        cancelForm: function() {
            this.clearForm();
            this.$emit('cancelForm');
        },

        clearForm: function () {
            this.category_temporal.category_id= null;
            this.category_temporal.name = '';
            this.category_temporal.description = '';
            this.category_temporal.slug = '';
            this.category_temporal.category_level_id = null;
            this.category_temporal.created_at = '';
            this.category_temporal.updated_at = '';
            this.category_temporal.subcategories = [];
            this.category_temporal.images = [];

            this.images = [];

            this.catLevelSelected = {
                category_level_id: 0,
                level: 0
            };

            this.upCategories = [];
            this.upCategoryIdSelected = 0;
        },

        copyTemporal: function () {
            this.category_temporal.category_id = this.category.category_id;
            this.category_temporal.name = this.category.name;
            this.category_temporal.description = this.category.description;
            this.category_temporal.slug = this.category.slug;
            this.category_temporal.CATEGORY_LEVELS_category_level_id = this.category.CATEGORY_LEVELS_category_level_id;
            this.category_temporal.category_level = this.category.category_level;
            this.category_temporal.category = this.category.category;
            this.category_temporal.sub_categories = this.category.sub_categories;
            this.category_temporal.images = this.category.images;

            this.objectId = this.category.category_id;
            this.objectImagesArray = this.category.images;
        }
    },

    watch: {
        category: {
            deep: true,
            handler() {
                this.copyTemporal();
            }
        }
    }
}
