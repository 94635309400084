import Modal from '@/components/general/ModalComponent/Modal.vue'
import Loader from '@/components/general/LoaderComponent/Loader.vue'

export default {
  name: 'CouponsList',
  components: {
    Modal,
    Loader,
  },
  data() {
    return {
      coupon_id: null,
      coupons: null,
      keyword: null,
      order: 'asc',
      isLoading: false,
    }
  },
  created: function () {
    this.getCoupons()
    this.$bus.$on('UPDATE-COUPON-LIST', () => {
      this.getCoupons()
    })
  },
  computed: {
    coupons_list: function () {
      return this.keyword
        ? this.coupons.filter((item) =>
            `${item.code} ${item.email} ${item.percentage} ${item.expiration_date}`
              .toLowerCase()
              .includes(this.keyword.toLowerCase())
          )
        : this.coupons
    },
  },
  methods: {
    getCoupons: function () {
      this.isLoading = true
      this.$axios
        .get(this.$API_URL + 'coupons', {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          this.coupons = response.data
          this.isLoading = false
        })
        .catch((error) => {
          this.$toastr.error(error, 'Error!')
        })
    },
    editCoupon: function (coupon) {
      this.$emit('showForm')
      this.$bus.$emit('EDIT-COUPON', coupon)
    },
    deleteCoupon: function () {
      this.$axios
        .delete(this.$API_URL + 'coupons/' + this.coupon_id, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          response
          this.$toastr.warning('The coupon was deleted.', 'Information')
          this.getCoupons()
        })
        .catch((error) => {
          this.$toastr.error(error, 'Error!')
        })
    },
    assignCouponId: function (id) {
      this.coupon_id = id
    },
    sort: function (option) {
      if (option == 'code') {
        if (this.order == 'asc') {
          this.coupons_list.sort(function (a, b) {
            return a.code - b.code
          })
          this.order = 'desc'
        } else {
          this.coupons_list.sort(function (a, b) {
            return b.code - a.code
          })
          this.order = 'asc'
        }
      }

      if (option == 'email') {
        if (this.order == 'asc') {
          this.coupons_list.sort(function (a, b) {
            if (a.email < b.email) {
              return -1
            }
            if (a.email > b.email) {
              return 1
            }
            return 0
          })
          this.order = 'desc'
        } else {
          this.coupons_list.sort(function (a, b) {
            if (a.email > b.email) {
              return -1
            }
            if (a.email < b.email) {
              return 1
            }
            return 0
          })
          this.order = 'asc'
        }
      }

      if (option == 'percentage') {
        if (this.order == 'asc') {
          this.coupons_list.sort(function (a, b) {
            return a.percentage - b.percentage
          })
          this.order = 'desc'
        } else {
          this.coupons_list.sort(function (a, b) {
            return b.percentage - a.percentage
          })
          this.order = 'asc'
        }
      }

      if (option == 'expiration_date') {
        if (this.order == 'asc') {
          this.coupons_list.sort(function (a, b) {
            return new Date(a.expiration_date) - new Date(b.expiration_date)
          })
          this.order = 'desc'
        } else {
          this.coupons_list.sort(function (a, b) {
            return new Date(b.expiration_date) - new Date(a.expiration_date)
          })
          this.order = 'asc'
        }
      }
    },
  },
}
