export default {
  name: 'ServiceTypesForm',

  data() {
    return {
      serviceType: {
        service_type_id: null,
        name: null,
        description: null,
      },

      isNew: true,
      btnDisabled: false,
    }
  },

  created: function () {
    this.$bus.$on('OPEN-UPDATE-FORM', (newServiceType) => {
      this.isNew = false

      this.serviceType.service_type_id = newServiceType.service_type_id
      this.serviceType.name = newServiceType.name
      this.serviceType.description = newServiceType.description
    })
  },

  methods: {
    checkForm: function (option) {
      this.btnDisabled = true

      if (option === 1) {
        if (!this.serviceType.name || !this.serviceType.description) {
          this.$toastr.error('All fields are required', 'Error!')

          this.btnDisabled = false
        }

        if (this.serviceType.name && this.serviceType.description) {
          this.createServiceType()
        }
      }

      if (option === 2) {
        if (!this.serviceType.name || !this.serviceType.description) {
          this.$toastr.error('All fields are required', 'Error!')

          this.btnDisabled = false
        }

        if (this.serviceType.name && this.serviceType.description) {
          this.updateServiceType()
        }
      }
    },

    createServiceType: function () {
      this.$axios
        .post(
          this.$API_URL + 'servicetypes',
          {
            name: this.serviceType.name,
            description: this.serviceType.description,
          },
          {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
          }
        )
        .then(() => {
          this.$bus.$emit('REFRESH-SERVICE-TYPES-LIST')
          this.clearForm()
          this.$emit('cancelForm')

          this.$toastr.success(
            'The Service Type has been Created successfully!',
            'Success!'
          )

          this.btnDisabled = false
        })
        .catch((error) => {
          this.$toastr.error(error, 'Error!')
          this.btnDisabled = false
        })
    },

    updateServiceType: function () {
      this.$axios
        .put(
          this.$API_URL + 'servicetypes/' + this.serviceType.service_type_id,
          {
            name: this.serviceType.name,
            description: this.serviceType.description,
          },
          {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
          }
        )
        .then(() => {
          this.$bus.$emit('REFRESH-SERVICE-TYPES-LIST')
          this.cancelForm()

          this.$toastr.info(
            'The Service Type has been Updated successfully!',
            'Success!'
          )

          this.btnDisabled = false
        })
        .catch((error) => {
          this.$toastr.error(error, 'Error!')
          this.btnDisabled = false
        })
    },

    cancelForm: function () {
      this.isNew = true
      this.clearForm()
      this.$emit('cancelForm')
    },

    clearForm: function () {
      this.serviceType.service_type_id = null
      this.serviceType.name = ''
      this.serviceType.description = ''
    },
  },
}
