export default {
  name: 'SortingFilter',

  props: ['sortFilter', 'deleteFilter', 'perPageFilter', 'setProductFilters'],

  data() {
    return {
      /*
        sortingOptions: Values to feed the sorting's input. These values is stablished by string index to access an object that can be 
        used to the filter main object. The values are 'hardcoded' because are a few options and not exist some reference 
        in the data base.
      */
      sortingOptions: {
        updated_desc: { order_by: ['updated_at', 'desc'] },
        updated_asc: { order_by: ['updated_at', 'asc'] },
        created_desc: { order_by: ['created_at', 'desc'] },
        created_asc: { order_by: ['created_at', 'asc'] },
        price_desc: { order_by: ['price', 'desc'] },
        price_asc: { order_by: ['price', 'asc'] },
        name_desc: { order_by: ['name', 'desc'] },
        name_asc: { order_by: ['name', 'asc'] },
      },

      sortingSelected: 'updated_desc', // value default to access to sorting input.

      perPageSelected: 40, // the value of items per page is 40 for default.
    }
  },

  watch: {
    /**
     * Listen the changes for sortingSelected state. If any change is detected, then the filter main object
     * is modified through its 'setProductFilters' method obtained for props.
     * If the sortingSelected is equal to default value 'uptated_desc' the order_by filter of the filters
     * main object is deleted.
     */
    sortingSelected: function () {
      if (this.sortingSelected !== 'updated_desc') {
        this.setProductFilters({
          ...this.sortingOptions[this.sortingSelected],
        })
      } else {
        this.deleteFilter('order_by')
      }
    },

    /**
     * If the filters main object not has the sortFilter, then set the select default value, in this case 'updated_desc'
     */
    sortFilter: function () {
      if (!this.sortFilter) {
        this.sortingSelected = 'updated_desc'
      }
    },

    /**
     * The logic is similar with the used for sortingSelected, with the diference that in this case the variable used to listen
     * is perPageSelected instead to sortingSelected
     */
    perPageSelected: function () {
      if (this.perPageSelected !== 40) {
        this.setProductFilters({
          per_page: this.perPageSelected,
        })
      } else {
        this.deleteFilter('per_page')
      }
    },

    /**
     * If the filters main object not has the perPerPageFilter, then set the select default value, in this case 40
     */
    perPageFilter: function () {
      if (!this.perPageFilter) {
        this.perPageSelected = 40
      }
    },
  },
}
