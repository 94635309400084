export const PostsCrud = {
  methods: {
    getCategories: function () {
      this.$axios
        .get(this.$API_URL + 'postcategories', {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          },
        })
        .then((response) => {
          this.categories = response.data
        })
        .catch((error) => {
          this.$toastr.error(error, 'Error!')
        })
    },

    getPosts: function () {
      this.isLoading = true

      this.$axios
        .get(this.$API_URL + 'posts', {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          },
        })
        .then((response) => {
          this.$bus.$emit('REFRESH-POSTS-LIST', response.data)

          this.isLoading = false
        })
        .catch((error) => {
          this.$toastr.error(error, 'Error!')
        })
    },

    createPost: function (post) {
      return new Promise((resolve, reject) => {
        this.$axios
          .post(
            this.$API_URL + 'posts',
            {
              title: post.title,
              body: post.body,
              creator_id: '1',
            },
            {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token'),
              },
            }
          )
          .then((response) => {
            resolve(response.data.post_id)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    createPostCategories: function (post_id, post_categories) {
      return new Promise((resolve, reject) => {
        var categories_to_create = []

        for (let i = 0; i < post_categories.length; i++) {
          let tempPromise = this.$axios.get(
            this.$API_URL +
              'post-categories/' +
              post_id +
              '/' +
              post_categories[i].post_categories_id,
            {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token'),
              },
            }
          )
          categories_to_create.push(tempPromise)
        }

        Promise.all(categories_to_create)
          .then(() => {
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    updatePost: function (post) {
      return new Promise((resolve, reject) => {
        this.$axios
          .put(
            this.$API_URL + 'posts/' + post.post_id,
            {
              title: post.title,
              body: post.body,
              creator_id: '1',
            },
            {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token'),
              },
            }
          )
          .then(() => {
            resolve()
          })
          .catch(() => {
            reject()
          })
      })
    },

    updatePostCategories: function (post_id, post_categories) {
      return new Promise((resolve, reject) => {
        var old_post_categories = []
        this.$axios
          .get(this.$API_URL + 'post-categories/' + post_id, {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
          })
          .then((response) => {
            var categories_to_update = []
            old_post_categories = response.data

            //Obtener categorias para eliminar
            for (let i = 0; i < old_post_categories.length; i++) {
              if (
                !post_categories.some(
                  (data) => data.name == old_post_categories[i].name
                )
              ) {
                let tempPromise = this.$axios.delete(
                  this.$API_URL +
                    'post-categories/' +
                    post_id +
                    '/' +
                    old_post_categories[i].post_categories_id,
                  {
                    headers: {
                      Authorization: 'Bearer ' + localStorage.getItem('token'),
                    },
                  }
                )
                categories_to_update.push(tempPromise)
              }
            }

            //Obtener categorias para agregar
            for (let j = 0; j < this.post_categories.length; j++) {
              if (
                !old_post_categories.some(
                  (data) => data.name == post_categories[j].name
                )
              ) {
                let tempPromise = this.$axios.get(
                  this.$API_URL +
                    'post-categories/' +
                    post_id +
                    '/' +
                    post_categories[j].post_categories_id,
                  {
                    headers: {
                      Authorization: 'Bearer ' + localStorage.getItem('token'),
                    },
                  }
                )
                categories_to_update.push(tempPromise)
              }
            }

            if (categories_to_update.length > 0) {
              Promise.all(categories_to_update)
                .then(() => {
                  resolve()
                })
                .catch((error) => {
                  reject(error)
                })
            } else {
              resolve()
            }
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    deletePost: function (id) {
      return new Promise((resolve, reject) => {
        this.$axios
          .delete(this.$API_URL + 'posts/' + id, {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
          })
          .then(() => {
            this.getPosts()
            resolve()
          })
          .catch(() => {
            reject()
          })
      })
    },

    errorAction: function (error) {
      this.$toastr.error(error, 'Error!')
      this.btnDisabled = false
    },
  },
}
