import Multiselect from 'vue-multiselect'

import ProductDesigner from '@/components/Products/mixins/ProductDesigner'

export default {
  name: 'DesignerFilter',

  props: ['deleteFilter', 'designerFilter', 'setProductFilters'],

  mixins: [ProductDesigner],

  components: {
    Multiselect,
  },

  data() {
    return {
      // designers: [],     -> from ProductDesigner Mixin
      // designers_temporal: [],      from ProductDesigner Mixin
      // designers_temporal is for handle the value of the Multiselect component
    }
  },

  created: function () {
    this.getProductDesigners()
  },

  methods: {
    // getDesigners: function () {},    -> from ProductDesigner Mixin

    /**
     * Getting all product designers for feed the designer multiselect, the
     * designer object obtained is locate in the ProductDesigner Mixin but is
     * accessible in all componente (here in ProductsFilters.js)
     */
    getProductDesigners: async function () {
      await this.getDesigners()
    },
  },

  watch: {
    /**
     * When an option is selected in the multiselect, the this.designer_temporal variable
     * is asigned with the designer object. If this.designers_temporal is null then
     * the filters main object will delete the 'designer' filter.
     */
    designers_temporal: function () {
      if (this.designers_temporal) {
        this.setProductFilters({
          designer: this.designers_temporal.name,
        })
      } else {
        this.deleteFilter('designer')
      }
    },

    /**
     * If the 'designer' filter from filters main object is deleted by other component
     * unlike this then assign to null the this.designers_temporal
     */
    designerFilter: function () {
      /*
        If this.designerFilter is undefined, because the 'designer' filter has been 
        deleted of the filters main object.
      */
      if (!this.designerFilter) {
        this.designers_temporal = null
      }
    },
  },
}
