import BreadcrumbComponent from '@/components/general/BreadcrumbComponent/Breadcrumb.vue'
import UsersForm from '@/components/Users/UsersForm/UsersForm.vue'
import UsersList from '@/components/Users/UsersList/UsersList.vue'

export default {
  name: 'Users',
  components: {
    BreadcrumbComponent,
    UsersForm,
    UsersList,
  },
  data() {
    return {
      isForm: false,
    }
  },
  methods: {
    showForm() {
      this.isForm = true
    },
    hideForm() {
      this.isForm = false
    },
  },
}
