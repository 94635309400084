import Modal from '@/components/general/ModalComponent/Modal.vue';
import DetailsModal from '@/components/general/DetailsModalComponent/DetailsModal.vue';
import Loader from '@/components/general/LoaderComponent/Loader.vue';

import CategoriesCRUD from '@/components/Categories/mixins/CategoriesCRUD';
import { Dropzone } from '@/mixins/Dropzone';

export default {
    name: 'CategoriesList',

    props: [
        'categories',
        'objectUris'
    ],

    components: {
        Modal,
        DetailsModal,
        Loader
    },

    mixins: [
        CategoriesCRUD,
        Dropzone
    ],

    data () {
        return {
            category_temporal: {
                category_id: null,
                name: null,
                description: null,
                slug: null,
                CATEGORY_LEVELS_category_level_id: 0,
                created_at: null,
                updated_at: null,
                category_level: {},
                category: [],
                sub_categories: [],
                images: []
            },
            category_id: null,
            isLoading: true,
            keyWord: '',
            sort: 'asc'
        }
    },

    created: function() {
        this.getCategories();

        this.$bus.$on('REFRESH-CATEGORY-LIST', () => {
            this.getCategories();
        });

        this.$bus.$on('SHOW-LOADING', () => {
            this.isLoading = true;
        });

        this.$bus.$on('HIDDEN-LOADING', () => {
            this.isLoading = false;
        });

        this.$bus.$on('OBJECT-IMAGES-DELETED', () => {
            this.deleteCategory()
        });
    },

    methods: {
        editCategory: function (category) {
            //To Categories.js
            this.$bus.$emit('REFRESH-CATEGORIES-OBJECT', category);
            //To CategoriesForm.js
            this.$bus.$emit('OPEN-UPDATE-FORM', category);
            this.$emit('showForm');
            
            this.scrollToTop();
        },

        assignCategory: function(cat) {
            this.category_temporal = cat;
        },

        deleteCat: async function () {
            this.$emit('cancelForm');
            this.isLoading = true;

            try {
                await this.deleteCategory();
    
                this.getCategories();
    
                this.$bus.$emit('CLEAR-CATEGORY-FORM');
                this.$toastr.warning('The category has been deleted successfully!', "Deleted!");

            }
            catch(error) {
                this.$toastr.error(error + ' - Probably the category you want to eliminate is being used by some categories!', "Error!");
            }
        },

        deleteCategory: function() {
            return new Promise( (resolve, reject) => {
                if( this.category_temporal.sub_categories.length == 0 ) {
                    if( this.category_temporal.category[0] ) {
                        var idCatUp = this.category_temporal.category[0].category_id;
                        var idCat = this.category_temporal.category_id;
    
                        //To CategoriesCRUD Mixin
                        this.deleteSubcategory(idCatUp, idCat)
                            .then( () => {
                                this.deleteCategoryImages()
                                    .then( () => {
                                        this.removeCategory()
                                            .then( () => {
                                                resolve();
                                            } )
                                            .catch( error => {
                                                reject(error);
                                            } );
                                    } )
                                    .catch( error => {
                                        reject(error);
                                    } );
                            } )
                            .catch( error => {
                                reject(error);
                            } );
                    }
                    else {
                        this.deleteCategoryImages()
                            .then( () => {
                                this.removeCategory()
                                    .then( () => {
                                        resolve();
                                    } )
                                    .catch( error => {
                                        reject(error);
                                    } );
                            } )
                            .catch( error => {
                                reject(error);
                            } );
                    }
                }
                else {
                    reject();
                }
            } );
        },

        deleteCategoryImages: function () {
            return new Promise( (resolve, reject) => {
                var imgsToDeletePromises = this.category_temporal.images.map( image => {
                    //To Dropzone Mixin
                    return this.deleteImage(image.image_id);
                } );

                Promise
                    .all(imgsToDeletePromises)
                    .then( () => {
                        resolve();
                    } )
                    .catch( error => {
                        reject(error);
                    } );
            } );
        },

        dateFormated: function(d) {
            return this.$moment(d).format('MMMM Do YYYY');
        },

        sortList: function(option) {
            if ( option == 'name' ) {
                if ( this.sort == 'asc' ) {
                    this.searchKeyWord.sort(function (a, b) {
                        return a.name.localeCompare(b.name);
                    });

                    this.sort = 'desc';
                }
                else {
                    this.searchKeyWord.sort( function (a, b) {
                        return b.name.localeCompare(a.name);
                    });
                    
                    this.sort = 'asc';
                }
            }

            else if ( option == 'description' ) {
                if ( this.sort == 'asc' ) {
                    this.searchKeyWord.sort(function (a, b) {
                        return a.description.localeCompare(b.description);
                    });

                    this.sort = 'desc';
                }
                else {
                    this.searchKeyWord.sort( function (a, b) {
                        return b.description.localeCompare(a.description);
                    });
                    
                    this.sort = 'asc';
                }
            }

            else if ( option == 'level' ) {
                if ( this.sort == 'asc' ) {
                    this.searchKeyWord.sort(function (a, b) {
                        if (a.category_level.level < b.category_level.level) {
                            return -1;
                        }
                        if (a.category_level.level > b.category_level.level) {
                            return 1;
                        }

                        return 0;
                    });

                    this.sort = 'desc';
                }
                else {
                    this.searchKeyWord.sort( function (a, b) {
                        if (a.category_level.level > b.category_level.level) {
                            return -1;
                        }
                        if (a.category_level.level < b.category_level.level) {
                            return 1;
                        }

                        return 0;
                    });
                    
                    this.sort = 'asc';
                }
            }
            
            else if ( option == 'created_at' ) {
                if ( this.sort == 'asc' ) {
                    this.searchKeyWord.sort(function (a, b) {
                        if (a.created_at < b.created_at) {
                            return -1;
                        }
                        if (a.created_at > b.created_at) {
                            return 1;
                        }

                        return 0;
                    });

                    this.sort = 'desc';
                }
                else {
                    this.searchKeyWord.sort( function (a, b) {
                        if (a.created_at > b.created_at) {
                            return -1;
                        }
                        if (a.created_at < b.created_at) {
                            return 1;
                        }

                        return 0;
                    });
                    
                    this.sort = 'asc';
                }
            }
            
            else if ( option == 'updated_at' ) {
                if ( this.sort == 'asc' ) {
                    this.searchKeyWord.sort(function (a, b) {
                        if (a.updated_at < b.updated_at) {
                            return -1;
                        }
                        if (a.updated_at > b.updated_at) {
                            return 1;
                        }

                        return 0;
                    });

                    this.sort = 'desc';
                }
                else {
                    this.searchKeyWord.sort( function (a, b) {
                        if (a.updated_at > b.updated_at) {
                            return -1;
                        }
                        if (a.updated_at < b.updated_at) {
                            return 1;
                        }

                        return 0;
                    });
                    
                    this.sort = 'asc';
                }
            }
        },

        scrollToTop: function () {
            window.scrollTo(0,0);
        },

        getURLImage: function (img) {
            var URLImage = '';

            if( img ) {
                URLImage = this.$MEDIA_URL + img.path;
            }
            
            return URLImage;
        },
    },

    computed: {
        searchKeyWord: function() {
            return this.keyWord ? this.categories.filter( (item) => 
                `${item.name} ${item.description} ${item.slug} ${item.category[0] ? item.category[0].name : ''} ${item.created_at} ${item.updated_at}`
                .toLowerCase()
                .includes(
                    this.keyWord.toLowerCase()
                ) ) : this.categories
        }
    }
}