import BreadcrumbComponent from '@/components/general/BreadcrumbComponent/Breadcrumb.vue';
import TipsForm from '@/components/Tips/TipsForm/TipsForm.vue';
import TipsList from '@/components/Tips/TipsList/TipsList.vue';

export default {
    name: 'Tips',
    components: {
        BreadcrumbComponent,
        TipsForm,
        TipsList
    },

    data () {
        return {
            tips: null,
            tip: {
                tip_id: null,
                name: null,
                description: null
            },
            isNew: true,
            isForm: false
        }
    },

    created: function() {
        this.$bus.$on('REFRESH-TIPS-LIST', (tips) => {
            this.tips = tips
        })

        this.$bus.$on('REFRESH-TIP-OBJECT', (tip) => {
            
            this.tip.tip_id = null
            this.tip.name = null
            this.tip.description = null
            
            this.tip.tip_id = tip.tip_id
            this.tip.name = tip.name
            this.tip.description = tip.description
            
            this.isNew = false
        })
    },

    methods: {
        showForm: function () {
            this.isForm = true
        },

        hideForm: function () {
            this.isNew = true
            this.isForm = false
        }
    }
}