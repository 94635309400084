import Modal from '@/components/general/ModalComponent/Modal.vue'
import Loader from '@/components/general/LoaderComponent/Loader.vue'
import { Dropzone } from '@/mixins/Dropzone.js'

import { ReviewsCrud } from '@/components/Reviews/mixins/ReviewsCrud.js'

export default {
  name: 'ReviewsList',
  props: ['reviews'],
  mixins: [ReviewsCrud, Dropzone],

  components: {
    Modal,
    Loader,
  },

  data() {
    return {
      review_temporal: null,
      review_id: null,
      isLoading: true,
      keyWord: '',
      sort: 'asc',
    }
  },

  created: function () {
    this.getReviews()

    this.$bus.$on('SHOW-LOADING', () => {
      this.isLoading = true
    })

    this.$bus.$on('HIDDEN-LOADING', () => {
      this.isLoading = false
    })
  },

  methods: {
    editReview: function (review) {
      this.$bus.$emit('REFRESH-REVIEW-OBJECT', review)
      this.$emit('showForm')
      this.scrollToTop()
    },

    assignReview: function (review) {
      this.review_temporal = review
    },

    modalResponse: async function () {
      try {
        await this.deleteAllReviewTypes(this.review_temporal.review_id)
        await this.deleteReview(this.review_temporal.review_id)

        this.$toastr.warning(
          'The Review has been deleted successfully!',
          'Deleted!'
        )
      } catch (error) {
        this.$toastr.error(error, 'Error!')
      }
    },

    repostReview: async function () {
      try {
        await this.updateTimestampReview(this.review_temporal.review_id)

        this.$toastr.success(
          'The Review has been reposted successfully!',
          'Reposted!'
        )
      } catch (error) {
        this.$toastr.error(error, 'Error!')
      }
    },

    dateFormated: function (d) {
      return this.$moment(d).format('MMMM Do YYYY')
    },

    sortList: function (option) {
      if (option == 'product') {
        if (this.sort == 'asc') {
          this.searchKeyWord.sort(function (a, b) {
            return a.product_name.localeCompare(b.product_name)
          })

          this.sort = 'desc'
        } else {
          this.searchKeyWord.sort(function (a, b) {
            return b.product_name.localeCompare(a.product_name)
          })

          this.sort = 'asc'
        }
      } else if (option == 'customer') {
        if (this.sort == 'asc') {
          this.searchKeyWord.sort(function (a, b) {
            return a.customer_name.localeCompare(b.customer_name)
          })

          this.sort = 'desc'
        } else {
          this.searchKeyWord.sort(function (a, b) {
            return b.customer_name.localeCompare(a.customer_name)
          })

          this.sort = 'asc'
        }
      } else if (option == 'score') {
        if (this.sort == 'asc') {
          this.searchKeyWord.sort(function (a, b) {
            if (a.score < b.score) {
              return -1
            }
            if (a.score > b.score) {
              return 1
            }

            return 0
          })

          this.sort = 'desc'
        } else {
          this.searchKeyWord.sort(function (a, b) {
            if (a.score > b.score) {
              return -1
            }
            if (a.score < b.score) {
              return 1
            }

            return 0
          })

          this.sort = 'asc'
        }
      } else if (option == 'review_date') {
        if (this.sort == 'asc') {
          this.searchKeyWord.sort(function (a, b) {
            if (a.review_date < b.review_date) {
              return -1
            }
            if (a.review_date > b.review_date) {
              return 1
            }

            return 0
          })

          this.sort = 'desc'
        } else {
          this.searchKeyWord.sort(function (a, b) {
            if (a.review_date > b.review_date) {
              return -1
            }
            if (a.review_date < b.review_date) {
              return 1
            }

            return 0
          })

          this.sort = 'asc'
        }
      } else if (option == 'created_at') {
        if (this.sort == 'asc') {
          this.searchKeyWord.sort(function (a, b) {
            if (a.created_at < b.created_at) {
              return -1
            }
            if (a.created_at > b.created_at) {
              return 1
            }

            return 0
          })

          this.sort = 'desc'
        } else {
          this.searchKeyWord.sort(function (a, b) {
            if (a.created_at > b.created_at) {
              return -1
            }
            if (a.created_at < b.created_at) {
              return 1
            }

            return 0
          })

          this.sort = 'asc'
        }
      } else if (option == 'updated_at') {
        if (this.sort == 'asc') {
          this.searchKeyWord.sort(function (a, b) {
            if (a.updated_at < b.updated_at) {
              return -1
            }
            if (a.updated_at > b.updated_at) {
              return 1
            }

            return 0
          })

          this.sort = 'desc'
        } else {
          this.searchKeyWord.sort(function (a, b) {
            if (a.updated_at > b.updated_at) {
              return -1
            }
            if (a.updated_at < b.updated_at) {
              return 1
            }

            return 0
          })

          this.sort = 'asc'
        }
      }
    },

    scrollToTop: function () {
      window.scrollTo(0, 0)
    },
  },

  computed: {
    searchKeyWord: function () {
      return this.keyWord
        ? this.reviews.filter((item) =>
            `${item.product_name} ${item.customer_name} ${item.review_date}`
              .toLowerCase()
              .includes(this.keyWord.toLowerCase())
          )
        : this.reviews
    },
  },
}
