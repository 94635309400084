import CKEditor  from '@ckeditor/ckeditor5-vue';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import draggable from 'vuedraggable';

import { Dropzone } from '@/mixins/Dropzone.js';
import { DesignersCrud } from '@/components/Designers/mixins/DesignersCrud.js';

export default {
    name: 'DesignersForm',
    props: ['designer','isNew'],
    mixins: [ DesignersCrud, Dropzone ],
    components: {
        ckeditor: CKEditor.component,
        draggable
    },
    
    data () {
        return {
            designer_temporal: {
                designer_id: null,
                name: null,
                biography: null,
            },

            objectUris: {
                imgTypePath: 'img/designers',
                addImage: 'designers-image/',
                removeAllImages: 'designers-images/',
            },

            objectId: '',

            objectImagesArray: '',

            images: [],

            imgsLimit: 2,

            isHover: [],
            btnDisabled: false,

            //Objetos para la configuracion de CKEditor
            editor: ClassicEditor,
            editorConfig: {
                toolbar: {
                    items: [
                        'heading', 
                        '|', 
                        'bold', 
                        'italic', 
                        'link', 
                        'bulletedList', 
                        'numberedList', 
                        '|', 
                        'undo', 
                        'redo'
                    ]
                }
            }
        }
    },

    created: function() {
        this.copyTemporal()
        
        this.$bus.$on('REFRESH-DESIGNER-OBJECT', (designer) => {
            this.images = designer.images.slice()
            this.objectImagesArray = designer.images
            this.objectId = designer.designer_id
        })
    },

    methods: {
        copyTemporal: function () {
            this.designer_temporal.designer_id = this.designer.designer_id
            this.designer_temporal.name = this.designer.name
            this.designer_temporal.biography = this.designer.biography
        },
        
        checkForm: function (option) {
            this.btnDisabled = true;
            
            if( option === 1 ) {
                if( !this.designer_temporal.name ) {
                    this.$toastr.error('All fields are required', 'Error!');

                    this.btnDisabled = false;
                }

                if( this.designer_temporal.name ) {
                    this.$bus.$emit('SHOW-LOADING');
                    this.createProcess();
                }
            }
            
            if( option === 2 ) {
                if( !this.designer_temporal.name ) {
                    this.$toastr.error('All fields are required', 'Error!');

                    this.btnDisabled = false;
                }
                
                if( this.designer_temporal.name ) {
                    this.$bus.$emit('SHOW-LOADING');
                    this.updateProcess();
                }
            }
        },

        createProcess: async function () {
            try {
                this.objectId =  await this.createDesigner(this.designer_temporal)

                await this.saveObjectImages()

                this.$toastr.success('The Designer has been created successfully!', "Success!");
                this.finishAction()
            } catch (error) {
                this.$toastr.error(error, "Error!");
                this.finishAction()
            }
            
            
        },

        updateProcess: async function() {
            try {
                await this.updateDesigner(this.designer_temporal)

                await this.updateObjectImages()

                this.$toastr.info('The Designer has been Updated successfully!', "Success!");
                this.finishAction()

            } catch (error) {
                this.$toastr.error(error, "Error!");
                this.btnDisabled = false;
            }
            
        },

        cancelForm: function() {
            this.$emit('cancelForm');
            this.clearForm();
        },

        clearForm: function () {
            this.designer_temporal.designer_id = null;
            this.designer_temporal.name = '';
            this.designer_temporal.biography = '';
            this.images = [];
        },
        
        getURLImage: function (img) {
            var URLImage = null;

            //Verifica si es un archivo
            if( img.type ) {
                URLImage = URL.createObjectURL(img);
            } else {
                URLImage = this.$MEDIA_URL + img.path;
            }

            return URLImage;
        },

        hoveImg: function(index) {
            this.$set(this.isHover, index, true);
        },

        leaveImg: function(index) {
            this.$set(this.isHover, index, false);
        },

        removeImg: function(index) {
            this.images.splice(index, 1);

            //Evento dirigido a componente Dropzone.js
            this.$bus.$emit('REMOVE-IMAGE', index)
        },

        finishAction: function () {
            this.getDesigners()
            this.cancelForm()
            this.$bus.$emit('HIDDEN-LOADING');                
            this.btnDisabled = false
        },
    },
    watch:  {
        designer: {
            deep: true,
            handler() {
                this.copyTemporal()
            }
        }
    }
}
