import Multiselect from 'vue-multiselect'

export default {
  name: 'UsersForm',
  components: {
    Multiselect,
  },
  data() {
    return {
      user_id: '',
      username: '',
      password: '',
      password_confirmation: '',
      email: '',
      user_roles: [],
      roles: [],
      isNew: true,
      btnDisabled: false,
    }
  },
  created: function () {
    this.$bus.$on('EDIT-USER', (new_user) => {
      this.isNew = false
      this.user_id = new_user.user_id
      this.username = new_user.username
      this.email = new_user.email
      this.user_roles = new_user.roles
    })
    this.getRoles()
  },
  methods: {
    getRoles: function () {
      this.$axios
        .get(this.$API_URL + 'roles', {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          },
        })
        .then((response) => {
          this.roles = response.data
        })
        .catch((error) => {
          this.$toastr.error(error, 'Error!')
        })
    },
    checkForm: function (option) {
      this.btnDisabled = true
      if (this.username != '' && this.email != '') {
        if (this.user_roles.length > 0) {
          if (option == 'save') {
            this.createUser()
          } else {
            this.updateUser()
          }
        } else {
          this.$toastr.error('You must assign a role to this user.', 'Error')
          this.btnDisabled = false
        }
      } else {
        this.$toastr.error('All fields are required.', 'Error')
        this.btnDisabled = false
      }
    },
    createUser: function () {
      //Creating user
      this.$axios
        .post(
          this.$API_URL + 'users',
          {
            username: this.username,
            email: this.email,
            password: this.password,
            password_confirmation: this.password_confirmation,
          },
          {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
          }
        )
        .then((response) => {
          var roles_success = false
          /*
                Once the user is created, we get the new user_id to use it for roles assignment
                */
          this.user_id = response.data.user_id

          /*
                Now that we already have the user id, we assign the roles to the user
                */
          for (let i = 0; i < this.user_roles.length; i++) {
            if (i == this.user_roles.length - 1) roles_success = true
            this.$axios
              .post(
                this.$API_URL +
                  'user-rol/' +
                  this.user_id +
                  '/' +
                  this.user_roles[i].rol_id,
                null,
                {
                  headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token'),
                  },
                }
              )
              //.then((response) => {})
              .catch((error) => {
                roles_success = false
                this.$toastr.error(error, 'Role assigment Error!')
                this.btnDisabled = false
              })
          }

          if (roles_success) {
            this.$toastr.success(
              'The user has been created succesfully.',
              'Success!'
            )
            this.$bus.$emit('UPDATE-USER-LIST')
            this.btnDisabled = false
            this.cleanForm()
          }
        })
        .catch((error) => {
          this.$toastr.error(error, 'Create Error!')
          this.btnDisabled = false
        })
    },
    updateUser: function () {
      this.$axios
        .put(
          this.$API_URL + 'users/' + this.user_id,
          {
            username: this.username,
            email: this.email,
          },
          {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
          }
        )
        .then(() => {
          this.btnDisabled = false
        })
        .catch((error) => {
          this.$toastr.error(error, 'Update Error!')
          this.btnDisabled = false
        })
      //Updating user roles
      /*
            Pasos
            1. Obtener, de nuevo, en una variable temporal, el arreglo de roles para este usuario
            2. Comparar los dos arreglos para ver cuales de los roles se quedan
            3. Borrar los roles que ya no se usan
            4. Insertar los roles de nuevos en caso existan
            */
      var old_user_roles = []
      this.$axios
        .get(this.$API_URL + 'user-rol/' + this.user_id, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          },
        })
        .then((response) => {
          //Arreglos extra para guardar indice de roles
          var roles_to_update = []

          old_user_roles = response.data

          //Obtener roles para eliminar
          for (let i = 0; i < old_user_roles.length; i++) {
            if (
              !this.user_roles.some(
                (data) => data.name == old_user_roles[i].name
              )
            )
              roles_to_update.push(
                this.$axios.delete(
                  this.$API_URL +
                    'user-rol/' +
                    this.user_id +
                    '/' +
                    old_user_roles[i].rol_id,
                  {
                    headers: {
                      Authorization: 'Bearer ' + localStorage.getItem('token'),
                    },
                  }
                )
              )
          }

          //Obtener roles para agregar
          for (let j = 0; j < this.user_roles.length; j++) {
            if (
              !old_user_roles.some(
                (data) => data.name == this.user_roles[j].name
              )
            )
              roles_to_update.push(
                this.$axios.post(
                  this.$API_URL +
                    'user-rol/' +
                    this.user_id +
                    '/' +
                    this.user_roles[j].rol_id,
                  null,
                  {
                    headers: {
                      Authorization: 'Bearer ' + localStorage.getItem('token'),
                    },
                  }
                )
              )
          }

          if (roles_to_update.length > 0) {
            Promise.all(roles_to_update).then(() => {
              this.$toastr.info(
                'The user was updated succesfully.',
                'Information'
              )
              this.$bus.$emit('UPDATE-USER-LIST')
              this.btnDisabled = false
              this.cleanForm()
            })
          }

          this.cancelForm()
        })
        .catch((error) => {
          this.$toastr.error(error, 'Error!')
        })
    },
    cancelForm: function () {
      this.$emit('cancelForm')
      this.cleanForm()
      this.isNew = true
    },
    cleanForm: function () {
      this.user_id = ''
      this.username = ''
      this.password = ''
      ;(this.password_confirmation = ''), (this.email = '')
      this.user_roles = []
    },
  },
}
