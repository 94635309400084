import { render, staticRenderFns } from "./SortingFilter.vue?vue&type=template&id=d48f5eee&scoped=true"
import script from "./SortingFilter.js?vue&type=script&lang=js&external"
export * from "./SortingFilter.js?vue&type=script&lang=js&external"
import style0 from "./SortingFilter.scss?vue&type=style&index=0&id=d48f5eee&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d48f5eee",
  null
  
)

export default component.exports