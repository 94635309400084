export default {
  name: 'CategoryLevelsForm',

  data() {
    return {
      categoryLevel: {
        category_level_id: null,
        level: null,
      },

      isNew: true,
      btnDisabled: false,
    }
  },

  created: function () {
    this.$bus.$on('GENERATE-NEW-CAT-LEVEL', (nexCatLevel) => {
      this.categoryLevel.level = nexCatLevel
    })
  },

  methods: {
    checkForm: function () {
      this.btnDisabled = true

      if (!this.categoryLevel.level) {
        this.$toastr.error('All fields are required', 'Error!')

        this.btnDisabled = false
      }

      if (this.categoryLevel.level) {
        this.createCategoryLevel()
      }
    },

    createCategoryLevel: function () {
      this.$axios
        .post(
          this.$API_URL + 'categorylevels',
          {
            level: this.categoryLevel.level,
          },
          {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
          }
        )
        .then(() => {
          this.$bus.$emit('REFRESH-CATEGORY-LEVELS-LIST')
          this.clearForm()
          this.$emit('cancelForm')

          this.$toastr.success(
            'The Category Level has been Created successfully!',
            'Success!'
          )

          this.btnDisabled = false
        })
        .catch((error) => {
          this.$toastr.error(error, 'Error!')
          this.btnDisabled = false
        })
    },

    cancelForm: function () {
      this.isNew = true
      this.clearForm()
      this.$emit('cancelForm')
    },

    clearForm: function () {
      this.categoryLevel.category_level_id = null
      this.categoryLevel.level = null
    },
  },
}
