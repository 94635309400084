import { render, staticRenderFns } from "./EmailsNavs.vue?vue&type=template&id=089f4b71&scoped=true"
import script from "./EmailsNavs.js?vue&type=script&lang=js&external"
export * from "./EmailsNavs.js?vue&type=script&lang=js&external"
import style0 from "./EmailsNavs.scss?vue&type=style&index=0&id=089f4b71&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "089f4b71",
  null
  
)

export default component.exports