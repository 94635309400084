import BreadcrumbComponent from '@/components/general/BreadcrumbComponent/Breadcrumb.vue';
import CategoryLevelsForm from '@/components/CategoryLevels/CategoryLevelsForm/CategoryLevelsForm.vue';
import CategoryLevelsList from '@/components/CategoryLevels/CategoryLevelsList/CategoryLevelsList.vue';

export default {
    name: 'CategoryLevels',
    components: {
        BreadcrumbComponent,
        CategoryLevelsForm,
        CategoryLevelsList
    },

    data () {
        return {
            isForm: false
        }
    },

    methods: {
        showForm: function () {
            this.isForm = true;
            this.$bus.$emit('REQUEST-NEW-CAT-LEVEL');
        },

        hideForm: function () {
            this.isForm = false;
        }
    }
}
