<template>
   <div class="container mt-3">
     <h2 class="text-lg font-semibold mb-4">Order Details</h2>
     <hr>
     <div v-if="transaction" class="container mt-4">
    <h3 class="mb-3">Transaction Details</h3>
    <table class="table table-bordered">
        <tbody>
            <tr>
                <th>User Name</th>
                <td>{{ transaction.user.first_name }} {{ transaction.user.last_name }}</td>
            </tr>
            <tr>
                <th>Email</th>
                <td>{{ transaction.user.email }}</td>
            </tr>
            <tr>
                <th>Phone</th>
                <td>{{ transaction.user.phone }}</td>
            </tr>
            <tr>
                <th>Payment ID</th>
                <td>{{ transaction.payment_id }}</td>
            </tr>
            <tr>
                <th>Subtotal</th>
                <td>{{ transaction.subtotal }}</td>
            </tr>
            <tr>
                <th>Shipping</th>
                <td>{{ transaction.shipping }}</td>
            </tr>
            <tr>
                <th>Taxes</th>
                <td>{{ transaction.taxes }}</td>
            </tr>
            <tr>
                <th>Total</th>
                <td><strong class="text-success">{{ transaction.total }}</strong></td>
            </tr>
            <tr>
                <th>State</th>
                <td>{{ transaction.state }}</td>
            </tr>
            <tr>
                <th>Created At</th>
                <td>{{ formatDate(transaction.created_at) }}</td>
            </tr>
        </tbody>
    </table>

    <h4 class="mt-4">Products</h4>
    <table class="table table-striped">
        <thead class="table-dark">
            <tr>
                <th>Product Name</th>
                <th>Materials</th>
                <th>Price</th>
                <th>Shipping</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="product in transaction.products" :key="product.product_id">
                <td>{{ product.product_physical.name }}</td>
                <td>{{ product.product_physical.materials }}</td>
                <td>{{ product.price }}</td>
                <td>{{ product.shipping }}</td>
            </tr>
        </tbody>
    </table>

    <h4 class="mt-4">Address</h4>
    <div v-if="transaction.user.addresses.length > 0" class="alert alert-info">
        {{ transaction.user.addresses[0].address }}, 
        {{ transaction.user.addresses[0].city }}, 
        {{ transaction.user.addresses[0].state }}, 
        {{ transaction.user.addresses[0].zip }}, 
        {{ transaction.user.addresses[0].country }}
    </div>
    <div v-else class="alert alert-warning">No address found.</div>
</div>

<div v-else class="text-center">
    <p class="text-muted">Loading Order details...</p>
</div>

 
<router-link to="/transactions" class="m-3 inline-block px-4 py-2 bg-black text-white rounded">
    Back to Order Listing
</router-link>
   </div>
 </template>
 
 <script>
 import axios from 'axios';
 
 export default {
   props: ['id'],
   data() {
     return {
       transaction: null
     };
   },
   methods: {
     async fetchTransactionDetails() {
       try {
         const transactionId = this.$route.params.id;
         const response = await axios.get(`${process.env.VUE_APP_API_URL}showTransaction/${transactionId}`, {
           headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
           }
         });
         this.transaction = response.data.data;
       } catch (error) {
         console.error("Error fetching transaction details:", error);
       }
     },
     formatDate(date) {
       return new Date(date).toLocaleString();
     }
   },
   created() {
     this.fetchTransactionDetails();
   }
 };
 </script>
 