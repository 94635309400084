import BreadcrumbComponent from '@/components/general/BreadcrumbComponent/Breadcrumb.vue';
import ServiceTypesForm from '@/components/ServiceTypes/ServiceTypesForm/ServiceTypesForm.vue';
import ServiceTypesList from '@/components/ServiceTypes/ServiceTypesList/ServiceTypesList.vue';

export default {
    name: 'Roles',
    components: {
        BreadcrumbComponent,
        ServiceTypesForm,
        ServiceTypesList
    },

    data () {
        return {
            isForm: false
        }
    },

    methods: {
        showForm: function () {
            this.isForm = true;
        },

        hideForm: function () {
            this.isForm = false;
        }
    }
}
