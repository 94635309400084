const CategoriesCRUD = {
  methods: {
    getCategories: function () {
      this.isLoading = true

      this.$axios
        .get(`${this.$API_URL}categories`, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          },
        })
        .then((response) => {
          this.$bus.$emit('ASSIGN-CATEGORIES-LIST', response.data)

          this.isLoading = false
        })
        .catch((error) => {
          this.$toastr.error(error, 'Error!')
        })
    },

    createCategory: function (category) {
      return new Promise((resolve, reject) => {
        this.$axios
          .post(
            `${this.$API_URL}categories`,
            {
              name: category.name,
              description: category.description,
              CATEGORY_LEVELS_category_level_id: this.catLevelSelected
                .category_level_id,
            },
            {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token'),
              },
            }
          )
          .then((response) => {
            resolve(response.data.category_id)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    saveCategoryChanges: function (category) {
      return new Promise((resolve, reject) => {
        this.$axios
          .put(
            `${this.$API_URL}categories/${category.category_id}`,
            {
              name: category.name,
              description: category.description,
              CATEGORY_LEVELS_category_level_id: this.catLevelSelected
                .category_level_id,
            },
            {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token'),
              },
            }
          )
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    removeCategory: function () {
      return new Promise((resolve, reject) => {
        this.$axios
          .delete(
            `${this.$API_URL}categories/${this.category_temporal.category_id}`,
            {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token'),
              },
            }
          )
          .then(() => {
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    addSubcategory: function () {
      return new Promise((resolve, reject) => {
        this.$axios
          .post(
            `${this.$API_URL}category-subcategory/${this.upCategoryIdSelected}/${this.objectId}`,
            null,
            {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token'),
              },
            }
          )
          .then(() => {
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    deleteSubcategory: function (idCatUp, idCat) {
      return new Promise((resolve, reject) => {
        this.$axios
          .delete(`${this.$API_URL}category-subcategory/${idCatUp}/${idCat}`, {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
          })
          .then(() => {
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    getCategoryLevels: function () {
      this.$axios
        .get(`${this.$API_URL}categorylevels`, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          },
        })
        .then((response) => {
          this.categoryLevels = response.data
        })
        .catch((error) => {
          this.$toastr.error(error, 'Error!')
        })
    },

    getUpCategories: function (level) {
      if (level > 1) {
        var upCatLevelName = level - 1

        this.$axios
          .get(`${this.$API_URL}levelname-categories/${upCatLevelName}`, {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
          })
          .then((response) => {
            this.upCategories = response.data
          })
          .catch((error) => {
            this.$toastr.error(error, 'Error!')
          })
      }
    },
  },
}

export default CategoriesCRUD
