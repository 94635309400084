import { render, staticRenderFns } from "./DiscountTypesList.vue?vue&type=template&id=0a6d5030&scoped=true"
import script from "./DiscountTypesList.js?vue&type=script&lang=js&external"
export * from "./DiscountTypesList.js?vue&type=script&lang=js&external"
import style0 from "./DiscountTypesList.scss?vue&type=style&index=0&id=0a6d5030&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a6d5030",
  null
  
)

export default component.exports