export default {
  name: 'RolesForm',

  data() {
    return {
      rol: {
        rol_id: null,
        name: null,
        description: null,
        state: null,
      },

      //Esta variable indica si se debe mostrar el boton de Save o de Update
      isNew: true,

      //Propiedad que deshabilita boton para permmitir solo un envio de informacion a la vez
      btnDisabled: false,
    }
  },

  created: function () {
    this.$bus.$on('open-update-form', (new_rol) => {
      this.isNew = false

      this.rol.rol_id = new_rol.rol_id
      this.rol.name = new_rol.name
      this.rol.description = new_rol.description
      this.rol.state = new_rol.state
    })
  },

  methods: {
    checkForm: function (option) {
      this.btnDisabled = true

      if (option === 1) {
        if (!this.rol.name || !this.rol.description || !this.rol.state) {
          this.$toastr.error('All fields are required', 'Error!')

          this.btnDisabled = false
        }

        if (this.rol.name && this.rol.description && this.rol.state) {
          this.createRol()
        }
      }

      if (option === 2) {
        if (!this.rol.name || !this.rol.description || !this.rol.state) {
          this.$toastr.error('All fields are required', 'Error!')

          this.btnDisabled = false
        }

        if (this.rol.name && this.rol.description && this.rol.state) {
          this.updateRol()
        }
      }
    },

    createRol: function () {
      this.$axios
        .post(
          this.$API_URL + 'roles',
          {
            name: this.rol.name,
            description: this.rol.description,
            state: this.rol.state,
          },
          {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
          }
        )
        .then(() => {
          this.$bus.$emit('REFRESH-ROLES-LIST')
          this.clearForm()
          this.$emit('cancelForm')

          this.$toastr.success(
            'The Rol has been Created successfully!',
            'Success!'
          )

          this.btnDisabled = false
        })
        .catch((error) => {
          this.$toastr.error(error, 'Error!')
          this.btnDisabled = false
        })
    },

    updateRol: function () {
      this.$axios
        .put(
          this.$API_URL + 'roles/' + this.rol.rol_id,
          {
            name: this.rol.name,
            description: this.rol.description,
            state: this.rol.state,
          },
          {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
          }
        )
        .then(() => {
          this.$bus.$emit('REFRESH-ROLES-LIST')
          this.cancelForm()

          this.$toastr.info(
            'The Rol has been Updated successfully!',
            'Success!'
          )

          this.btnDisabled = false
        })
        .catch((error) => {
          this.$toastr.error(error, 'Error!')
          this.btnDisabled = false
        })
    },

    cancelForm: function () {
      this.isNew = true
      this.clearForm()
      this.$emit('cancelForm')
    },

    clearForm: function () {
      this.rol.rol_id = null
      this.rol.name = ''
      this.rol.description = ''
      this.rol.state = ''
    },
  },
}
