import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import Login from './views/Login.vue'

//Pages Components
import Roles from '@/pages/Roles/Roles.vue'
import Coupons from '@/pages/Coupons/Coupons.vue'
import CategoryLevels from '@/pages/CategoryLevels/CategoryLevels.vue'
import KeyWords from '@/pages/KeyWords/KeyWords.vue'
import PostCategories from '@/pages/PostCategories/PostCategories.vue'
import Posts from '@/pages/Posts/Posts.vue'
import ServiceTypes from '@/pages/ServiceTypes/ServiceTypes.vue'
import DiscountTypes from '@/pages/DiscountTypes/DiscountTypes.vue'
import Discount from '@/pages/Discount/Discount.vue'
import Styles from '@/pages/Styles/Styles.vue'
import States from '@/pages/States/States.vue'
import Tips from '@/pages/Tips/Tips.vue'
import Periods from '@/pages/Periods/Periods.vue'
import Materials from '@/pages/Materials/Materials.vue'
import Designers from '@/pages/Designers/Designers.vue'
import Countries from '@/pages/Countries/Countries.vue'
import Conditions from '@/pages/Conditions/Conditions.vue'
import Users from '@/pages/Users/Users.vue'
import ImageTypes from '@/pages/ImageTypes/ImageTypes.vue'
import Manufacturers from '@/pages/Manufacturers/Manufacturers.vue'
import Profile from '@/pages/Profile/Profile.vue'
import Reviews from '@/pages/Reviews/Reviews.vue'
import Categories from '@/pages/Categories/Categories.vue'
import Products from '@/pages/Products/Products.vue'
import Emails from '@/pages/Emails/Emails.vue'
import BannerList from '@/components/Banner/BannerList.vue'
import BannerCreate from '@/components/Banner/BannerCreate.vue'
import BannerUpdate from '@/components/Banner/BannerUpdate.vue'
import TransactionList from '@/components/Transaction/TransactionList.vue'
import TransactionDetail from '@/components/TransactionDetail/TransactionDetail.vue'


Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home,
      children: [
        {
          path: 'reviews',
          component: Reviews,
        },
        {
          path: 'profile',
          component: Profile,
        },
        {
          path: 'roles',
          component: Roles,
        },
        {
          path: 'coupons',
          component: Coupons,
        },
        {
          path: 'category-levels',
          component: CategoryLevels,
        },
        {
          path: 'key-words',
          component: KeyWords,
        },
        {
          path: 'post-categories',
          component: PostCategories,
        },
        {
          path: 'service-types',
          component: ServiceTypes,
        },
        {
          path: 'discount-types',
          component: DiscountTypes,
        },
        {
          path: 'discount',
          component: Discount,
        },
        {
          path: 'styles',
          component: Styles,
        },
        {
          path: 'states',
          component: States,
        },
        {
          path: 'periods',
          component: Periods,
        },
        {
          path: 'materials',
          component: Materials,
        },
        {
          path: 'designers',
          component: Designers,
        },
        {
          path: 'countries',
          component: Countries,
        },
        {
          path: 'conditions',
          component: Conditions,
        },
        {
          path: 'users',
          component: Users,
        },
        {
          path: 'image-types',
          component: ImageTypes,
        },
        {
          path: 'manufacturers',
          component: Manufacturers,
        },
        {
          path: 'tips',
          component: Tips,
        },
        {
          path: 'posts',
          component: Posts,
        },
        {
          path: 'categories',
          component: Categories,
        },
        {
          name: 'ProductCRUD',
          path: 'products/:view',
          component: Products,
        },
        {
          name: 'emails',
          path: 'emails',
          component: Emails,
        },
        {
          path: '/banners',
          name: 'BannerList',
          component: BannerList,
        },
        {
          path: '/banners/create',
          name: 'BannerCreate',
          component: BannerCreate,
        },
        {
          path: '/banners/update/:id',
          name: 'BannerUpdate',
          component: BannerUpdate,
          props: true,
        },
        {
          path: '/transactions',
          name: 'TransactionList',
          component: TransactionList,
        },
        {
          path: '/showtransactions/:id',
          name: 'TransactionDetail',
          component: TransactionDetail,
        }
      ],
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
    },
  ],
  mode: 'history',
})
