import Multiselect from 'vue-multiselect'

import ProductState from '@/components/Products/mixins/ProductState'

export default {
  name: 'StateFilter',

  props: ['stateFilter', 'deleteFilter', 'setProductFilters'],

  mixins: [ProductState],

  components: {
    Multiselect,
  },

  data() {
    return {
      // states: [],     -> from ProductState Mixin
      stateSelected: null, // Bind the value of the select product state compontent
    }
  },

  created: function () {
    this.getProductState()
  },

  methods: {
    // getStates: function () {},    -> from ProductState Mixin

    /**
     * Getting all product states for feed the states option select, the state object obtained is locate
     * in the ProductState Mixin but is accessible in all componente (here in ProductsFilters.js)
     */
    getProductState: async function () {
      await this.getStates()
    },
  },

  watch: {
    /**
     * Call the prop methdod 'setProducFilters' to stablish the selected filter
     * If the option selected is 'Any States' then clean the State Filter in the main filters object
     * through the method 'deleteFilter'
     */
    stateSelected: function () {
      if (this.stateSelected) {
        this.setProductFilters({
          state: this.stateSelected.name,
        })
      } else {
        this.deleteFilter('state')
      }
    },

    /**
     * Is established like empty string to restart the select with the value 'Any States'
     * If the stateFilter is undefined or null, this.stateSelect will be null.
     */
    stateFilter: function () {
      if (!this.stateFilter) {
        this.stateSelected = null
      }
    },
  },
}
