const EmailsCRUD = {
  methods: {
    getInboxEmails: function () {
      return new Promise((resolve, reject) => {
        this.$axios
          .get(`${this.$API_URL}emails-inbox`, {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
          })
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    getSentEmails: function () {
      return new Promise((resolve, reject) => {
        this.$axios
          .get(`${this.$API_URL}emails-sent`, {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
          })
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    getTrashEmails: function () {
      return new Promise((resolve, reject) => {
        this.$axios
          .get(`${this.$API_URL}emails-trash`, {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
          })
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    markReadFile: function (emailId, readEmail) {
      return new Promise((resolve, reject) => {
        this.$axios
          .put(
            `${this.$API_URL}email-read/${emailId}`,
            {
              read: readEmail,
            },
            {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token'),
              },
            }
          )
          .then(() => {
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    updateEmailType: function (emailId, emailType) {
      return new Promise((resolve, reject) => {
        this.$axios
          .put(
            `${this.$API_URL}email-update-type/${emailId}`,
            {
              type: emailType,
            },
            {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token'),
              },
            }
          )
          .then(() => {
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    deleteEmail: function (emailId) {
      return new Promise((resolve, reject) => {
        this.$axios
          .delete(`${this.$API_URL}emails/${emailId}`, {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
          })
          .then(() => {
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    getIdProduct: function (emailId) {
      return new Promise((resolve, reject) => {
        this.$axios
          .get(`${this.$API_URL}emails-id-product/${emailId}`, {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
          })
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    getProduct: function (productId) {
      return new Promise((resolve, reject) => {
        this.$axios
          .get(`${this.$API_URL}products/${productId}`, {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
          })
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    getProductPhysical: function (productId) {
      return new Promise((resolve, reject) => {
        this.$axios
          .get(`${this.$API_URL}product-productphysical/${productId}`, {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
          })
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
  },
}

export default EmailsCRUD
