const ProductsCRUD = {
  methods: {
    getProducts: function (params) {
      return new Promise((resolve, reject) => {
        this.$axios
          .get(`${this.$API_URL}products`, {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
            params,
          })
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    getProductsWithoutPagination: function () {
      return new Promise((resolve, reject) => {
        this.$axios
          .get(`${this.$API_URL}products-without-pagination`, {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
          })
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    getProductByName: function (search) {
      return new Promise((resolve, reject) => {
        this.$axios
          .post(
            `${this.$API_URL}product-by-name`,
            {
              productName: search,
            },
            {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token'),
              },
            }
          )
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    createProduct: function (product) {
      return new Promise((resolve, reject) => {
        this.$axios
          .post(
            `${this.$API_URL}products`,
            {
              price: product.price,
              discount_price: product.discount_price,
              states_state_id: product.state.state_id,
              shipping: product.shipping,
            },
            {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token'),
              },
            }
          )
          .then((response) => {
            resolve(response.data.product_id)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    updateProduct: function (product) {
      return new Promise((resolve, reject) => {
        this.$axios
          .put(
            `${this.$API_URL}products/${product.product_id}`,
            {
              price: product.price,
              discount_price: product.discount_price,
              states_state_id: product.state.state_id,
              shipping: product.shipping,
            },
            {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token'),
              },
            }
          )
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    updateTimestampProduct: function (product_id) {
      return new Promise((resolve, reject) => {
        this.$axios
          .put(`${this.$API_URL}product-timestamp-update/${product_id}`, null, {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
          })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
 
    changeToSoldState: function (product_id) {
      return new Promise((resolve, reject) => {
        this.$axios
          .post(`${this.$API_URL}mark-sold-product/${product_id}`, null, {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
          })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    validateProductName: function (name) {
      return new Promise((resolve, reject) => {
        this.$axios
          .post(
            `${this.$API_URL}validate-duplicate-product-physical-slug`,
            {
              productName: name,
            },
            {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token'),
              },
            }
          )
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    deleteProduct: function (productId) {
      return new Promise((resolve, reject) => {
        this.$axios
          .delete(`${this.$API_URL}products/${productId}`, {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
          })
          .then(() => {
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    createProductPhysical: function (productPhysical) {
      return new Promise((resolve, reject) => {
        this.$axios
          .post(
            `${this.$API_URL}productphysical`,
            {
              name: productPhysical.name,
              id_number: productPhysical.id_number,
              description: productPhysical.description,
              dimensions: productPhysical.dimensions,
              quantity: productPhysical.quantity,
              individual: productPhysical.individual,
              customizable: productPhysical.customizable,
              materials: productPhysical.materials,
              extra_condition: productPhysical.extra_condition,
              PRODUCTS_product_id: productPhysical.PRODUCTS_product_id,
              MANUFACTURERS_manufacturer_id:
                productPhysical.manufacturer.manufacturer_id,
              COUNTRIES_country_id: productPhysical.country.country_id,
              PERIODS_period_id: productPhysical.period.period_id,
              CONDITIONS_condition_id: productPhysical.condition.condition_id,
            },
            {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token'),
              },
            }
          )
          .then((response) => {
            resolve(response.data.product_physical_id)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    updateProductPhysical: function (productPhysical) {
      return new Promise((resolve, reject) => {
        this.$axios
          .put(
            `${this.$API_URL}productphysical/${productPhysical.product_physical_id}`,
            {
              name: productPhysical.name,
              id_number: productPhysical.id_number,
              description: productPhysical.description,
              dimensions: productPhysical.dimensions,
              quantity: productPhysical.quantity,
              individual: productPhysical.individual,
              customizable: productPhysical.customizable,
              materials: productPhysical.materials,
              extra_condition: productPhysical.extra_condition,
              PRODUCTS_product_id: productPhysical.PRODUCTS_product_id,
              MANUFACTURERS_manufacturer_id:
                productPhysical.manufacturer.manufacturer_id,
              COUNTRIES_country_id: productPhysical.country.country_id,
              PERIODS_period_id: productPhysical.period.period_id,
              CONDITIONS_condition_id: productPhysical.condition.condition_id,
            },
            {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token'),
              },
            }
          )
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    deleteProductPhysical: function (productPhysicalId) {
      return new Promise((resolve, reject) => {
        this.$axios
          .delete(`${this.$API_URL}productphysical/${productPhysicalId}`, {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
          })
          .then(() => {
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
  },
}

export default ProductsCRUD
