const ProductTip = {
  data() {
    return {
      tips: [],
      tips_temporal: [],
    }
  },
  methods: {
    getTips: function () {
      return new Promise((resolve, reject) => {
        this.$axios
          .get(`${this.$API_URL}tips`, {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
          })
          .then((response) => {
            this.tips = response.data
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    getProductTips: function () {
      return new Promise((resolve, reject) => {
        this.$axios
          .get(
            `${this.$API_URL}productphysical-tips/${this.temporal_product.product_physical.product_physical_id}`,
            {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token'),
              },
            }
          )
          .then((response) => {
            this.tips_temporal = response.data
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    saveProductTips: function () {
      return new Promise((resolve, reject) => {
        let tipPromises = this.tips_temporal.map((tip) => {
          return new Promise((resolve, reject) => {
            this.$axios
              .post(
                `${this.$API_URL}productphysical-tips/${this.temporal_product.product_physical.product_physical_id}/${tip.tip_id}`,
                null,
                {
                  headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token'),
                  },
                }
              )
              .then(() => {
                resolve()
              })
              .catch((error) => {
                reject(error)
              })
          })
        })
        try {
          Promise.all(tipPromises)
            .then(() => {
              resolve()
            })
            .catch((error) => {
              reject(error)
            })
        } catch (error) {
          reject(error)
        }
      })
    },

    deleteProducTipsRelations: function () {
      return new Promise((resolve, reject) => {
        this.$axios
          .delete(
            `${this.$API_URL}productphysical-tips/${this.temporal_product.product_physical.product_physical_id}`,
            {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token'),
              },
            }
          )
          .then(() => {
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
  },
}

export default ProductTip
