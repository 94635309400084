export default {
  name: 'PeriodsForm',

  data() {
    return {
      period: {
        period_id: null,
        period: null,
      },

      isNew: true,
      btnDisabled: false,
    }
  },

  created: function () {
    this.$bus.$on('OPEN-UPDATE-FORM', (newPeriod) => {
      this.isNew = false

      this.period.period_id = newPeriod.period_id
      this.period.period = newPeriod.period
    })
  },

  methods: {
    checkForm: function (option) {
      this.btnDisabled = true

      if (option === 1) {
        if (!this.period.period) {
          this.$toastr.error('All fields are required', 'Error!')

          this.btnDisabled = false
        }

        if (this.period.period) {
          this.createPeriod()
        }
      }

      if (option === 2) {
        if (!this.period.period) {
          this.$toastr.error('All fields are required', 'Error!')

          this.btnDisabled = false
        }

        if (this.period.period) {
          this.updatePeriod()
        }
      }
    },

    createPeriod: function () {
      this.$axios
        .post(
          this.$API_URL + 'periods',
          {
            period: this.period.period,
          },
          {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
          }
        )
        .then(() => {
          this.$bus.$emit('REFRESH-PERIODS-LIST')
          this.clearForm()
          this.$emit('cancelForm')

          this.$toastr.success(
            'The Period has been created successfully!',
            'Success!'
          )

          this.btnDisabled = false
        })
        .catch((error) => {
          this.$toastr.error(error, 'Error!')
          this.btnDisabled = false
        })
    },

    updatePeriod: function () {
      this.$axios
        .put(
          this.$API_URL + 'periods/' + this.period.period_id,
          {
            period: this.period.period,
          },
          {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
          }
        )
        .then(() => {
          this.$bus.$emit('REFRESH-PERIODS-LIST')
          this.cancelForm()

          this.$toastr.info(
            'The Period has been Updated successfully!',
            'Success!'
          )

          this.btnDisabled = false
        })
        .catch((error) => {
          this.$toastr.error(error, 'Error!')
          this.btnDisabled = false
        })
    },

    cancelForm: function () {
      this.isNew = true
      this.clearForm()
      this.$emit('cancelForm')
    },

    clearForm: function () {
      this.period.period_id = null
      this.period.period = ''
    },
  },
}
