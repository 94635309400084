import BreadcrumbComponent from '@/components/general/BreadcrumbComponent/Breadcrumb.vue';
import UserProfileForm from '@/components/UserProfile/UserProfileForm/UserProfileForm.vue';
import RolesProfileList from '@/components/RolesProfile/RolesProfileList/RolesProfileList.vue';

export default {
    name: 'Profile',
    components: {
        UserProfileForm,
        RolesProfileList,
        BreadcrumbComponent
    },

    data () {
        return {
            
        }
    },

    methods: {
        
    }
}
