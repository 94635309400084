import { render, staticRenderFns } from "./KeyWordsForm.vue?vue&type=template&id=57f09a78&scoped=true"
import script from "./KeyWordsForm.js?vue&type=script&lang=js&external"
export * from "./KeyWordsForm.js?vue&type=script&lang=js&external"
import style0 from "./KeyWordsForm.scss?vue&type=style&index=0&id=57f09a78&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57f09a78",
  null
  
)

export default component.exports