import ProductCategory from '@/components/Products/mixins/ProductCategory'

export default {
  name: 'CategoryFilter',

  props: ['categoryFilter', 'setProductFilters'],

  mixins: [ProductCategory],

  data() {
    return {
      categoriesForFilters: [], // Array with all the categories for feed the category filter component
    }
  },

  created: function () {
    this.getProductsCategories()
  },

  methods: {
    /**
     * Getting all categories for feed the category filter component. The components are sorted and listed
     * by category level. The result will be assigned to this.categoriesForFilters variable.
     */
    getProductsCategories: async function () {
      this.categoriesForFilters = await this.getCategoriesForFilters()
    },

    /**
     * Get a category filter and set it to this.categoryFilter. If the category is the same of the current
     * filter (if exist) the handle will be ignored.
     * @param {string} category - category selected from the category filter list
     * @returns {void}
     */
    handleCategoryFilter: function (category) {
      if (this.categoryFilter) {
        if (this.categoryFilter === category) {
          return
        }
      }
      this.setProductFilters({
        category: category,
      })
    },

    /**
     * Close all accordions that conform the category filter component. For do that, is necessary obtain all
     * html div elements that contains the 'collapse' class, and if exists, remove the 'show' class.
     * This action is to handle the collapsed accordions and avoid disorder between elements.
     */
    closeAccordions: function () {
      // Selecting all elements that contains the 'collapse' class
      const collapseElements = document.querySelectorAll('div.collapse')
      // Removing the 'show' class for every single element
      for (let i = 0; i < collapseElements.length; i++) {
        collapseElements[i].classList.remove('show')
      }
    },
  },

  watch: {
    categoryFilter: function () {
      if (!this.categoryFilter) {
        // If the category filter exist, before remove the others filters, is necessary close the accordions.
        this.closeAccordions()
      }
    },
  },
}
