import ProductsCRUD from '@/components/Products/mixins/ProductsCRUD'

export default {
  name: 'PaginationProducts',

  props: ['setProductFilters', 'pagination', 'products'],

  mixins: [ProductsCRUD],

  /**
   * Data of the Component
   * @returns {Object} - the object contains:
   *  - offsetPagination {Number} - establishes the number of buttons is shows in the pagination.
   *      the offset is 2 to rigth and 2 to left of the current page button. In total is 5
   *      buttons in the pagination.
   */
  data() {
    return {
      offsetPagination: 2,
    }
  },

  methods: {
    /**
     * Assign the current page of the pagination to the main filters array through setProductFilters prop method.
     * @param {Number} page - number of page to change the pagination and get a new list of produts
     */
    changePage: async function (page) {
      try {
        this.setProductFilters({
          page: page,
        })
      } catch (error) {
        this.$toastr.error(error, 'Error!')
      }
    },
  },

  computed: {
    isActive: function () {
      return this.pagination.current_page
    },

    /**
     * Calculate the numbers that compose the pagination.
     * @returns {Array} pagesArray - contain the number of pages that will be show in the
     *  pagination. These numbers are calculated by the pagination data.
     */
    pagesNumber: function () {
      if (!this.pagination.to) {
        return []
      }

      var from = this.pagination.current_page - this.offsetPagination
      if (from < 1) {
        from = 1
      }

      var to = from + this.offsetPagination * 2
      if (to >= this.pagination.last_page) {
        to = this.pagination.last_page
      }

      var pagesArray = []
      while (from <= to) {
        pagesArray.push(from)
        from++
      }

      return pagesArray
    },
  },
}
