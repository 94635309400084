export default {
  name: 'StatesForm',

  data() {
    return {
      state: {
        state_id: null,
        name: null,
      },

      isNew: true,
      btnDisabled: false,
    }
  },

  created: function () {
    this.$bus.$on('OPEN-UPDATE-FORM', (newState) => {
      this.isNew = false

      this.state.state_id = newState.state_id
      this.state.name = newState.name
    })
  },

  methods: {
    checkForm: function (option) {
      this.btnDisabled = true

      if (option === 1) {
        if (!this.state.name) {
          this.$toastr.error('All fields are required', 'Error!')

          this.btnDisabled = false
        }

        if (this.state.name) {
          this.createState()
        }
      }

      if (option === 2) {
        if (!this.state.name) {
          this.$toastr.error('All fields are required', 'Error!')

          this.btnDisabled = false
        }

        if (this.state.name) {
          this.updateState()
        }
      }
    },

    createState: function () {
      this.$axios
        .post(
          this.$API_URL + 'states',
          {
            name: this.state.name,
          },
          {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
          }
        )
        .then(() => {
          this.$bus.$emit('REFRESH-STATES-LIST')
          this.clearForm()
          this.$emit('cancelForm')

          this.$toastr.success(
            'The State has been created successfully!',
            'Success!'
          )

          this.btnDisabled = false
        })
        .catch((error) => {
          this.$toastr.error(error, 'Error!')
          this.btnDisabled = false
        })
    },

    updateState: function () {
      this.$axios
        .put(
          this.$API_URL + 'states/' + this.state.state_id,
          {
            name: this.state.name,
          },
          {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
          }
        )
        .then(() => {
          this.$bus.$emit('REFRESH-STATES-LIST')
          this.cancelForm()

          this.$toastr.info(
            'The State has been Updated successfully!',
            'Success!'
          )

          this.btnDisabled = false
        })
        .catch((error) => {
          this.$toastr.error(error, 'Error!')
          this.btnDisabled = false
        })
    },

    cancelForm: function () {
      this.isNew = true
      this.clearForm()
      this.$emit('cancelForm')
    },

    clearForm: function () {
      this.state.state_id = null
      this.state.name = ''
    },
  },
}
