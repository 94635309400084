import BreadcrumbComponent from '@/components/general/BreadcrumbComponent/Breadcrumb.vue';
import ConditionsForm from '@/components/Conditions/ConditionsForm/ConditionsForm.vue';
import ConditionsList from '@/components/Conditions/ConditionsList/ConditionsList.vue';

export default {
    name: 'Conditions',
    
    components: {
        BreadcrumbComponent,
        ConditionsForm,
        ConditionsList
    },

    data () {
        return {
            isForm: false
        }
    },

    methods: {
        showForm: function () {
            this.isForm = true;
        },

        hideForm: function () {
            this.isForm = false;
        }
    }
}
