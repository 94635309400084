import Multiselect from 'vue-multiselect'

export default {
  name: 'DiscountForm',
  components: {
    Multiselect,
  },
  data() {
    return {
      discount: {
        discount_id: null,
        type: null,
        expiration_date: null,
        percentage: null,
      },

      isNew: true,
      btnDisabled: false,
      options: [
        'Discount for a single product',
        'Discount for a category',
        'Discount for a Designer',
      ],
    }
  },

  created: function () {
    this.$bus.$on('OPEN-UPDATE-FORM', (newDiscount) => {
      this.isNew = false

      this.discount.discount_id = newDiscount.discount_id
      this.discount.type = newDiscount.type
      this.discount.expiration_date = newDiscount.expiration_date
      this.discount.percentage = newDiscount.percentage
    })
  },

  methods: {
    checkForm: function (option) {
      this.btnDisabled = true

      if (option === 'save') {
        if (!this.discount.type) {
          this.$toastr.error('All fields are required', 'Error!')

          this.btnDisabled = false
        }

        if (this.discount.type) {
          this.createDiscount()
        }
      }

      if (option === 'update') {
        if (!this.discount.type) {
          this.$toastr.error('All fields are required', 'Error!')

          this.btnDisabled = false
        }

        if (this.discount.type) {
          this.updateDiscount()
        }
      }
    },

    createDiscount: function () {
      this.$axios
        .post(
          this.$API_URL + 'discounts',
          {
            type: this.discount.type,
            expiration_date: this.discount.expiration_date,
            percentage: this.discount.percentage,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
          }
        )
        .then(() => {
          this.$bus.$emit('REFRESH-DISCOUNT-LIST')
          this.clearForm()
          this.$emit('cancelForm')

          this.$toastr.success(
            'The Discount has been created successfully!',
            'Success!'
          )

          this.btnDisabled = false
        })
        .catch((error) => {
          this.$toastr.error(error, 'Error!')
          this.btnDisabled = false
        })
    },

    updateDiscount: function () {
      this.$axios
        .put(
          this.$API_URL + 'discounts/' + this.discount.discount_id,
          {
            type: this.discount.type,
            expiration_date: this.discount.expiration_date,
            percentage: this.discount.percentage,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
          }
        )
        .then(() => {
          this.$bus.$emit('REFRESH-DISCOUNT-LIST')
          this.cancelForm()

          this.$toastr.info(
            'The Discount has been updated successfully!',
            'Success!'
          )

          this.btnDisabled = false
        })
        .catch((error) => {
          this.$toastr.error(error, 'Error!')
          this.btnDisabled = false
        })
    },

    cancelForm: function () {
      this.isNew = true
      this.clearForm()
      this.$emit('cancelForm')
    },

    clearForm: function () {
      this.discount.discount_id = null
      this.discount.type = ''
      this.discount.expiration_date = ''
      this.discount.percentage = ''
    },
  },
}
