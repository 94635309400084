import BreadcrumbComponent from '@/components/general/BreadcrumbComponent/Breadcrumb.vue';
import PeriodsForm from '@/components/Periods/PeriodsForm/PeriodsForm.vue';
import PeriodsList from '@/components/Periods/PeriodsList/PeriodsList.vue';

export default {
    name: 'Periods',
    components: {
        BreadcrumbComponent,
        PeriodsForm,
        PeriodsList
    },

    data () {
        return {
            isForm: false
        }
    },

    methods: {
        showForm: function () {
            this.isForm = true;
        },

        hideForm: function () {
            this.isForm = false;
        }
    }
}
