import BreadcrumbComponent from '@/components/general/BreadcrumbComponent/Breadcrumb.vue';
import PostsForm from '@/components/Posts/PostsForm/PostsForm.vue';
import PostsList from '@/components/Posts/PostsList/PostsList.vue';

export default {
    name: 'Posts',
    components: {
        BreadcrumbComponent,
        PostsForm,
        PostsList
    },

    data () {
        return {
            posts: null,
            post: {
                post_id: null,
                title: null,
                body: null,
            },
            isNew: true,
            isForm: false     //bandera para mostrar o no el formulario
        }
    },

    created: function() {
        this.$bus.$on('REFRESH-POSTS-LIST', (posts) => {
            this.posts = posts
        })

        this.$bus.$on('REFRESH-POST-OBJECT', (post) => {

            this.post.post_id = null
            this.post.title = null
            this.post.body = null
            this.post.creator_id = null
            
            this.post.post_id = post.post_id
            this.post.title = post.title
            this.post.body = post.body            
            this.post.creator_id = post.creator_id
            
            this.isNew = false
        })
    },

    methods: {
        showForm: function () {
            this.isForm = true;
        },

        hideForm: function () {
            this.isNew = true
            this.isForm = false;
        }
    }
}
