import axios from 'axios';

export default {
  name: 'BannerList',
  data() {
    return {
      banners: [],
      error: '',
      currentPage: 1,
      itemsPerPage: 5,
    };
  },
  computed: {
    paginatedBanners() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.banners.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.banners.length / this.itemsPerPage);
    },
  },
  methods: {
    async fetchBanners() {
      this.error = '';
      this.$bus.$emit('SHOW-LOADING'); // Show loading spinner

      try {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}banner/`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        this.banners = response.data.data;
      } catch (error) {
        this.error = error.response.data || 'Failed to fetch banners.';
        this.$toastr.error(this.error, 'Error');
      } finally {
        this.$bus.$emit('HIDDEN-LOADING'); // Hide loading spinner
      }
    },
    goToCreate() {
      this.$router.push('banners/create');
    },
    goToUpdate(id) {
      this.$router.push(`banners/update/${id}`);
    },
    async deleteBanner(id) {
      if (confirm('Are you sure you want to delete this banner?')) {
        this.$bus.$emit('SHOW-LOADING'); // Show loading spinner

        try {
          await axios.delete(`${process.env.VUE_APP_API_URL}banner/${id}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          });
          
          // Remove the deleted banner from the list without reloading the page
          this.banners = this.banners.filter(banner => banner.id !== id);
          this.$toastr.success('Banner deleted successfully.', 'Success');
        } catch (error) {
          this.error = error.response.data || 'Failed to delete banner.';
          this.$toastr.error(this.error, 'Error');
        } finally {
          this.$bus.$emit('HIDDEN-LOADING'); // Hide loading spinner
        }
      }
    },
    changePage(page) {
      this.currentPage = page;
    },
  },
  created() {
    this.fetchBanners();
  },
};
