const PDFCard = {
  methods: {
    generatePDF: async function (product) {
      //1. Getting pdfMake ready
      var pdfMake = require('pdfmake/build/pdfmake.js')
      if (pdfMake.vfs == undefined) {
        var pdfFonts = require('pdfmake/build/vfs_fonts.js')
        pdfMake.vfs = pdfFonts.vfs
      }

      //2. Getting the image in dataURL format
      let productImage = await this.toDataURL(product.product_id)

      //3. Getting written information together
      var styles = []
      var materials = ''

      product.product_physical.styles.forEach((s) => styles.push(s.name))
      materials = product.product_physical.materials
        ? product.product_physical.materials
        : 'not specified'

      styles = styles.join(', ')

      //4. Designing and organizing pdf
      var pageWidth = 213
      var pageHeigth = 358

      var docDefinition = {
        info: {
          title: product.product_physical.name,
          author: 'Danish Modern L.A.',
          subject: 'Admin',
        },
        compress: false,
        pageMargins: [10, 10, 10, 10],
        pageOrientation: 'landscape',
        pageSize: {
          width: pageWidth,
          height: pageHeigth,
        },
        background: function () {
          return {
            canvas: [
              {
                type: 'rect',
                x: 0,
                y: 0,
                w: 358,
                h: 213,
                color: '#000000',
              },
              {
                type: 'rect',
                x: 1,
                y: 1,
                w: 356,
                h: 211,
                color: '#FFFFFF',
              },
              {
                type: 'rect',
                x: 168,
                y: 50,
                w: 1,
                h: 135,
                color: '#729201',
              },
            ],
          }
        },
        content: [
          {
            text: product.product_physical.name + '\n',
            alignment: 'center',
            fontSize: 11,
            margin: [0, 10, 0, 20],
          },
          {
            columns: [
              {
                image: productImage,
                width: 143,
                height: pageHeigth / 3,
              },
              {
                width: '60%',
                text: [
                  { text: 'Manufacturer: ', bold: true },
                  product.product_physical.manufacturer
                    ? product.product_physical.manufacturer.name
                    : 'not specified',
                  { text: '\nCountry: ', bold: true },
                  product.product_physical.country
                    ? product.product_physical.country.name
                    : 'not specified',
                  { text: '\nDate: ', bold: true },
                  product.product_physical.period
                    ? product.product_physical.period.period
                    : 'not specified',
                  { text: '\nMaterials: ', bold: true },
                  materials,
                  { text: '\nPeriod Styles: ', bold: true },
                  styles,
                  { text: '\nDimentions: ', bold: true },
                  product.product_physical.dimensions
                    ? product.product_physical.dimensions
                    : 'not specified',
                  { text: '\n\nPrice: $', bold: true },
                  product.price ? product.price : 'not specified',
                ],
                fontSize: 8,
                lineHeight: 1.25,
                margin: [30, 0, 0, 0],
              },
            ],
          },
        ],
      }

      //Displaying pdf
      pdfMake.createPdf(docDefinition).open({}, window.open('', 'blank'))
    },

    toDataURL: function (productId) {
      return new Promise((resolve, reject) => {
        this.$axios
          .get(`${this.$API_URL}product-firstimage/${productId}`, {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
          })
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
  },
}

export default PDFCard
