export const TipsCrud = {
  methods: {
    getTips: function () {
      this.isLoading = true

      this.$axios
        .get(this.$API_URL + 'tips', {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          },
        })
        .then((response) => {
          this.$bus.$emit('REFRESH-TIPS-LIST', response.data)

          this.isLoading = false
        })
        .catch((error) => {
          this.$toastr.error(error, 'Error!')
        })
    },

    createTip: function (tip) {
      return new Promise((resolve, reject) => {
        this.$axios
          .post(
            this.$API_URL + 'tips',
            {
              name: tip.name,
              description: tip.description,
            },
            {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token'),
              },
            }
          )
          .then((response) => {
            resolve(response.data.tip_id)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    updateTip: function (tip) {
      return new Promise((resolve, reject) => {
        this.$axios
          .put(
            this.$API_URL + 'tips/' + tip.tip_id,
            {
              name: tip.name,
              description: tip.description,
            },
            {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token'),
              },
            }
          )
          .then(() => {
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    deleteTip: function (id) {
      return new Promise((resolve, reject) => {
        this.$axios
          .delete(this.$API_URL + 'tips/' + id, {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
          })
          .then(() => {
            this.getTips()
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    errorAction: function (error) {
      this.$toastr.error(error, 'Error!')
      this.btnDisabled = false
    },
  },
}
