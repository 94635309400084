// Mixins
import { Dropzone } from '@/mixins/Dropzone'
import PDFCard from '@/components/Products/mixins/PDFCard'
import ProductsCRUD from '@/components/Products/mixins/ProductsCRUD'
import ProductsImages from '@/components/Products/mixins/ProductsImages'

// Components
import Modal from '@/components/general/ModalComponent/Modal.vue'
import ProductsFilters from '../ProductsFilters/ProductsFilters.vue'
import SearchBarProducts from '../SearchBarProducts/SearchBarProducts.vue'
import PaginationProducts from '../PaginationProducts/PaginationProducts.vue'
import ProductBadge from '@/components/Products/ProductBadge/ProductBadge.vue'
import SortingFilter from '@/components/Products/SortingFilter/SortingFilter.vue'

export default {
  name: 'ProductsList',

  props: ['prods', 'pagination', 'searchProduct'],

  components: {
    Modal,
    ProductBadge,
    SortingFilter,
    ProductsFilters,
    SearchBarProducts,
    PaginationProducts,
  },

  mixins: [ProductsCRUD, Dropzone, ProductsImages, PDFCard],

  data() {
    return {
      /*
        - filters {array} - contains all filters applied to show the products, this array is used to make the request to API
        - products {Array} - has all the products to show in the list.
        - imagesPreview {Array} - contain the images to show like preview in the list.
        - productsLoading {Boolean} - control the state that show the message 'Loading...' when
            the products is loading. This variable is diferent with isLoading variable to show a GIF when
            the pages are loading.
        - temporal_product {Object} - handle the data of one product and avoid the mutation.
      */
      filters: {},
      products: [],
      imagesPreview: [],
      productsLoading: true,
      temporal_product: null,
    }
  },

  created: function () {
    //To Products.js mixin
    this.$bus.$emit('SHOW-LOADING')

    this.refreshProductList()
  },

  methods: {
    /**
     * Transfer through $bus to Product component for handle its data and move the data
     *  to ProductsForm component by props. Later, change directory.
     * @param {Object} product - Product object for move to form
     */
    moveToForm: function (product) {
      //To Products.js mixin
      this.$bus.$emit('ASSIGN-PRODUCT', product)
      this.$router.push({ name: 'ProductCRUD', params: { view: 'form' } })
    },

    assignProduct: function (prod) {
      this.temporal_product = {
        ...prod,
      }
    },

    deleteProcess: async function () {
      //To Products.js
      this.$bus.$emit('SHOW-LOADING')

      try {
        //To ProductsImages
        await this.deleteImgs(
          this.temporal_product.product_id,
          this.temporal_product.images
        )

        //To ProductsCRUD
        await this.deleteProductPhysical(
          this.temporal_product.product_physical.product_physical_id
        )
        await this.deleteProduct(this.temporal_product.product_id)

        this.$toastr.warning(
          'The Product has been deleted successfully!',
          'Deleted!'
        )

        //To ProductsCRUD
        this.refreshProductList()
      } catch (error) {
        this.$toastr.error(
          error +
            ' - Probably the product you want to eliminate is being used by something!',
          'Error!'
        )
        this.refreshProductList()
      }
    },

    repostProduct: async function () {
      try {
        //To ProductsCRUD
        await this.updateTimestampProduct(this.temporal_product.product_id)

        this.$toastr.info(
          'The Product has been resposted successfully!',
          'Reposted!'
        )

        //To ProductsCRUD
        this.refreshProductList()
      } catch (error) {
        this.$toastr.error(error, 'Error!')
        this.refreshProductList()
      }
    },

    markSoldProduct: async function () {
      try {
        await this.changeToSoldState(this.temporal_product.product_id)

        this.$toastr.info(
          'The Product has been marked sold successfully!',
          'Marked Sold!'
        )

        //To ProductsCRUD
        this.refreshProductList()
      } catch (error) {
        this.$toastr.error(error, 'Error!')
        this.refreshProductList()
      }
    },

    printProductCard: async function (product) {
      // To PDFCard.js mixin
      this.generatePDF(product)
    },

    /**
     * Obtain a fresh list of products through a filters object.
     * Also, show a loading gif and assign the necesary props
     */
    refreshProductList: async function () {
      try {
        this.setProductLoading(true)
        //To ProductsCRUD
        var data = await this.getProducts(this.filters) // The filters object can be a empty object
        this.setProductLoading(false)

        //To Products.js
        this.$bus.$emit('ASSIGN-PRODUCTS-LIST', data)
        this.$bus.$emit('HIDDEN-LOADING')
      } catch (error) {
        this.setProductLoading(false)
        this.$toastr.error(error, 'Error!')
      }
    },

    /**
     * Change the this.productsLoading variable with the param value.
     * @param {Boolean} loading - option for change 'this.productsLoading' variable
     */
    setProductLoading: function (loading) {
      this.productsLoading = loading
    },

    /**
     * Receive a new filter object and add to the this.filters object and refresh the product list
     * Before refresh the product list, verify if exist a page filter, and reset it (delete from this.filters[])
     * @param {Object} newFilter - an object that contain the filter to add into the main filters object
     *  the format must be like:
     *      {
     *        typeOfFilter: valueOfFilter
     *      }
     *  for example:
     *      {
     *        state: 'active'
     *      }
     */
    setProductFilters: function (newFilter) {
      if (this.filters['page']) {
        delete this.filters['page']
      }

      this.filters = {
        ...this.filters,
        ...newFilter,
      }
      this.refreshProductList()
    },

    /**
     * Receive a string filter to delete on the this.filters main object and refresh the products list
     * Verify if there are a page filter and delete it. This is to reset the pagination to page 1 for any filter change
     * @param {String} filter - kind of filter to delete.
     */
    deleteProductFilters: function (filter) {
      if (filter === 'page') {
        delete this.filters['page']
      } else {
        if (this.filters['page']) {
          delete this.filters['page']
        }
        delete this.filters[filter]
      }

      // Destructuring the filter object because a bug on Vue that cant watch changes in arrays elements
      this.filters = { ...this.filters }
      this.refreshProductList()
    },

    /**
     * Assign an empty object to this.filters for clean and delete all filters asigned, later refresh
     * the product list
     */
    deleteAllProductFilters: function () {
      this.filters = {}
      this.refreshProductList()
    },
  },

  watch: {
    prods: function () {
      this.products = [...this.prods]
      this.setProductLoading(false)
    },
  },
}
