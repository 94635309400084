import Modal from '@/components/general/ModalComponent/Modal.vue'
import Loader from '@/components/general/LoaderComponent/Loader.vue'

export default {
  name: 'CategoryLevelsList',

  components: {
    Modal,
    Loader,
  },

  data() {
    return {
      categoryLevels: null,
      categoryLevelId: null,
      isLoading: true,
      keyWord: '',
      sort: 'asc',
    }
  },

  created: function () {
    this.getCategoryLevels()

    this.$bus.$on('REFRESH-CATEGORY-LEVELS-LIST', () => {
      this.getCategoryLevels()
    })

    this.$bus.$on('REQUEST-NEW-CAT-LEVEL', () => {
      var nexCatLevel = null
      var currentCatLevel = this.categoryLevels[this.categoryLevels.length - 1]

      if (currentCatLevel) {
        nexCatLevel = currentCatLevel.level + 1
      } else {
        nexCatLevel = 1
      }

      this.$bus.$emit('GENERATE-NEW-CAT-LEVEL', nexCatLevel)
    })
  },

  methods: {
    getCategoryLevels: function () {
      this.isLoading = true

      this.$axios
        .get(this.$API_URL + 'categorylevels', {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          },
        })
        .then((response) => {
          this.categoryLevels = response.data

          this.isLoading = false
        })
        .catch((error) => {
          this.$toastr.error(error, 'Error!')
        })
    },

    deleteCategoryLevel: function () {
      this.$axios
        .delete(this.$API_URL + 'categorylevels/' + this.categoryLevelId, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          },
        })
        .then((response) => {
          response
          this.getCategoryLevels()

          this.$toastr.warning(
            'The Category Level has been Deleted successfully!',
            'Deleted!'
          )
        })
        .catch((error) => {
          this.$toastr.error(
            error +
              ' - Probably the category level you want to eliminate is being used by some category!',
            'Error!'
          )
        })
    },

    assignCategoryLevelId: function (id) {
      this.categoryLevelId = id
    },

    dateFormated: function (d) {
      return this.$moment(d).format('MMMM Do YYYY')
    },

    sortList: function (option) {
      if (option == 'level') {
        if (this.sort == 'asc') {
          this.categoryLevels.sort(function (a, b) {
            if (a.level < b.level) {
              return -1
            }
            if (a.level > b.level) {
              return 1
            }

            return 0
          })

          this.sort = 'desc'
        } else {
          this.categoryLevels.sort(function (a, b) {
            if (a.created_at > b.level) {
              return -1
            }
            if (a.level < b.level) {
              return 1
            }

            return 0
          })

          this.sort = 'asc'
        }
      } else if (option == 'created_at') {
        if (this.sort == 'asc') {
          this.categoryLevels.sort(function (a, b) {
            if (a.created_at < b.created_at) {
              return -1
            }
            if (a.created_at > b.created_at) {
              return 1
            }

            return 0
          })

          this.sort = 'desc'
        } else {
          this.categoryLevels.sort(function (a, b) {
            if (a.created_at > b.created_at) {
              return -1
            }
            if (a.created_at < b.created_at) {
              return 1
            }

            return 0
          })

          this.sort = 'asc'
        }
      } else if (option == 'updated_at') {
        if (this.sort == 'asc') {
          this.categoryLevels.sort(function (a, b) {
            if (a.updated_at < b.updated_at) {
              return -1
            }
            if (a.updated_at > b.updated_at) {
              return 1
            }

            return 0
          })

          this.sort = 'desc'
        } else {
          this.categoryLevels.sort(function (a, b) {
            if (a.updated_at > b.updated_at) {
              return -1
            }
            if (a.updated_at < b.updated_at) {
              return 1
            }

            return 0
          })

          this.sort = 'asc'
        }
      }
    },

    scrollToTop: function () {
      window.scrollTo(0, 0)
    },
  },
}
