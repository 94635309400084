const ProductDesigner = {
  data() {
    return {
      designers: [],
      designers_temporal: [],
    }
  },
  methods: {
    getDesigners: function () {
      return new Promise((resolve, reject) => {
        this.$axios
          .get(`${this.$API_URL}designers`, {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
          })
          .then((response) => {
            this.designers = response.data
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    getProductDesigners: function () {
      return new Promise((resolve, reject) => {
        this.$axios
          .get(
            `${this.$API_URL}productphysical-designers/${this.temporal_product.product_physical.product_physical_id}`,
            {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token'),
              },
            }
          )
          .then((response) => {
            this.designers_temporal = response.data
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    saveProductDesigners: function () {
      return new Promise((resolve, reject) => {
        let designerPromises = this.designers_temporal.map((designer) => {
          return new Promise((resolve, reject) => {
            this.$axios
              .post(
                `${this.$API_URL}productphysical-designers/${this.temporal_product.product_physical.product_physical_id}/${designer.designer_id}`,
                null,
                {
                  headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token'),
                  },
                }
              )
              .then(() => {
                resolve()
              })
              .catch((error) => {
                reject(error)
              })
          })
        })
        try {
          Promise.all(designerPromises)
            .then(() => {
              resolve()
            })
            .catch((error) => {
              reject(error)
            })
        } catch (error) {
          reject(error)
        }
      })
    },

    deleteProductDesignersRelations: function () {
      return new Promise((resolve, reject) => {
        this.$axios
          .delete(
            `${this.$API_URL}productphysical-designers/${this.temporal_product.product_physical.product_physical_id}`,
            {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token'),
              },
            }
          )
          .then(() => {
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
  },
}

export default ProductDesigner
