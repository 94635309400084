export default {
  name: 'ManufacturersForm',

  data() {
    return {
      manufacturer: {
        manufacturer_id: null,
        name: null,
      },

      isNew: true,
      btnDisabled: false,
    }
  },

  created: function () {
    this.$bus.$on('OPEN-UPDATE-FORM', (newManufacturer) => {
      this.isNew = false

      this.manufacturer.manufacturer_id = newManufacturer.manufacturer_id
      this.manufacturer.name = newManufacturer.name
    })

    this.$bus.$on('CLEAR-MANUFACTURER-FORM', () => {
      this.cancelForm()
    })
  },

  methods: {
    checkForm: function (option) {
      this.btnDisabled = true

      if (option === 1) {
        if (!this.manufacturer.name) {
          this.$toastr.error('All fields are required', 'Error!')

          this.btnDisabled = false
        }

        if (this.manufacturer.name) {
          this.createManufacturer()
        }
      }

      if (option === 2) {
        if (!this.manufacturer.name) {
          this.$toastr.error('All fields are required', 'Error!')

          this.btnDisabled = false
        }

        if (this.manufacturer.name) {
          this.updateManufacturer()
        }
      }
    },

    createManufacturer: function () {
      this.$axios
        .post(
          this.$API_URL + 'manufacturers',
          {
            name: this.manufacturer.name,
          },
          {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
          }
        )
        .then(() => {
          this.$bus.$emit('REFRESH-MANUFACTURER-LIST')
          this.cancelForm()

          this.$toastr.success(
            'The manufacturer has been created successfully!',
            'Success!'
          )

          this.btnDisabled = false
        })
        .catch((error) => {
          this.$toastr.error(error, 'Error!')
          this.btnDisabled = false
        })
    },

    updateManufacturer: function () {
      this.$axios
        .put(
          this.$API_URL + 'manufacturers/' + this.manufacturer.manufacturer_id,
          {
            name: this.manufacturer.name,
          },
          {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
          }
        )
        .then(() => {
          this.$bus.$emit('REFRESH-MANUFACTURER-LIST')
          this.cancelForm()

          this.$toastr.info(
            'The manufacturer has been updated successfully!',
            'Success!'
          )

          this.btnDisabled = false
        })
        .catch((error) => {
          this.$toastr.error(error, 'Error!')
          this.btnDisabled = false
        })
    },

    cancelForm: function () {
      this.isNew = true
      this.clearForm()
      this.$emit('cancelForm')
    },

    clearForm: function () {
      this.manufacturer.manufacturer_id = null
      this.manufacturer.name = ''
    },
  },
}
