import { render, staticRenderFns } from "./PostsList.vue?vue&type=template&id=7789303a&scoped=true"
import script from "./PostsList.js?vue&type=script&lang=js&external"
export * from "./PostsList.js?vue&type=script&lang=js&external"
import style0 from "./PostsList.scss?vue&type=style&index=0&id=7789303a&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7789303a",
  null
  
)

export default component.exports