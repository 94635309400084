export const DesignersCrud = {
  methods: {
    getDesigners: function () {
      this.isLoading = true

      this.$axios
        .get(this.$API_URL + 'designers', {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          },
        })
        .then((response) => {
          this.$bus.$emit('REFRESH-DESIGNERS-LIST', response.data)

          this.isLoading = false
        })
        .catch((error) => {
          this.$toastr.error(error, 'Error!')
        })
    },

    createDesigner: function (designer) {
      return new Promise((resolve, reject) => {
        this.$axios
          .post(
            this.$API_URL + 'designers',
            {
              name: designer.name,
              biography: designer.biography ? designer.biography : '',
            },
            {
              headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.getItem('token'),
              },
            }
          )
          .then((response) => {
            resolve(response.data.designer_id)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    updateDesigner: function (designer) {
      return new Promise((resolve, reject) => {
        this.$axios
          .post(
            this.$API_URL + 'designers/' + designer.designer_id,
            {
              name: designer.name,
              biography: designer.biography,
            },
            {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token'),
              },
            }
          )
          .then(() => {
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    deleteDesigner: function (id) {
      return new Promise((resolve, reject) => {
        this.$axios
          .delete(this.$API_URL + 'designers/' + id, {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
          })
          .then(() => {
            this.getDesigners()
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    errorAction: function (error) {
      this.$toastr.error(error, 'Error!')
      this.btnDisabled = false
    },
  },
}
