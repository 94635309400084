import Modal from '@/components/general/ModalComponent/Modal.vue';
import Loader from '@/components/general/LoaderComponent/Loader.vue';
import { Dropzone } from '@/mixins/Dropzone.js';

import { DesignersCrud } from '@/components/Designers/mixins/DesignersCrud.js';

export default {
    name: 'DesignersList',
    props: ['designers'],
    mixins: [DesignersCrud,Dropzone],
    components: {
        Modal,
        Loader
    },

    data () {
        return {
            designer_temporal: null,
            designer_id: null,
            isLoading: true,
            keyWord: '',
            sort: 'asc',
            designerBios: []
        }
    },

    created: function() {
        this.getDesigners();

        this.$bus.$on('SHOW-LOADING', () => {
            this.isLoading = true;
        });

        this.$bus.$on('HIDDEN-LOADING', () => {
            this.isLoading = false;
        })
    },

    methods: {
        
        editDesigner: function (designer) {            
            this.$bus.$emit('REFRESH-DESIGNER-OBJECT', designer);
            this.$emit('showForm');
            this.scrollToTop();
        },

        deleteDesignerImages: function () {
            return new Promise( (resolve, reject) => {
                var imgsToDeletePromises = this.designer_temporal.images.map( image => {
                    //To Dropzone Mixin
                    return this.deleteImage(image.image_id);
                })

                Promise
                    .all(imgsToDeletePromises)
                    .then( () => {
                    resolve()
                })
                    .catch( error => {
                    reject(error)
                })
            })
        },

        assignDesigner: function(designer) {
            this.designer_temporal = designer;
        },

        modalResponse: async function() {
            try {
                await this.deleteDesignerImages()
                await this.deleteDesigner(this.designer_temporal.designer_id)
                this.$toastr.warning('The Designer has been deleted successfully!', "Deleted!")
            } catch (error) {
                this.$toastr.error(error + ' - Probably the designer you want to eliminate is being used by some products!', "Error!");
            }
        },

        dateFormated: function(d) {
            return this.$moment(d).format('MMMM Do YYYY');
        },

        sortList: function(option) {
            if ( option == 'biography' ) {
                if ( this.sort == 'asc' ) {
                    this.searchKeyWord.sort(function (a, b) {
                        return a.biography.localeCompare(b.biography);
                    });

                    this.sort = 'desc';
                }
                else {
                    this.searchKeyWord.sort( function (a, b) {
                        return b.biography.localeCompare(a.biography);
                    });
                    
                    this.sort = 'asc';
                }
            }
            
            else if ( option == 'created_at' ) {
                if ( this.sort == 'asc' ) {
                    this.searchKeyWord.sort(function (a, b) {
                        if (a.created_at < b.created_at) {
                            return -1;
                        }
                        if (a.created_at > b.created_at) {
                            return 1;
                        }

                        return 0;
                    });

                    this.sort = 'desc';
                }
                else {
                    this.searchKeyWord.sort( function (a, b) {
                        if (a.created_at > b.created_at) {
                            return -1;
                        }
                        if (a.created_at < b.created_at) {
                            return 1;
                        }

                        return 0;
                    });
                    
                    this.sort = 'asc';
                }
            }
            
            else if ( option == 'updated_at' ) {
                if ( this.sort == 'asc' ) {
                    this.searchKeyWord.sort(function (a, b) {
                        if (a.updated_at < b.updated_at) {
                            return -1;
                        }
                        if (a.updated_at > b.updated_at) {
                            return 1;
                        }

                        return 0;
                    });

                    this.sort = 'desc';
                }
                else {
                    this.searchKeyWord.sort( function (a, b) {
                        if (a.updated_at > b.updated_at) {
                            return -1;
                        }
                        if (a.updated_at < b.updated_at) {
                            return 1;
                        }

                        return 0;
                    });
                    
                    this.sort = 'asc';
                }
            }
        },

        scrollToTop: function () {
            window.scrollTo(0,0);
        },

        getImgPath: function(imgURL) {
            if (imgURL) {
                return this.$MEDIA_URL + imgURL;
            }
            else {
                return this.$API_URL + this.$notFoundDesignerImg;
            }
        },

        desplegateBioDesigner: function (index) {
            this.$set(this.designerBios, index, this.searchKeyWord[index].biography);
        },

        truncateBioDesigner: function (index) {
            this.$set(this.designerBios, index, this.searchKeyWord[index].biography.substr(0, 500));
        }
    },

    computed: {
        searchKeyWord: function() {
            return this.keyWord ? this.designers
                .filter( (item) => `${item.name} ${item.biography} ${item.img_name} ${item.created_at} ${item.updated_at}`
                .toLowerCase()
                .includes(
                    this.keyWord.toLowerCase()
                ) ) : this.designers
        }
    },

    watch: {
        searchKeyWord: function () {
            //Se crea un arreglo para guardar las biografias y asi poder truncarlas
            this.designerBios = [];

            this.searchKeyWord.forEach( element => {
                this.designerBios.push( element.biography.substr(0, 500) );
            } );
        }
    }
}
