<template>
  <div class="login">
    <nav class="navbar navbar-light bg-light pt-3 pb-3">
      <img
        src="../assets/images/dmla-logo.png"
        height="35"
        class="d-inline-block align-top"
        alt="Danish Modern L.A. Logo"
      />
    </nav>
    <div class="container">
      <br />
      <div class="row justify-content-center">
        <div class="col-5">
          <div class="card">
            <div class="card-header bg-light">
              <strong>Welcome to DMLA CMS</strong>
            </div>
            <div class="card-body">
              <form>
                <div class="form-group">
                  <label for="inputUsername">Username</label>
                  <input
                    v-model="username"
                    type="text"
                    class="form-control"
                    id="inputUsername"
                  />
                </div>
                <div class="form-group">
                  <label for="inputPassword">Password</label>
                  <input
                    v-model="password"
                    type="password"
                    class="form-control"
                    id="inputPassword"
                  />
                </div>
                <button
                  v-on:click.prevent="submit"
                  type="submit"
                  class="btn btn-success btn-block mt-3"
                >
                  Sign In
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'login',
  data() {
    return {
      username: '',
      password: '',
    }
  },
  mounted() {
    this.validateToken(localStorage.getItem('token')).then(() => {
      this.$router.replace({ name: 'Home' })
    })
  },

  methods: {
    submit() {
      this.$axios
        .post(this.$API_URL + 'login', {
          username: this.username,
          password: this.password,
        })
        .then((response) => {
          localStorage.setItem('token', response.data.token)
          this.$emit('authenticated', true)
        })
        .catch((error) => {
          this.$toastr.error(error, 'Error!')
        })
    },

    validateToken: function (token) {
      return new Promise((resolve, reject) => {
        this.$axios
          .post(this.$API_URL + 'validate-token', {
            token: token,
          })
          .then((response) => {
            if (response.data.valid) {
              resolve(true)
            }
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
  },
}
</script>
