import ChipFilters from '@/components/Products/ChipFilters/ChipFilters.vue'
import StateFilter from '@/components/Products/StateFilter/StateFilter.vue'
import PeriodFilter from '@/components/Products/PeriodFilter/PeriodFilter.vue'
import CategoryFilter from '@/components/Products/CategoryFilter/CategoryFilter.vue'
import DesignerFilter from '@/components/Products/DesignerFilter/DesignerFilter.vue'

export default {
  name: 'ProductsFilters',

  props: [
    'filters',
    'setProductFilters',
    'deleteProductFilters',
    'deleteAllProductFilters',
  ],

  components: {
    ChipFilters,
    StateFilter,
    PeriodFilter,
    CategoryFilter,
    DesignerFilter,
  },

  methods: {
    /**
     * Obtain a filter to delete using the prop method 'deleteProductFilter'
     * @param {String} filter - filter to delete using the prop method 'deleteProductFilters'
     */
    deleteFilter: function (filter) {
      // Delete the filter
      this.deleteProductFilters(filter)
    },

    /**
     * Verify if the prop object filters is empty and call the 'deleteAllProductFilters' prop method
     */
    clearFilters: function () {
      if (Object.keys(this.filters).length !== 0) {
        this.deleteAllProductFilters()
      }
    },
  },
}
