const ProductsImages = {
  data() {
    return {
      images: [],
      imgPreview: null,
      isHover: [],

      imagesM: [],
      imgPreviewM: null,
      isHoverM: [],

      objectImagesArray: [],
      imagesArrayDesktop: [],
      imagesArrayMobile: [],

      objectUris: {
        imgTypePath: '',
        addImage: 'product-images/',
        removeAllImages: 'product-images/',
      },
    }
  },

  methods: {
    saveImages: function () {
      return new Promise((resolve, reject) => {
        //Imagenes para desktop
        if (this.images.length > 0) {
          this.saveImagesDesktop()
            .then(() => {
              if (this.imagesM.length > 0) {
                this.saveImagesMobil()
                  .then(() => {
                    resolve()
                  })
                  .catch((error) => {
                    reject(error)
                  })
              } else {
                resolve()
              }
            })
            .catch((error) => {
              reject(error)
            })
        }

        //Imagenes para movil
        else if (this.imagesM.length > 0) {
          this.saveImagesMobil()
            .then(() => {
              resolve()
            })
            .catch((error) => {
              reject(error)
            })
        } else {
          resolve()
        }
      })
    },

    saveImagesDesktop: function () {
      return new Promise((resolve, reject) => {
        this.objectUris.imgTypePath = `img/products/product-${this.objectId}/desktop`

        //To Dropzone Mixin
        this.saveObjectImages(0) //1 para las imagenes de desktop
          .then(() => {
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    saveImagesMobil: function () {
      return new Promise((resolve, reject) => {
        this.images = []
        this.images = this.imagesM
        this.objectUris.imgTypePath = `img/products/product-${this.objectId}/mobile`

        this.saveObjectImages(1) //0 para las imagenes de movil
          .then(() => {
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    updateImages: function () {
      return new Promise((resolve, reject) => {
        let isChange = false
        let isChangeM = false

        //Verificando cambios en el arreglo de this.images
        isChange = this.verifyImagesDChange()

        //Verificando cambios en el arreglo de this.imagesM
        isChangeM = this.verifyImagesMChange()

        if (isChange) {
          this.objectImagesArray = this.imagesArrayDesktop
          this.objectUris.imgTypePath = `img/products/product-${this.objectId}/desktop`

          //To Dropzone Mixin
          this.updateObjectImages(0)
            .then(() => {
              this.objectUris.imgTypePath = ''
              this.objectImagesArray = []

              this.images = this.imagesM
              this.objectImagesArray = this.imagesArrayMobile
              this.objectUris.imgTypePath = `img/products/product-${this.objectId}/mobile`

              //To Dropzone Mixin
              this.updateObjectImagesWithoutDeleteRelations(1)
                .then(() => {
                  this.objectUris.imgTypePath = ''
                  this.objectImagesArray = []

                  resolve()
                })
                .catch((error) => {
                  reject(error)
                })
            })
            .catch((error) => {
              reject(error)
            })
        } else if (isChangeM) {
          this.images = this.imagesM
          this.objectImagesArray = this.imagesArrayMobile
          this.objectUris.imgTypePath = `img/products/product-${this.objectId}/mobile`

          //To Dropzone Mixin
          this.updateObjectImages(1)
            .then(() => {
              this.objectUris.imgTypePath = ''
              this.objectImagesArray = []

              this.images = this.imagesArrayDesktop
              this.objectImagesArray = this.imagesArrayDesktop
              this.objectUris.imgTypePath = `img/products/product-${this.objectId}/desktop`

              //To Dropzone Mixin
              this.updateObjectImagesWithoutDeleteRelations(0)
                .then(() => {
                  this.objectUris.imgTypePath = ''
                  this.objectImagesArray = []

                  resolve()
                })
                .catch((error) => {
                  reject(error)
                })
            })
            .catch((error) => {
              reject(error)
            })
        } else {
          resolve()
        }
      })
    },

    //Verifica cambios en imagenes desktop
    verifyImagesDChange: function () {
      let isChange = false

      if (this.images.length === this.imagesArrayDesktop.length) {
        for (var i = 0; i < this.imagesArrayDesktop.length; i++) {
          if (this.images[i] !== this.imagesArrayDesktop[i]) {
            isChange = true
            break
          }
        }
      } else if (this.images.length !== this.imagesArrayDesktop.length) {
        isChange = true
      }

      return isChange
    },

    //Verifica cambios en imagenes mobiles
    verifyImagesMChange: function () {
      let isChange = false

      if (this.imagesM.length === this.imagesArrayMobile.length) {
        for (var i = 0; i < this.imagesArrayMobile.length; i++) {
          if (this.imagesM[i] !== this.imagesArrayMobile[i]) {
            isChange = true
            break
          }
        }
      } else if (this.imagesM.length !== this.imagesArrayMobile.length) {
        isChange = true
      }

      return isChange
    },

    deleteImgs: function (id, imgs) {
      return new Promise((resolve, reject) => {
        //To Dropzone.js
        this.deleteAllObjectImages(id, this.objectUris)
          .then(() => {
            let imgsDeletePromise = imgs.map((i) => {
              return this.deleteImage(i.image_id)
            })

            try {
              Promise.all(imgsDeletePromise)
                .then(() => {
                  resolve()
                })
                .catch((error) => {
                  reject(error)
                })
            } catch (error) {
              reject(error)
            }
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    getURLImage: function (img) {
      let URLImage = null

      if (img.type) {
        URLImage = URL.createObjectURL(img)
      } else {
        URLImage = this.$MEDIA_URL + img.path
      }

      return URLImage
    },

    showImagePreview: function (img) {
      this.imgPreview = img
    },

    hoveImg: function (index) {
      this.$set(this.isHover, index, true)
    },

    leaveImg: function (index) {
      this.$set(this.isHover, index, false)
    },

    removeImg: function (index) {
      this.images.splice(index, 1)
      this.isHover.pop()
    },

    showImagePreviewM: function (img) {
      this.imgPreviewM = img
    },

    hoveImgM: function (index) {
      this.$set(this.isHoverM, index, true)
    },

    leaveImgM: function (index) {
      this.$set(this.isHoverM, index, false)
    },

    removeImgM: function (index) {
      this.imagesM.splice(index, 1)
      this.isHoverM.pop()
    },

    copyImages: function () {
      if (this.product.images) {
        for (var i = 0; i < this.product.images.length; i++) {
          if (this.product.images[i].isMobile === 0) {
            this.images.push(this.product.images[i])
          } else if (this.product.images[i].isMobile === 1) {
            this.imagesM.push(this.product.images[i])
          }
        }

        this.imagesArrayDesktop = [...this.images]

        this.imagesArrayMobile = [...this.imagesM]
      }
    },
  },

  watch: {
    imgPreview: function () {
      if (!this.images.includes(this.imgPreview)) {
        this.imgPreview = null
      }
    },

    imgPreviewM: function () {
      if (!this.imagesM.includes(this.imgPreviewM)) {
        this.imgPreviewM = null
      }
    },
  },
}

export default ProductsImages
