export default {
  name: 'NavbarComponent',

  methods: {
    handleLogout: function () {
      localStorage.removeItem('token')
      this.$router.replace({ name: 'login' })
    },
  },
}
