import BreadcrumbComponent from '@/components/general/BreadcrumbComponent/Breadcrumb.vue';
import StylesForm from '@/components/Styles/StylesForm/StylesForm.vue';
import StylesList from '@/components/Styles/StylesList/StylesList.vue';

export default {
    name: 'Styles',
    components: {
        BreadcrumbComponent,
        StylesForm,
        StylesList
    },

    data () {
        return {
            isForm: false
        }
    },

    methods: {
        showForm: function () {
            this.isForm = true;
        },

        hideForm: function () {
            this.isForm = false;
        }
    }
}
