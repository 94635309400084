export default {
  name: 'UserProfileForm',
  data() {
    return {
      user: {
        user_id: '',
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
      },
      btnDisabled: false,
    }
  },
  created: function () {
    this.getUser()
  },
  methods: {
    getUser: function () {
      this.$axios
        .get(this.$API_URL + 'users/1', {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          },
        })
        .then((response) => {
          this.user = response.data
        })
        .catch((error) => {
          this.$toastr.error(error, 'Error!')
        })
    },
    checkForm: function () {
      this.btnDisabled = true
      if (
        this.user.first_name != '' &&
        this.user.last_name != '' &&
        this.user.username != '' &&
        this.user.email != ''
      ) {
        this.updateUser()
      } else {
        this.$toastr.error('All fields are required.', 'Error')
        this.btnDisabled = false
      }
    },
    updateUser: function () {
      this.$axios
        .put(
          this.$API_URL + 'users/' + this.user.user_id,
          {
            first_name: this.user.first_name,
            last_name: this.user.last_name,
            username: this.user.username,
            email: this.user.email,
            phone: this.user.phone,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
          }
        )
        .then(() => {
          this.$toastr.info(
            'The user information has been Updated successfully!',
            'Success!'
          )
          this.btnDisabled = false
        })
        .catch((error) => {
          this.$toastr.error(error, 'Update Error!')
          this.btnDisabled = false
        })
    },
  },
}
