import draggable from 'vuedraggable';
import { Dropzone } from '@/mixins/Dropzone.js';
import { TipsCrud } from '@/components/Tips/mixins/TipsCrud.js';

export default {
    name: 'TipsForm',
    props: ['tip','isNew'],
    mixins: [TipsCrud, Dropzone],
    components: {
        draggable
    },
    
    data () {
        return {
            tip_temporal: {
                tip_id: null,
                name: null,
                description: null
            },

            objectUris: {
                imgTypePath: 'img/tips',
                addImage: 'tips-image/',
                removeAllImages: 'tips-images/',
            },

            objectId: '',

            objectImagesArray: '',

            imgsLimit: 1,

            images: [],

            isHover: [],

            btnDisabled: false 
        }
    },

    created: function () {
        this.copyTemporal()

        this.$bus.$on('REFRESH-TIP-OBJECT', (tip) => {
            this.images = tip.images.slice()
            this.objectImagesArray = tip.images
            this.objectId = tip.tip_id

        })
    },

    methods: {
        copyTemporal: function () {
            this.tip_temporal.tip_id = this.tip.tip_id
            this.tip_temporal.name = this.tip.name
            this.tip_temporal.description = this.tip.description
        },
        
        checkForm: function (option) {
            this.btnDisabled = true;
            
            if( option === 1 ) {
                if( !this.tip_temporal.name ) {
                    this.$toastr.error('All fields are required', 'Error!');

                    this.btnDisabled = false;
                }

                if( this.tip_temporal.name  ) {
                    this.$bus.$emit('SHOW-LOADING');
                    this.createProcess()
                }
            }
            
            if( option === 2 ) {
                if( !this.tip_temporal.name ) {
                    this.$toastr.error('All fields are required', 'Error!');

                    this.btnDisabled = false;
                }
                
                if( this.tip_temporal.name ) {
                    this.$bus.$emit('SHOW-LOADING');
                    this.updateProcess()
                }
            }
        },

        createProcess: async function () {
            try {
                this.objectId =  await this.createTip(this.tip_temporal)

                await this.saveObjectImages()

                this.$toastr.success('The Tip has been created successfully!', "Success!")
                this.finishAction()
            } catch (error) {
                this.$toastr.error(error, "Error!");
                this.finishAction()
            }
        },

        updateProcess: async function () {
            try {
                await this.updateTip(this.tip_temporal)

                await this.updateObjectImages()

                this.$toastr.info('The Tip has been Updated successfully!', "Success!");
                this.finishAction()
            } catch (error) {
                this.$toastr.error(error, "Error!");
                this.finishAction()
            }
        },

        cancelForm: function() {
            this.$emit('cancelForm');
            this.clearForm();
        },

        clearForm: function () {
            this.tip_temporal.tip_id = null;
            this.tip_temporal.name = '';
            this.tip_temporal.description = '';
            this.images = [];
        },

        getURLImage: function (img) {
            var URLImage = null;

            //Verifica si es un archivo
            if( img.type ) {
                URLImage = URL.createObjectURL(img);
            } else {
                URLImage = this.$MEDIA_URL + img.path;
            }

            return URLImage;
        },

        hoveImg: function(index) {
            this.$set(this.isHover, index, true);
        },

        leaveImg: function(index) {
            this.$set(this.isHover, index, false);
        },

        removeImg: function(index) {
            this.images.splice(index, 1);

            //Evento dirigido a componente Dropzone.js
            this.$bus.$emit('REMOVE-IMAGE', index)
        },

        finishAction: function () {
            this.getTips()
            this.cancelForm();
            this.$bus.$emit('HIDDEN-LOADING');                
            this.btnDisabled = false;
        },
    },
    watch:  {
        tip: {
            deep: true,
            handler() {
                this.copyTemporal()
            }
        }
    }
}
