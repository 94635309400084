export default {
  name: 'KeyWordsForm',

  data() {
    return {
      keyWord: {
        key_words_id: null,
        name: null,
      },

      isNew: true,
      btnDisabled: false,
    }
  },

  created: function () {
    this.$bus.$on('OPEN-UPDATE-FORM', (newKeyWord) => {
      this.isNew = false

      this.keyWord.key_words_id = newKeyWord.key_words_id
      this.keyWord.name = newKeyWord.name
    })
  },

  methods: {
    checkForm: function (option) {
      this.btnDisabled = true

      if (option === 1) {
        if (!this.keyWord.name) {
          this.$toastr.error('All fields are required', 'Error!')

          this.btnDisabled = false
        }

        if (this.keyWord.name) {
          this.createKeyWord()
        }
      }

      if (option === 2) {
        if (!this.keyWord.name) {
          this.$toastr.error('All fields are required', 'Error!')

          this.btnDisabled = false
        }

        if (this.keyWord.name) {
          this.updateKeyWord()
        }
      }
    },

    createKeyWord: function () {
      this.$axios
        .post(
          this.$API_URL + 'keywords',
          {
            name: this.keyWord.name,
          },
          {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
          }
        )
        .then(() => {
          this.$bus.$emit('REFRESH-KEY-WORDS-LIST')
          this.clearForm()
          this.$emit('cancelForm')

          this.$toastr.success(
            'The Key Word has been Created successfully!',
            'Success!'
          )

          this.btnDisabled = false
        })
        .catch((error) => {
          this.$toastr.error(error, 'Error!')
          this.btnDisabled = false
        })
    },

    updateKeyWord: function () {
      this.$axios
        .put(
          this.$API_URL + 'keywords/' + this.keyWord.key_words_id,
          {
            name: this.keyWord.name,
          },
          {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
          }
        )
        .then(() => {
          this.$bus.$emit('REFRESH-KEY-WORDS-LIST')
          this.cancelForm()

          this.$toastr.info(
            'The Key Word has been Updated successfully!',
            'Success!'
          )

          this.btnDisabled = false
        })
        .catch((error) => {
          this.$toastr.error(error, 'Error!')
          this.btnDisabled = false
        })
    },

    cancelForm: function () {
      this.isNew = true
      this.clearForm()
      this.$emit('cancelForm')
    },

    clearForm: function () {
      this.keyWord.key_words_id = null
      this.keyWord.name = ''
    },
  },
}
