import Multiselect from 'vue-multiselect'

import ProductPeriod from '@/components/Products/mixins/ProductPeriod'

export default {
  name: 'PeriodFilter',

  props: ['periodFilter', 'deleteFilter', 'setProductFilters'],

  mixins: [ProductPeriod],

  components: {
    Multiselect,
  },

  data() {
    return {
      // periods: [],      -> from ProductPeriod Mixin
      periodSelected: null, // Bind the value of the select product period compontent
    }
  },

  created: function () {
    this.getProductPeriods()
  },

  methods: {
    // getPeriods: function() {},   -> from ProductPeriod Mixin

    /**
     * Getting all product periods for feed the period option select, the period object obtained is locate
     * in the ProductPeriod Mixin but is accessible in all componente (here in ProductsFilters.js)
     */
    getProductPeriods: async function () {
      await this.getPeriods()
    },
  },

  watch: {
    /**
     * Call the prop methdod 'setProductFilters' to stablish the selected filter
     */
    periodSelected: function () {
      if (this.periodSelected) {
        this.setProductFilters({
          period: this.periodSelected.period,
        })
      } else {
        this.deleteFilter('period')
      }
    },

    /**
     * Is established like empty string to restart the select with the value 'Any Period'
     * If the periodSelected is undefined or null, this.periodSelected will be ''.
     */
    periodFilter: function () {
      if (!this.periodFilter) {
        this.periodSelected = null
      }
    },
  },
}
