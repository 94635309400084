import Modal from '@/components/general/ModalComponent/Modal.vue'
import Loader from '@/components/general/LoaderComponent/Loader.vue'

export default {
  name: 'StatesList',

  components: {
    Modal,
    Loader,
  },

  data() {
    return {
      states: null,
      state_id: null,
      isLoading: true,
      keyWord: '',
      sort: 'asc',
    }
  },

  created: function () {
    this.getStates()

    this.$bus.$on('REFRESH-STATES-LIST', () => {
      this.getStates()
    })
  },

  methods: {
    getStates: function () {
      this.isLoading = true

      this.$axios
        .get(this.$API_URL + 'states', {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          },
        })
        .then((response) => {
          this.states = response.data

          this.isLoading = false
        })
        .catch((error) => {
          this.$toastr.error(error, 'Error!')
        })
    },

    deleteState: function () {
      this.$axios
        .delete(this.$API_URL + 'states/' + this.state_id, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          },
        })
        .then(() => {
          this.getStates()

          this.$toastr.warning(
            'The State has been deleted successfully!',
            'Deleted!'
          )
        })
        .catch((error) => {
          this.$toastr.error(
            error +
              ' - Probably the State you want to eliminate is being used by some product!',
            'Error!'
          )
        })
    },

    editState: function (state) {
      this.$emit('showForm')
      this.$bus.$emit('OPEN-UPDATE-FORM', state)

      this.scrollToTop()
    },

    assignStateId: function (id) {
      this.state_id = id
    },

    dateFormated: function (d) {
      return this.$moment(d).format('MMMM Do YYYY')
    },

    sortList: function (option) {
      if (option == 'name') {
        if (this.sort == 'asc') {
          this.searchKeyWord.sort(function (a, b) {
            return a.name.localeCompare(b.name)
          })

          this.sort = 'desc'
        } else {
          this.searchKeyWord.sort(function (a, b) {
            return b.name.localeCompare(a.name)
          })

          this.sort = 'asc'
        }
      } else if (option == 'created_at') {
        if (this.sort == 'asc') {
          this.searchKeyWord.sort(function (a, b) {
            if (a.created_at < b.created_at) {
              return -1
            }
            if (a.created_at > b.created_at) {
              return 1
            }

            return 0
          })

          this.sort = 'desc'
        } else {
          this.searchKeyWord.sort(function (a, b) {
            if (a.created_at > b.created_at) {
              return -1
            }
            if (a.created_at < b.created_at) {
              return 1
            }

            return 0
          })

          this.sort = 'asc'
        }
      } else if (option == 'updated_at') {
        if (this.sort == 'asc') {
          this.searchKeyWord.sort(function (a, b) {
            if (a.updated_at < b.updated_at) {
              return -1
            }
            if (a.updated_at > b.updated_at) {
              return 1
            }

            return 0
          })

          this.sort = 'desc'
        } else {
          this.searchKeyWord.sort(function (a, b) {
            if (a.updated_at > b.updated_at) {
              return -1
            }
            if (a.updated_at < b.updated_at) {
              return 1
            }

            return 0
          })

          this.sort = 'asc'
        }
      }
    },

    scrollToTop: function () {
      window.scrollTo(0, 0)
    },
  },

  computed: {
    searchKeyWord: function () {
      return this.keyWord
        ? this.states.filter((item) =>
            `${item.name} ${item.created_at} ${item.updated_at}`
              .toLowerCase()
              .includes(this.keyWord.toLowerCase())
          )
        : this.states
    },
  },
}
