import Modal from '@/components/general/ModalComponent/Modal.vue'
import DetailsModal from '@/components/general/DetailsModalComponent/DetailsModal.vue'
import Loader from '@/components/general/LoaderComponent/Loader.vue'

export default {
  name: 'UsersList',
  components: {
    Modal,
    DetailsModal,
    Loader,
  },
  data() {
    return {
      users: null,
      user: {
        user_id: null,
        first_name: null,
        last_name: null,
        username: null,
        email: null,
        phone: null,
        created_at: null,
        updated_at: null,
        addresses: [],
      },
      keyword: null,
      order: 'asc',
      isLoading: false,
    }
  },
  created: function () {
    this.getUsers()
    this.$bus.$on('UPDATE-USER-LIST', () => {
      this.getUsers()
    })
  },
  computed: {
    users_list: function () {
      return this.keyword
        ? this.users.filter((item) =>
            `${item.id} ${item.username} ${item.email}`
              .toLowerCase()
              .includes(this.keyword.toLowerCase())
          )
        : this.users
    },
  },
  methods: {
    getUsers: function () {
      this.isLoading = true
      this.$axios
        .get(this.$API_URL + 'users', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        })
        .then((response) => {
          this.users = response.data
          this.isLoading = false
        })
        .catch((error) => {
          this.$toastr.error(error, 'Error!')
        })
    },
    editUser: function (user) {
      this.$emit('showForm')
      this.$bus.$emit('EDIT-USER', user)
    },
    detailsUser: function (user) {
      this.user = user
    },
    deleteUser: function () {
      this.$axios
        .delete(this.$API_URL + 'users/' + this.user_id, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        })
        .then(() => {
          this.$toastr.info('The user was deleted.', 'Information')
          this.getUsers()
        })
        .catch((error) => {
          this.$toastr.error(error, 'Error!')
        })
    },
    assignUserId: function (id) {
      this.user_id = id
    },
    dateFormated: function (d) {
      return this.$moment(d).format('MMMM Do YYYY')
    },
    sort: function (option) {
      if (option == 'id') {
        if (this.order == 'asc') {
          this.users_list.sort(function (a, b) {
            return a.id - b.cid
          })
          this.order = 'desc'
        } else {
          this.users_list.sort(function (a, b) {
            return b.id - a.id
          })
          this.order = 'asc'
        }
      }

      if (option == 'username') {
        if (this.order == 'asc') {
          this.users_list.sort(function (a, b) {
            if (a.username < b.username) {
              return -1
            }
            if (a.username > b.username) {
              return 1
            }
            return 0
          })
          this.order = 'desc'
        } else {
          this.users_list.sort(function (a, b) {
            if (a.username > b.username) {
              return -1
            }
            if (a.username < b.username) {
              return 1
            }
            return 0
          })
          this.order = 'asc'
        }
      }

      if (option == 'email') {
        if (this.order == 'asc') {
          this.users_list.sort(function (a, b) {
            if (a.email < b.email) {
              return -1
            }
            if (a.email > b.email) {
              return 1
            }
            return 0
          })
          this.order = 'desc'
        } else {
          this.users_list.sort(function (a, b) {
            if (a.email > b.email) {
              return -1
            }
            if (a.email < b.email) {
              return 1
            }
            return 0
          })
          this.order = 'asc'
        }
      }

      if (option == 'date') {
        if (this.order == 'asc') {
          this.users_list.sort(function (a, b) {
            return new Date(a.created_at) - new Date(b.created_at)
          })
          this.order = 'desc'
        } else {
          this.users_list.sort(function (a, b) {
            return new Date(b.created_at) - new Date(a.created_at)
          })
          this.order = 'asc'
        }
      }
    },
  },
}
