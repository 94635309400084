const ProductCategory = {
  data() {
    return {
      categories: [],
      categories_temporal: [],

      categoriesLevel1: [],
      categoriesLevel2: [],
      categoriesLevel3: [],

      catLevel1Selected: null,
      catLevel2Selected: null,
      catLevel3Selected: null,

      selectedCategories: [],
    }
  },

  methods: {
    getCategories: function () {
      return new Promise((resolve, reject) => {
        this.$axios
          .get(`${this.$API_URL}categories`, {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
          })
          .then((response) => {
            this.categories = response.data

            this.categoriesLevel1 = this.categories.filter((cat) => {
              if (cat.category_level.level === 1) {
                return cat
              }
            })

            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    getProductCategories: function () {
      return new Promise((resolve, reject) => {
        this.$axios
          .get(
            `${this.$API_URL}productphysical-categories/${this.temporal_product.product_physical.product_physical_id}`,
            {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token'),
              },
            }
          )
          .then((response) => {
            this.categories_temporal = response.data
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    saveProductCategory: function (productPhisicalId, categoryId) {
      return new Promise((resolve, reject) => {
        this.$axios
          .post(
            `${this.$API_URL}productphysical-categories/${productPhisicalId}/${categoryId}`,
            null,
            {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token'),
              },
            }
          )
          .then(() => {
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    deleteProductCategories: function (productPhysicalId) {
      return new Promise((resolve, reject) => {
        this.$axios
          .delete(
            `${this.$API_URL}productphysical-categories/${productPhysicalId}`,
            {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token'),
              },
            }
          )
          .then(() => {
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    saveCategories: function (productPhisicalId) {
      return new Promise((resolve, reject) => {
        var prodCatPromises = this.selectedCategories.map((selectedCat) => {
          return this.saveProductCategory(
            productPhisicalId,
            selectedCat[2].category_id
          )
        })

        try {
          Promise.all(prodCatPromises).then(() => {
            resolve()
          })
        } catch (error) {
          reject(error)
        }
      })
    },

    updateCategories: function () {
      return new Promise((resolve, reject) => {
        let isChange = false

        //Se verifica si hay cambios en las categorias
        if (
          this.selectedCategories.length !== this.categories_temporal.length
        ) {
          isChange = true
        } else {
          for (let x = 0; x < this.selectedCategories.length; x++) {
            if (
              this.selectedCategories[x][2].category_id !==
              this.categories_temporal[x].category_id
            ) {
              isChange = true

              break
            }
          }
        }

        if (isChange) {
          let productPhysicalId = this.temporal_product.product_physical
            .product_physical_id

          this.deleteProductCategories(productPhysicalId)
            .then(() => {
              this.saveCategories(productPhysicalId)
                .then(() => {
                  resolve()
                })
                .catch((error) => {
                  reject(error)
                })
            })
            .catch((error) => {
              reject(error)
            })
        } else {
          resolve()
        }
      })
    },

    getCategoriesForFilters: function () {
      return new Promise((resolve, reject) => {
        this.$axios
          .get(`${this.$API_URL}category-for-filters`, {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
          })
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    showSubCategory1: function (category) {
      this.categoriesLevel2 = []
      this.categoriesLevel3 = []
      this.catLevel1Selected = null
      this.catLevel2Selected = null
      this.catLevel3Selected = null

      this.catLevel1Selected = category

      for (let x = 0; x < this.categories.length; x++) {
        if (this.categories[x].category[0]) {
          if (
            this.categories[x].category[0].category_id === category.category_id
          ) {
            this.categoriesLevel2.push(this.categories[x])
          }
        }
      }
    },

    showSubCategory2: function (category) {
      this.categoriesLevel3 = []
      this.catLevel2Selected = null
      this.catLevel3Selected = null

      this.catLevel2Selected = category

      for (let x = 0; x < this.categories.length; x++) {
        if (this.categories[x].category[0]) {
          if (
            this.categories[x].category[0].category_id === category.category_id
          ) {
            this.categoriesLevel3.push(this.categories[x])
          }
        }
      }
    },

    selectCategory: function (category) {
      this.catLevel3Selected = null

      this.catLevel3Selected = category
    },

    confirmCategory: function () {
      //Se verifica que se haya seleccionado una categoria nivel 3
      if (this.catLevel3Selected) {
        let isRepeated = false

        for (let x = 0; x < this.selectedCategories.length; x++) {
          if (
            this.selectedCategories[x][2].category_id ===
            this.catLevel3Selected.category_id
          ) {
            isRepeated = true
            this.$toastr.warning(
              "Oops, it's not allowed for a product to have repeated categories!",
              'Warning!'
            )

            break
          }
        }

        if (!isRepeated) {
          this.selectedCategories.push([
            this.catLevel1Selected,
            this.catLevel2Selected,
            this.catLevel3Selected,
          ])
        }
      }

      this.clearCategory()
    },

    assignProductCategories: function () {
      return new Promise((resolve, reject) => {
        var catSelected3 = null
        var catSelected2 = null
        var catSelected1 = null

        try {
          for (let x = 0; x < this.categories_temporal.length; x++) {
            catSelected3 = this.categories_temporal[x]
            catSelected2 = this.getCategoryParent(catSelected3)
            catSelected1 = this.getCategoryParent(catSelected2)

            this.selectedCategories.push([
              catSelected1,
              catSelected2,
              catSelected3,
            ])

            catSelected3 = null
            catSelected2 = null
            catSelected1 = null
          }

          resolve()
        } catch (error) {
          reject(error)
        }
      })
    },

    getCategoryParent: function (category) {
      for (let x = 0; x < this.categories.length; x++) {
        if (this.categories[x].sub_categories.length > 0) {
          for (let y = 0; y < this.categories[x].sub_categories.length; y++) {
            if (
              this.categories[x].sub_categories[y].category_id ===
              category.category_id
            ) {
              return this.categories[x]
            }
          }
        }
      }
    },

    clearProductCategory: function (index) {
      this.selectedCategories.splice(index, 1)
    },

    clearCategory: function () {
      this.categoriesLevel2 = []
      this.categoriesLevel3 = []

      this.catLevel1Selected = null
      this.catLevel2Selected = null
      this.catLevel3Selected = null
    },
  },
}

export default ProductCategory
