import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import toastr from 'toastr'
import moment from 'moment'

import 'bootstrap'
import '@fortawesome/fontawesome-free/js/all.js'
import '@fortawesome/fontawesome-free/css/all.css'

//Declaring bus usage
import EventBus from '@/plugins/event-bus'
Vue.use(EventBus)

//Global libraries
Vue.prototype.$axios = axios
Vue.prototype.$toastr = toastr
Vue.prototype.$moment = moment

//Global variables
Vue.prototype.$API_URL = process.env.VUE_APP_API_URL
Vue.prototype.$MEDIA_URL = process.env.VUE_APP_MEDIA_URL
Vue.prototype.$ECOMMERCE_URL = process.env.VUE_APP_ECOMMERCE_URL
Vue.prototype.$notFoundDesignerImg = 'img/designers/no_image.png'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
