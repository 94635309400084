import BreadcrumbComponent from '@/components/general/BreadcrumbComponent/Breadcrumb.vue';
import KeyWordsForm from '@/components/KeyWords/KeyWordsForm/KeyWordsForm.vue';
import KeyWordsList from '@/components/KeyWords/KeyWordsList/KeyWordsList.vue';

export default {
    name: 'KeyWords',

    components: {
        BreadcrumbComponent,
        KeyWordsForm,
        KeyWordsList
    },

    data () {
        return {
            isForm: false
        }
    },

    methods: {
        showForm: function () {
            this.isForm = true;
        },

        hideForm: function () {
            this.isForm = false;
        }
    }
}