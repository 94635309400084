import BreadcrumbComponent from '@/components/general/BreadcrumbComponent/Breadcrumb.vue'
import Loader from '@/components/general/LoaderComponent/Loader.vue'

import ProductsForm from '@/components/Products/ProductsForm/ProductsForm.vue'
import ProductsList from '@/components/Products/ProductsList/ProductsList.vue'
import ProductsCRUD from '@/components/Products/mixins/ProductsCRUD'

export default {
  name: 'Products',

  components: {
    BreadcrumbComponent,
    ProductsForm,
    ProductsList,
    Loader,
  },

  mixins: [ProductsCRUD],

  data() {
    return {
      products: null,
      product: {
        product_id: null,
        price: null,
        shipping: null,
        state: {
          state_id: null,
          name: null,
        },
        TRANSACTION_transaction_id: null,
        product_physical: {
          product_physical_id: null,
          name: null,
          slug: null,
          id_number: null,
          description: null,
          dimensions: null,
          quantity: '1',
          individual: 'individual',
          customizable: null,
          materials: null,
          extra_condition: null,
          PRODUCTS_product_id: null,
          manufacturer: {
            manufacturer_id: null,
            name: null,
          },
          country: {
            country_id: null,
            name: null,
          },
          period: {
            period_id: null,
            period: null,
          },
          condition: {
            condition_id: null,
            name: null,
          },
        },
      },

      pagination: null,

      isLoading: true,
    }
  },

  created: function () {
    this.$bus.$on('ASSIGN-PRODUCTS-LIST', (data) => {
      this.products = data.products.data
      this.pagination = data.pagination
    })

    this.$bus.$on('ASSIGN-PRODUCT', (prod) => {
      this.product = prod

      this.product.product_id = prod.product_id
      this.product.price = prod.price
      this.product.states_state_id = prod.states_state_id
      this.product.shipping = prod.shipping
      this.product.TRANSACTION_transaction_id = prod.TRANSACTION_transaction_id

      this.product.images = prod.images

      this.product.product_physical.product_physical_id =
        prod.product_physical.product_physical_id
      this.product.product_physical.name = prod.product_physical.name
      this.product.product_physical.slug = prod.product_physical.slug
      this.product.product_physical.id_number = prod.product_physical.id_number
      this.product.product_physical.description =
        prod.product_physical.description
      this.product.product_physical.dimensions =
        prod.product_physical.dimensions
      this.product.product_physical.quantity = prod.product_physical.quantity
      this.product.product_physical.individual =
        prod.product_physical.individual
      this.product.product_physical.customizable =
        prod.product_physical.customizable
      this.product.product_physical.materials = prod.product_physical.materials
      this.product.product_physical.extra_condition =
        prod.product_physical.extra_condition

      this.product.product_physical.PRODUCTS_product_id =
        prod.product_physical.PRODUCTS_product_id
      this.product.product_physical.MANUFACTURERS_manufacturer_id =
        prod.product_physical.MANUFACTURERS_manufacturer_id
      this.product.product_physical.COUNTRIES_country_id =
        prod.product_physical.COUNTRIES_country_id
      this.product.product_physical.PERIODS_period_id =
        prod.product_physical.PERIODS_period_id
      this.product.product_physical.conditions_condition_id =
        prod.product_physical.conditions_condition_id
    })

    this.$bus.$on('CLEAR-PRODUCT', () => {
      this.clearProduct()
    })

    this.$bus.$on('SHOW-LOADING', () => {
      this.isLoading = true
    })

    this.$bus.$on('HIDDEN-LOADING', () => {
      this.isLoading = false
    })
  },

  methods: {
    clearProduct: function () {
      this.product.product_id = null
      this.product.price = null
      this.product.shipping = null
      this.product.TRANSACTION_transaction_id = null

      this.product.state.state_id = null
      this.product.state.name = null

      this.product.images = []

      this.product.product_physical.product_physical_id = null
      this.product.product_physical.name = null
      this.product.product_physical.slug = null
      this.product.product_physical.id_number = null
      this.product.product_physical.description = null
      this.product.product_physical.dimensions = null
      this.product.product_physical.quantity = '1'
      this.product.product_physical.individual = 'individual'
      this.product.product_physical.customizable = null
      this.product.product_physical.materials = null
      this.product.product_physical.extra_condition = null

      this.product.product_physical.PRODUCTS_product_id = null
      this.product.product_physical.PERIODS_period_id = null
      this.product.product_physical.MANUFACTURERS_manufacturer_id = null
      this.product.product_physical.COUNTRIES_country_id = null
      this.product.product_physical.CONDITIONS_condition_id = null

      if (this.product.product_physical.manufacturer) {
        this.product.product_physical.manufacturer.manufacturer_id = null
        this.product.product_physical.manufacturer.name = null
      }

      if (this.product.product_physical.country) {
        this.product.product_physical.country.country_id = null
        this.product.product_physical.country.name = null
      }

      if (this.product.product_physical.period) {
        this.product.product_physical.period.period_id = null
        this.product.product_physical.period.name = null
      }

      if (this.product.product_physical.condition) {
        this.product.product_physical.condition.condition_id = null
        this.product.product_physical.condition.name = null
      }
    },

    /**
     * Search a Product by the name using the API through the 'getProductByName' function
     *  of 'ProductsCRUD' mixin.
     * The result is assigned to this.products and the reactivity make the rest.
     * @param {string} search - Name of product to search
     */
    searchProduct: async function (search) {
      this.products = [...(await this.getProductByName(search))]
    },
  },
}
