export const ReviewsCrud = {
  methods: {
    getProducts: function () {
      this.$axios
        .get(this.$API_URL + 'products-without-pagination', {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          },
        })
        .then((response) => {
          this.products = response.data
        })
        .catch((error) => {
          this.$toastr.error(error, 'Error!')
        })
    },

    getReviews: function () {
      this.isLoading = true

      this.$axios
        .get(this.$API_URL + 'reviews', {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          },
        })
        .then((response) => {
          this.$bus.$emit('REFRESH-REVIEWS-LIST', response.data)

          this.isLoading = false
        })
        .catch((error) => {
          this.$toastr.error(error, 'Error!')
        })
    },

    createReview: function (review) {
      return new Promise((resolve, reject) => {
        this.$axios
          .post(
            `${this.$API_URL}reviews`,
            {
              product_name: review.product_name,
              url_product: review.url_product,
              url_image: review.url_image,
              customer_name: review.customer_name,
              customer_name_url: review.customer_name_url,
              score: review.score,
              review_source: review.review_source,
              review_source_url: review.review_source_url,
              comment: review.comment,
              country: review.country,
              state: review.state,
              review_date: review.review_date,
            },
            {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token'),
              },
            }
          )
          .then((response) => {
            resolve(response.data.review_id)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    updateReview: function (review) {
      return new Promise((resolve, reject) => {
        this.$axios
          .put(
            this.$API_URL + 'reviews/' + review.review_id,
            {
              product_name: review.product_name,
              url_product: review.url_product,
              url_image: review.url_image,
              customer_name: review.customer_name,
              customer_name_url: review.customer_name_url,
              score: review.score,
              review_source: review.review_source,
              review_source_url: review.review_source_url,
              comment: review.comment,
              country: review.country,
              state: review.state,
              review_date: review.review_date,
            },
            {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token'),
              },
            }
          )
          .then(() => {
            resolve()
          })
          .catch(() => {
            reject()
          })
      })
    },

    updateTimestampReview: function (id) {
      return new Promise((resolve, reject) => {
        this.$axios
          .put(`${this.$API_URL}review-update/${id}`,
            null,
            {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token'),
              },
            }
          )
          .then(() => {
            this.getReviews()
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    deleteReview: function (id) {
      return new Promise((resolve, reject) => {
        this.$axios
          .delete(this.$API_URL + 'reviews/' + id, {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
          })
          .then(() => {
            this.getReviews()
            resolve()
          })
          .catch(() => {
            reject()
          })
      })
    },

    getReviewTypes: function () {
      return new Promise((resolve, reject) => {
        this.$axios
          .get(`${this.$API_URL}review_types`, {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
          })
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    addReviewType: function (reviewId, reviewTypeId) {
      return new Promise((resolve, reject) => {
        this.$axios
          .post(
            `${this.$API_URL}review-type/${reviewId}/${reviewTypeId}`,
            null,
            {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token'),
              },
            }
          )
          .then(() => {
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    deleteAllReviewTypes: function (reviewId) {
      return new Promise((resolve, reject) => {
        this.$axios
          .delete(`${this.$API_URL}review-types/${reviewId}`, {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
          })
          .then(() => {
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    errorAction: function (error) {
      this.$toastr.error(error, 'Error!')
      this.btnDisabled = false
    },
  },
}
