export default {
  name: 'ProductBadge',

  props: ['product', 'moveToForm', 'assignProduct', 'printProductCard'],

  data() {
    return {
      /*
        URL used for show the main image of the product. If it not exist, 
        then the componente shows a text with a black background.
      */
      URLImage: null,
    }
  },

  created: function () {
    this.getURLImage()
  },

  methods: {
    /**
     * Obtain the first coincidece of the images array of the product when the attribute 'isMobile' is
     * equal to 0 because the first image is the main image.
     * Later, concat the result with the Assets Media URL to form the complete URL Image.
     */
    getURLImage: function () {
      const locationResource = this.product.images.find(
        (img) => img.isMobile === 0
      )

      if (locationResource) {
        this.URLImage = this.$MEDIA_URL + locationResource.path
      }
    },

    /**
     * Receive a MySQL Date format and convert it to a new date with the format 'YYYY-MM-DD' using Moment JS
     * @param {Date} d - timestamp with the MySQL format.
     * @returns {Object} a moment js date with the format 'YYYY-MM-DD'
     */
    dateFormated: function (d) {
      return this.$moment(d).format('YYYY-MM-DD')
    },
  },
}
