import BreadcrumbComponent from '@/components/general/BreadcrumbComponent/Breadcrumb.vue';
import CouponsForm from '@/components/Coupons/CouponsForm/CouponsForm.vue';
import CouponsList from '@/components/Coupons/CouponsList/CouponsList.vue';

export default {
    name: 'Coupons',
    components: {
        BreadcrumbComponent,
        CouponsForm,
        CouponsList
    },
    data () {
        return {
            isForm: false
        }
    },
    methods: {
        showForm (){
            this.isForm = true
        },
        hideForm () {
            this.isForm = false
        }
    }
  }