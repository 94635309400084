export default {
  name: 'ChipFilters',

  props: ['filterName', 'filterValue', 'deleteFilter'],

  data() {
    return {
      // Label that will be show in the Chip Componente
      labelChip: '',
    }
  },

  created: function () {
    this.buildLabelChip(this.filterName, this.filterValue)
  },

  methods: {
    /**
     * Build a string with the name and value of the filter obtained. The result string will be show in the text Chip Component
     * @param {String} filterName - name or type of the filte, for example: page, sorting, category, state, etc.
     * @param {String} filterValue - value of the filter obtained
     */
    buildLabelChip: function (filterName, filterValue) {
      switch (filterName) {
        case 'page':
          this.labelChip = `Page #${filterValue}`
          break
        case 'per_page':
          this.labelChip = `Per Page  ${filterValue}`
          break
        case 'order_by':
          this.labelChip = `${
            filterValue[0].charAt(0).toUpperCase() + filterValue[0].slice(1)
          } - ${filterValue[1].toUpperCase()}`
          break
        default:
          this.labelChip =
            filterValue.charAt(0).toUpperCase() + filterValue.slice(1)
      }
    },
  },
}
