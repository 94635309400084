<template>
  <div class="home">
    <Navbar />
    <br />
    <br />
    <router-view />
  </div>
</template>

<script>
// rm -rf node_modules package-lock.json

// @ is an alias to /src
import Navbar from "@/components/general/NavbarComponent/Navbar.vue";

export default {
  name: "home",
  components: {
    Navbar,
  },
};
</script>
