<template>
    <div class="container order-overview mt-4">
        <h2>Order List</h2>
        <hr>
        <table class="table table-hover">
            <thead class="table-dark">
                <tr>
                    <th>Sr. No</th>
                    <th>User Name</th>
                    <th>Payment ID</th>
                    <th>Total</th>
                    <th>State</th>
                    <th>Created At</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(transaction, index) in transactions" :key="transaction.transaction_id">
                    <td>{{ (currentPage - 1) * perPage + index + 1 }}</td>
                    <td>{{ transaction.user ? transaction.user.first_name + ' ' + transaction.user.last_name : 'N/A' }}</td>
                    <td>{{ transaction.payment_id }}</td>
                    <td>{{ transaction.total }}</td>
                    <td>{{ transaction.state }}</td>
                    <td>{{ new Date(transaction.created_at).toLocaleString() }}</td>
                    <td>
                        <button @click="goToDetail(transaction.transaction_id)" class="btn btn-outline-secondary me-2">  <i class="bi bi-pencil-square mx-2"></i>Details</button>
                    </td>
                </tr>
            </tbody>
        </table>

        <!-- Pagination -->
        <nav aria-label="Page navigation example">
            <div class="pagination justify-content-center">
    <ul class="pagination">
        <li class="page-item" :class="{ disabled: !prevPage }">
            <button class="page-link" @click="fetchTransactions(prevPage)" :disabled="!prevPage">
                Previous
            </button>
        </li>
        <li class="page-item" v-for="page in pages" :key="page.label" :class="{ active: page.active }">
            <button class="page-link" @click="fetchTransactions(page.url)">
                {{ page.label }}
            </button>
        </li>
        <li class="page-item" :class="{ disabled: !nextPage }">
            <button class="page-link" @click="fetchTransactions(nextPage)" :disabled="!nextPage">
                Next
            </button>
        </li>
    </ul>
</div>


    </nav>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            transactions: [],
            currentPage: 1,
            totalPages: 1,
            prevPage: null,
            nextPage: null,
            pages: [],
            perPage: 1, // Default per-page value
        };
    },
    mounted() {
        this.fetchTransactions();
    },
    methods: {
        async fetchTransactions(pageUrl = `${process.env.VUE_APP_API_URL}getAllTransactionData?page=1`) {
            try {
                const response = await axios.get(pageUrl, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                });
                const data = response.data.data;

                this.transactions = data.data; // Transactions array
                this.currentPage = data.current_page;
                this.totalPages = data.last_page;
                this.prevPage = data.prev_page_url;
                this.nextPage = data.next_page_url;
                this.perPage = data.per_page; // Get per-page value from API
                this.pages = data.links
            .filter(link => link.url !== null)
            .map(link => ({
                ...link,
                label: link.label.replace(/&raquo;|&laquo;/g, '').trim()
            }));

            } catch (error) {
                console.error("Error fetching transactions:", error);
            }
        },
        goToDetail(id) {
      this.$router.push(`showtransactions/${id}`);
    },
    }
};
</script>
