export default {
  name: 'CouponsForm',
  data() {
    return {
      coupon: {
        coupon_id: null,
        code: null,
        email: null,
        expiration_date: null,
        percentage: null,
      },
      isNew: true,
      btnDisabled: false,
    }
  },
  created: function () {
    this.$bus.$on('EDIT-COUPON', (new_coupon) => {
      this.isNew = false
      this.coupon.coupon_id = new_coupon.coupon_id
      this.coupon.code = new_coupon.code
      this.coupon.email = new_coupon.email
      this.coupon.expiration_date = new_coupon.expiration_date
      this.coupon.percentage = new_coupon.percentage
    })
  },
  methods: {
    checkForm: function (option) {
      this.btnDisabled = true
      if (
        this.coupon.code != '' &&
        this.coupon.email != '' &&
        this.coupon.expiration_date != '' &&
        this.coupon.percentage != ''
      ) {
        if (option == 'save') {
          this.createCoupon()
        } else {
          this.updateCoupon()
        }
      } else {
        this.$toastr.error('All fields are required.', 'Error')
        this.btnDisabled = false
      }
    },
    createCoupon: function () {
      this.$axios
        .post(
          this.$API_URL + 'coupons',
          {
            code: this.coupon.code,
            email: this.coupon.email,
            expiration_date: this.coupon.expiration_date,
            percentage: this.coupon.percentage,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
          }
        )
        .then((response) => {
          response
          this.$toastr.success(
            'The coupon has been created succesfully.',
            'Success!'
          )
          this.$bus.$emit('UPDATE-COUPON-LIST')
          this.btnDisabled = false
          this.cleanForm()
        })
        .catch((error) => {
          this.$toastr.error(error, 'Create Error!')
          this.btnDisabled = false
        })
    },
    updateCoupon: function () {
      this.$axios
        .put(
          this.$API_URL + 'coupons/' + this.coupon.coupon_id,
          {
            code: this.coupon.code,
            email: this.coupon.email,
            expiration_date: this.coupon.expiration_date,
            percentage: this.coupon.percentage,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
          }
        )
        .then((response) => {
          response
          this.$bus.$emit('UPDATE-COUPON-LIST')
          this.$toastr.info(
            'The coupon was updated succesfully.',
            'Information'
          )
          this.btnDisabled = false
          this.cancelForm()
        })
        .catch((error) => {
          this.$toastr.error(error, 'Update Error!')
          this.btnDisabled = false
        })
    },
    cancelForm: function () {
      this.$emit('cancelForm')
      this.cleanForm()
      this.isNew = true
    },
    cleanForm: function () {
      this.coupon.coupon_id = null
      this.coupon.code = ''
      this.coupon.email = ''
      this.coupon.percentage = ''
      this.coupon.expiration_date = ''
    },
  },
}
