import BreadcrumbComponent from '@/components/general/BreadcrumbComponent/Breadcrumb.vue';
import CountriesForm from '@/components/Countries/CountriesForm/CountriesForm.vue';
import CountriesList from '@/components/Countries/CountriesList/CountriesList.vue';

export default {
    name: 'Countries',
    
    components: {
        BreadcrumbComponent,
        CountriesForm,
        CountriesList
    },

    data () {
        return {
            isForm: false
        }
    },

    methods: {
        showForm: function () {
            this.isForm = true;
        },

        hideForm: function () {
            this.isForm = false;
        }
    }
}
