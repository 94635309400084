<template>
  <div id="app">
    <router-view @authenticated="setAuthenticated" />
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      authenticated: localStorage.getItem('token'),
    }
  },
  mounted() {
    this.validateToken(this.authenticated).catch(() => {
      this.$router.replace({ name: 'login' })
    })
  },
  methods: {
    setAuthenticated: function (status) {
      this.authenticated = status
      this.$router.replace({ name: 'Home' })
    },

    validateToken: function (token) {
      return new Promise((resolve, reject) => {
        this.$axios
          .post(this.$API_URL + 'validate-token', {
            token: token,
          })
          .then((response) => {
            if (response.data.valid) {
              resolve(true)
            }
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
  },
}
</script>

<style lang="scss" src="./assets/main.scss"></style>