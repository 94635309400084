import BreadcrumbComponent from '@/components/general/BreadcrumbComponent/Breadcrumb.vue';
import DesignersForm from '@/components/Designers/DesignersForm/DesignersForm.vue';
import DesignersList from '@/components/Designers/DesignersList/DesignersList.vue';

export default {
    name: 'Designers',
    components: {
        BreadcrumbComponent,
        DesignersForm,
        DesignersList
    },

    data () {
        return {
            designers: null,
            designer: {
                designer_id: null,
                name: null,
                biography: null,
            },
            isNew: true,
            isForm: false
        }
    },

    created: function () {
        this.$bus.$on('REFRESH-DESIGNERS-LIST', (designers) => {
            this.designers = designers
        })

        this.$bus.$on('REFRESH-DESIGNER-OBJECT', (designer) => {
            
            this.designer.designer_id = null
            this.designer.name = null
            this.designer.biography = null
            
            this.designer.designer_id = designer.designer_id
            this.designer.name = designer.name
            this.designer.biography = designer.biography
            
            this.isNew = false
        })
    },

    methods: {
        showForm: function () {
            this.isForm = true;
        },

        hideForm: function () {
            this.isForm = false;
        }
    }
}
