import BreadcrumbComponent from '@/components/general/BreadcrumbComponent/Breadcrumb.vue';
import ManufacturersForm from '@/components/Manufacturers/ManufacturersForm/ManufacturersForm.vue';
import ManufacturersList from '@/components/Manufacturers/ManufacturersList/ManufacturersList.vue';

export default {
    name: 'Manufacturers',
    
    components: {
        BreadcrumbComponent,
        ManufacturersForm,
        ManufacturersList
    },

    data () {
        return {
            isForm: false
        }
    },

    methods: {
        showForm: function () {
            this.isForm = true;
        },

        hideForm: function () {
            this.isForm = false;
        }
    }
}