const ProductManufacturer = {
  data() {
    return {
      manufacturers: [],
    }
  },
  methods: {
    getManufacturers: function () {
      return new Promise((resolve, reject) => {
        this.$axios
          .get(`${this.$API_URL}manufacturers`, {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
          })
          .then((response) => {
            this.manufacturers = response.data
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
  },
}

export default ProductManufacturer
