export default {
  name: 'SearchBarProducts',

  props: ['searchProduct', 'setProductLoading', 'refreshProductList'],

  data() {
    return {
      search: '',
    }
  },

  methods: {
    handleSubmit: function () {
      if (!this.search.length > 0) return

      this.setProductLoading(true)
      this.searchProduct(this.search)
    },

    clearText: function () {
      this.search = ''
      this.setProductLoading(true)
      this.refreshProductList()
    },
  },
}
