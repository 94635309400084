import BreadcrumbComponent from '@/components/general/BreadcrumbComponent/Breadcrumb.vue'
import ReviewsForm from '@/components/Reviews/ReviewsForm/ReviewsForm.vue'
import ReviewsList from '@/components/Reviews/ReviewsList/ReviewsList.vue'

export default {
  name: 'Reviews',
  components: {
    BreadcrumbComponent,
    ReviewsForm,
    ReviewsList,
  },

  data() {
    return {
      reviews: null,
      review: {
        review_id: null,
        product_name: null,
        url_product: null,
        url_image: null,
        customer_name: null,
        customer_name_url: null,
        score: null,
        review_source: null,
        review_source_url: null,
        comment: '',
        country: null,
        state: null,
        users_user_id: null,
        products_product_id: null,
        review_date: null,
        review_type: [],
      },
      isNew: true,
      isForm: false,
    }
  },

  created: function () {
    this.$bus.$on('REFRESH-REVIEWS-LIST', (reviews) => {
      this.reviews = reviews
    })

    this.$bus.$on('REFRESH-REVIEW-OBJECT', (review) => {
      this.review.review_id = null
      this.review.product_name = null
      this.review.url_product = null
      this.review.url_image = null
      this.review.customer_name = null
      this.review.customer_name_url = null
      this.review.score = null
      this.review.review_source = null
      this.review.review_source_url = null
      this.review.comment = ''
      this.review.country = null
      this.review.state = null
      this.review.users_user_id = null
      this.review.products_product_id = null
      this.review.review_date = null
      this.review.review_type = []

      this.review.review_id = review.review_id
      this.review.product_name = review.product_name
      this.review.url_product = review.url_product
      this.review.url_image = review.url_image
      this.review.customer_name = review.customer_name
      this.review.customer_name_url = review.customer_name_url
      this.review.score = review.score
      this.review.review_source = review.review_source
      this.review.review_source_url = review.review_source_url
      this.review.comment = review.comment
      this.review.country = review.country
      this.review.state = review.state
      this.review.users_user_id = review.users_user_id
      this.review.products_product_id = review.products_product_id
      this.review.review_date = review.review_date
      this.review.review_type = [...review.review_type]

      this.isNew = false
    })
  },

  methods: {
    showForm: function () {
      this.isForm = true
    },

    hideForm: function () {
      this.isNew = true
      this.isForm = false
    },
  },
}
