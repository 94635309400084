import CKEditor  from '@ckeditor/ckeditor5-vue';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Multiselect from 'vue-multiselect';
import draggable from 'vuedraggable';

import { Dropzone } from '@/mixins/Dropzone.js';

import { PostsCrud } from '@/components/Posts/mixins/PostsCrud.js';



export default {
    name: 'PostsForm',
    props: ['post','isNew'],
    mixins:[PostsCrud,Dropzone],
    components: {
        ckeditor: CKEditor.component,
        Multiselect,
        draggable
    },
    
    data () {
        return {
            post_temporal: {
                post_id: null,
                title: null,
                body: null,
                creator_id: null,
            },

            objectUris: {
                imgTypePath: 'img/posts',
                addImage: 'posts-image/',
                removeAllImages: 'posts-images/',
            },

            objectId: '',

            objectImagesArray: '',

            images: [],

            imgsLimit: 1,
            
            isHover: [],
            btnDisabled: false,

            post_categories: [],
            categories: [],

            //Objetos para la configuracion de CKEditor
            editor: ClassicEditor,
            editorConfig: {
                toolbar: {
                    items: [
                        'heading', 
                        '|', 
                        'bold', 
                        'italic', 
                        'link', 
                        'bulletedList', 
                        'numberedList', 
                        '|', 
                        'undo', 
                        'redo'
                    ]
                }
            }
        }
    },

    created: function() {
        this.copyTemporal()

        this.$bus.$on('REFRESH-POST-OBJECT', (post) => {
            this.post_categories = post.categories
            this.images = post.images.slice()
            this.objectImagesArray = post.images
            this.objectId = post.post_id
        })

        this.getCategories()
    },

    methods: {
        copyTemporal: function () {
            this.post_temporal.post_id = this.post.post_id
            this.post_temporal.title = this.post.title
            this.post_temporal.body = this.post.body
            this.post_temporal.creator_id = this.post.creator_id
        },

        checkForm: function (option) {
            this.btnDisabled = true;
            
            if(this.post.title != "" && this.post.body != ""){
                if(this.post_categories.length > 0){
                    if(option == "save"){
                        this.$bus.$emit('SHOW-LOADING');
                        this.createProcess()
                    }
                    else {
                        this.$bus.$emit('SHOW-LOADING');
                        this.updateProcess()
                    }
                } else {
                    this.$toastr.error('You must assign at least one category for this post.','Error')
                    this.btnDisabled = false
                }
            } else {
                this.$toastr.error('All fields are required.','Error')
                this.btnDisabled = false
            }
        },
        
        createProcess: async function () {
            try {
                this.objectId = await this.createPost(this.post_temporal)

                await this.createPostCategories(this.objectId, this.post_categories)

                await this.saveObjectImages()

                this.getCategories()

                this.$toastr.success('The Post has been created successfully!', "Success!");
                this.finishAction()
                
            } catch (error) {
                this.$toastr.error(error, "Error!");
                this.finishAction()
            }
        },

        updateProcess: async function() {
            try {
                await this.updatePost(this.post_temporal)

                await this.updatePostCategories(this.objectId, this.post_categories)

                await this.updateObjectImages()

                this.$toastr.success('The Post has been updated successfully!', "Success!");
                this.finishAction()

            } catch (error) {
                this.$toastr.error(error, "Error!");
                this.btnDisabled = false;
            }
        },

        cancelForm: function() {
            this.clearForm();
            this.$emit('cancelForm');
        },

        clearForm: function () {
            this.post_temporal.post_id = null;
            this.post_temporal.title = '';
            this.post_temporal.body = '';
            this.post_categories = [];
            this.images = []
        },

        getURLImage: function (img) {
            var URLImage = null;

            //Verifica si es un archivo
            if( img.type ) {
                URLImage = URL.createObjectURL(img);
            } else {
                URLImage = this.$MEDIA_URL + img.path;
            }

            return URLImage;
        },

        hoveImg: function(index) {
            this.$set(this.isHover, index, true);
        },

        leaveImg: function(index) {
            this.$set(this.isHover, index, false);
        },

        removeImg: function(index) {
            this.images.splice(index, 1);

            //Evento dirigido a componente Dropzone.js
            this.$bus.$emit('REMOVE-IMAGE', index)
        },

        finishAction: function () {
            this.getPosts()
            this.cancelForm();
            this.$bus.$emit('HIDDEN-LOADING');                 
            this.btnDisabled = false;
        },
    },
    watch: {
        post: {
            deep: true,
            handler() {
                this.copyTemporal()
            }
        }
    }
}
