import BreadcrumbComponent from '@/components/general/BreadcrumbComponent/Breadcrumb.vue';
import Loader from '@/components/general/LoaderComponent/Loader.vue';

import EmailsNavs from '@/components/Emails/EmailsNavs/EmailsNavs.vue';

export default {
    name: 'Emails',

    components: {
        BreadcrumbComponent,
        Loader,
        EmailsNavs
    },

    data () {
        return {
            isLoading: true
        }
    },

    created: function() {
        this.$bus.$on('HIDDEN-LOADING', () => {
            this.isLoading = false;
        });
    }
}