const ProductPeriod = {
  data() {
    return {
      periods: [],
    }
  },
  methods: {
    getPeriods: function () {
      return new Promise((resolve, reject) => {
        this.$axios
          .get(`${this.$API_URL}periods`, {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
          })
          .then((response) => {
            this.periods = response.data
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
  },
}

export default ProductPeriod
