import BreadcrumbComponent from '@/components/general/BreadcrumbComponent/Breadcrumb.vue';
import MaterialsForm from '@/components/Materials/MaterialsForm/MaterialsForm.vue';
import MaterialsList from '@/components/Materials/MaterialsList/MaterialsList.vue';

export default {
    name: 'Materials',
    
    components: {
        BreadcrumbComponent,
        MaterialsForm,
        MaterialsList
    },

    data () {
        return {
            isForm: false
        }
    },

    methods: {
        showForm: function () {
            this.isForm = true;
        },

        hideForm: function () {
            this.isForm = false;
        }
    }
}
