import BreadcrumbComponent from '@/components/general/BreadcrumbComponent/Breadcrumb.vue';
import CategoriesForm from '@/components/Categories/CategoriesForm/CategoriesForm.vue';
import CategoriesList from '@/components/Categories/CategoriesList/CategoriesList.vue';

export default {
    name: 'Categories',
    
    components: {
        BreadcrumbComponent,
        CategoriesForm,
        CategoriesList
    },

    data () {
        return {
            categories: null,
            category: {
                category_id: null,
                name: null,
                description: null,
                slug: null,
                CATEGORY_LEVELS_category_level_id: 0,
                created_at: null,
                updated_at: null,
                category_level: {},
                category: [],
                sub_categories: [],
                images: []
            },

            objectUris: {
                imgTypePath: 'img/categories',
                addImage: 'category-images/',
                categoryImages: 'category-images/',
                removeAllImages: 'category-images/'
            },

            isNew: true,
            isForm: false
        }
    },

    created: function() {
        this.$bus.$on('ASSIGN-CATEGORIES-LIST', (categories) => {
            this.categories = categories
        });

        this.$bus.$on('REFRESH-CATEGORIES-OBJECT', (newCategory) => {
            //Limpieza de total del objeto category
            this.category.category_id = null;
            this.category.name =  null;
            this.category.description = null;
            this.category.slug = null;
            this.category.CATEGORY_LEVELS_category_level_id = 0;

            this.category.category_level = {};
            this.category.category = [];
            this.category.sub_categories = [];
            this.category.images = [];

            //Asignacion del objetod de category
            this.category.category_id = newCategory.category_id;
            this.category.name = newCategory.name;
            this.category.description = newCategory.description;
            this.category.slug = newCategory.slug;
            this.category.CATEGORY_LEVELS_category_level_id = newCategory.CATEGORY_LEVELS_category_level_id;

            //Asignacion de sus relaciones
            this.category.category_level = newCategory.category_level;
            this.category.category = newCategory.category;
            this.category.sub_categories = newCategory.sub_categories;
            this.category.images = newCategory.images;
            
            this.isNew = false
        });
    },

    methods: {
        showForm: function () {
            this.isForm = true;
        },

        hideForm: function () {
            this.isNew = true;
            this.isForm = false;
        }
    }
}