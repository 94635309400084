import CKEditor from '@ckeditor/ckeditor5-vue'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import Multiselect from 'vue-multiselect'
import draggable from 'vuedraggable'

import { Dropzone } from '@/mixins/Dropzone.js'

import { ReviewsCrud } from '@/components/Reviews/mixins/ReviewsCrud.js'

export default {
  name: 'ReviewsForm',
  props: ['review', 'isNew'],
  mixins: [ReviewsCrud, Dropzone],
  components: {
    ckeditor: CKEditor.component,
    Multiselect,
    draggable,
  },

  data() {
    return {
      review_temporal: {
        review_id: null,
        product_name: null,
        url_product: null,
        url_image: null,
        customer_name: null,
        customer_name_url: null,
        score: null,
        review_source: null,
        review_source_url: null,
        comment: '',
        country: null,
        state: null,
        users_user_id: null,
        products_product_id: null,
        review_date: null,
        review_type: [],
      },

      review_types: [],

      objectUris: {
        imgTypePath: 'img/reviews',
        addImage: 'reviews-image/',
        removeAllImages: 'reviews-images/',
      },

      objectId: '',

      objectImagesArray: '',

      images: [],

      imgsLimit: 1,

      products: [
        {
          product_physical: {
            name: '',
          },
        },
      ],

      isHover: [],
      btnDisabled: false,
      isLoading: true,

      //Objetos para la configuracion de CKEditor
      editor: ClassicEditor,
      editorConfig: {
        toolbar: {
          items: [
            'heading',
            '|',
            'bold',
            'italic',
            'link',
            'bulletedList',
            'numberedList',
            '|',
            'undo',
            'redo',
          ],
        },
      },
    }
  },

  created: function () {
    this.getReviewTypesFromAPI()
    this.copyTemporal()
  },

  methods: {
    nameProduct({ product_physical }) {
      return `${product_physical.name}`
    },

    copyTemporal: function () {
      this.review_temporal.review_id = this.review.review_id
      this.review_temporal.product_name = this.review.product_name
      this.review_temporal.url_product = this.review.url_product
      this.review_temporal.url_image = this.review.url_image
      this.review_temporal.customer_name = this.review.customer_name
      this.review_temporal.customer_name_url = this.review.customer_name_url
      this.review_temporal.score = this.review.score
      this.review_temporal.review_source = this.review.review_source
      this.review_temporal.review_source_url = this.review.review_source_url
      this.review_temporal.comment = this.review.comment
      this.review_temporal.country = this.review.country
      this.review_temporal.state = this.review.state
      this.review_temporal.users_user_id = this.review.users_user_id
      this.review_temporal.products_product_id = this.review.products_product_id
      this.review_temporal.review_date = this.review.review_date
        ? this.review.review_date.slice(0, 10)
        : null
      this.review_temporal.review_type = [...this.review.review_type]
    },

    getReviewTypesFromAPI: async function () {
      this.review_types = await this.getReviewTypes()
    },

    checkForm: function (option) {
      this.btnDisabled = true

      if (
        this.review_temporal.product_name != '' &&
        this.review_temporal.customer_name != '' &&
        this.review_temporal.country != ''
      ) {
        if (this.review_temporal.score != '') {
          if (option == 'save') {
            this.$bus.$emit('SHOW-LOADING')
            this.createProcess()
          } else {
            this.$bus.$emit('SHOW-LOADING')
            this.updateProcess()
          }
        } else {
          this.$toastr.error(
            'You must assign at least one star for this review.',
            'Error'
          )
          this.btnDisabled = false
        }
      } else {
        this.$toastr.error('Some fields are required.', 'Error')
        this.btnDisabled = false
      }
    },

    createProcess: async function () {
      try {
        //The review is created and we will get the ID
        this.objectId = await this.createReview(this.review_temporal)

        if (this.review_temporal.review_type.length > 0) {
          await this.saveReviewTypesAssigned(
            this.objectId,
            this.review_temporal.review_type
          )
        }

        this.$toastr.success(
          'The Review has been created successfully!',
          'Success!'
        )
        this.finishAction()
      } catch (error) {
        this.$toastr.error(error, 'Error!')
        this.finishAction()
      }
    },

    updateProcess: async function () {
      try {
        await this.updateReview(this.review_temporal)

        if (
          this.verifyReviewTypesChanges(
            this.review_temporal.review_type,
            this.review.review_type
          )
        ) {
          await this.deleteAllReviewTypes(this.review_temporal.review_id)
          await this.assignReviewTypes(
            this.review_temporal.review_id,
            this.review_temporal.review_type
          )
        }

        this.$toastr.success(
          'The Review has been updated successfully!',
          'Success!'
        )
        this.finishAction()
      } catch (error) {
        this.$toastr.error(error, 'Error!')
        this.btnDisabled = false
      }
    },

    saveReviewTypesAssigned: function (reviewId, reviewTypes) {
      return new Promise((resolve, reject) => {
        const reviewTypesPromises = reviewTypes.map((reviewType) =>
          //To ReviewsCrud.js
          this.addReviewType(reviewId, reviewType.review_type_id)
        )

        try {
          Promise.all(reviewTypesPromises)
            .then(() => {
              resolve()
            })
            .catch((error) => {
              reject(error)
            })
        } catch (error) {
          reject(error)
        }
      })
    },

    verifyReviewTypesChanges: function (
      reviewTypesToCheck,
      reviewTypesOriginal
    ) {
      let isChange = false

      if (reviewTypesToCheck.length !== reviewTypesOriginal.length) {
        isChange = true
      } else {
        reviewTypesToCheck.forEach((reviewType, index) => {
          if (reviewType !== reviewTypesOriginal[index]) {
            isChange = true
          }
        })
      }

      return isChange
    },

    assignReviewTypes: function (reviewId, reviewTypes) {
      return new Promise((resolve, reject) => {
        const reviewTypesPromises = reviewTypes.map((reviewType) =>
          //To ReviewsCrud.js
          this.addReviewType(reviewId, reviewType.review_type_id)
        )

        try {
          Promise.all(reviewTypesPromises)
            .then(() => {
              resolve()
            })
            .catch((error) => {
              reject(error)
            })
        } catch (error) {
          reject(error)
        }
      })
    },

    cancelForm: function () {
      this.clearForm()
      this.$emit('cancelForm')
    },

    clearForm: function () {
      this.review_temporal.review_id = null
      this.review_temporal.product_name = null
      this.review_temporal.url_product = null
      this.review_temporal.url_image = null
      this.review_temporal.customer_name = null
      this.review_temporal.customer_name_url = null
      this.review_temporal.score = null
      this.review_temporal.review_source = null
      this.review_temporal.review_source_url = null
      this.review_temporal.comment = ''
      this.review_temporal.country = null
      this.review_temporal.state = null
      this.review_temporal.users_user_id = null
      this.review_temporal.products_product_id = null
      this.review_temporal.review_date = null

      this.review_temporal.review_type = []
    },

    getURLImage: function (img) {
      var URLImage = null

      //Verifica si es un archivo
      if (img.type) {
        URLImage = URL.createObjectURL(img)
      } else {
        URLImage = this.$API_URL + img.path
      }

      return URLImage
    },

    assignSelectedProduct(product) {
      this.review_temporal.product = product
    },

    assignedProductImg() {
      if (this.review_temporal.product != null)
        return this.$API_URL + this.review_temporal.product.images[0].path
      else return 'We will show the product image here.'
    },

    hoveImg: function (index) {
      this.$set(this.isHover, index, true)
    },

    leaveImg: function (index) {
      this.$set(this.isHover, index, false)
    },

    removeImg: function (index) {
      this.images.splice(index, 1)

      //Evento dirigido a componente Dropzone.js
      this.$bus.$emit('REMOVE-IMAGE', index)
    },

    finishAction: function () {
      this.getReviews()
      this.cancelForm()
      this.$bus.$emit('HIDDEN-LOADING')
      this.btnDisabled = false
    },
  },
  watch: {
    review: {
      deep: true,
      handler() {
        this.copyTemporal()
      },
    },
  },
}
