import { render, staticRenderFns } from "./Materials.vue?vue&type=template&id=0bed88c4&scoped=true"
import script from "./Materials.js?vue&type=script&lang=js&external"
export * from "./Materials.js?vue&type=script&lang=js&external"
import style0 from "./Materials.scss?vue&type=style&index=0&id=0bed88c4&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0bed88c4",
  null
  
)

export default component.exports