export default {
  name: 'ImageTypesForm',

  data() {
    return {
      imageType: {
        id_image_type: null,
        name: null,
      },

      isNew: true,
      btnDisabled: false,
    }
  },

  created: function () {
    this.$bus.$on('OPEN-UPDATE-FORM', (newImageType) => {
      this.isNew = false

      this.imageType.id_image_type = newImageType.id_image_type
      this.imageType.name = newImageType.name
    })

    this.$bus.$on('CLEAR-IMAGE-TYPES-FORM', () => {
      this.cancelForm()
    })
  },

  methods: {
    checkForm: function (option) {
      this.btnDisabled = true

      if (option === 1) {
        if (!this.imageType.name) {
          this.$toastr.error('All fields are required', 'Error!')

          this.btnDisabled = false
        }

        if (this.imageType.name) {
          this.createImageType()
        }
      }

      if (option === 2) {
        if (!this.imageType.name) {
          this.$toastr.error('All fields are required', 'Error!')

          this.btnDisabled = false
        }

        if (this.imageType.name) {
          this.updateImageType()
        }
      }
    },

    createImageType: function () {
      this.$axios
        .post(
          this.$API_URL + 'imagetypes',
          {
            name: this.imageType.name,
          },
          {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
          }
        )
        .then(() => {
          this.$bus.$emit('REFRESH-IMAGE-TYPES-LIST')
          this.cancelForm()

          this.$toastr.success(
            'The Image Type has been Created successfully!',
            'Success!'
          )

          this.btnDisabled = false
        })
        .catch((error) => {
          this.$toastr.error(error, 'Error!')
          this.btnDisabled = false
        })
    },

    updateImageType: function () {
      this.$axios
        .put(
          this.$API_URL + 'imagetypes/' + this.imageType.id_image_type,
          {
            name: this.imageType.name,
          },
          {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
          }
        )
        .then(() => {
          this.$bus.$emit('REFRESH-IMAGE-TYPES-LIST')
          this.cancelForm()

          this.$toastr.info(
            'The Image Type has been Updated successfully!',
            'Success!'
          )

          this.btnDisabled = false
        })
        .catch((error) => {
          this.$toastr.error(error, 'Error!')
          this.btnDisabled = false
        })
    },

    cancelForm: function () {
      this.isNew = true
      this.clearForm()
      this.$emit('cancelForm')
    },

    clearForm: function () {
      this.imageType.id_image_type = null
      this.imageType.name = ''
    },
  },
}
