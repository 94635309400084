import { render, staticRenderFns } from "./RolesForm.vue?vue&type=template&id=d10d4b48&scoped=true"
import script from "./RolesForm.js?vue&type=script&lang=js&external"
export * from "./RolesForm.js?vue&type=script&lang=js&external"
import style0 from "./RolesForm.scss?vue&type=style&index=0&id=d10d4b48&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d10d4b48",
  null
  
)

export default component.exports